import { createReducer } from "@reduxjs/toolkit";
import { StudentBalanceCheckState } from "@redux/app-state";
import { checkStudentBalance, resetCheckStudentBalance } from "./check-student-balance";

const initialState: StudentBalanceCheckState = { fetching: "idle", isStudentBalanceSufficientForBatchEnrollment: false };

export const checkStudentBalanceReducer = createReducer<StudentBalanceCheckState>(initialState, builder => {
  builder.addCase(checkStudentBalance.fulfilled, (state, { payload }) => {
    return {
      ...state,
      isStudentBalanceSufficientForBatchEnrollment: payload,
      fetching: "success",
    };
  });
  builder.addCase(checkStudentBalance.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(checkStudentBalance.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCheckStudentBalance, () => {
    return initialState;
  });
});
