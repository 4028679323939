import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { resetSubmitFundingRequestToAfdas, submitFundingRequestToAfdas } from "./submit-funding-request-to-afdas";

const initialState: SimpleState = { processing: "idle" };

export const submitFundingRequestToAfdasReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(submitFundingRequestToAfdas.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(submitFundingRequestToAfdas.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(submitFundingRequestToAfdas.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetSubmitFundingRequestToAfdas, () => {
    return initialState;
  });
});
