import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateLessonBody } from "@academy-context/write/domain/types/teacher/lesson";
import { LessonCreate } from "./lesson-create.component";
import { LessonListSummary } from "./lesson-list-summary.components";
import { retrieveLessons } from "../../../read/application/use-cases/teacher/lessons-retrieval/retrieve-lessons";
import { selectTeacherLessonsRetrievalForList } from "../../../read/application/use-cases/teacher/lessons-retrieval/selectors/lessons-list-selectors";
import { retrieveTeacherStudents } from "../../../../user-management-context/read/application/use-cases/teacher/students-retrieval/retrieve-students";
import { createLesson } from "../../../write/application/use-cases/teacher/lesson-creation/create-lesson";
import { retrieveTeacherProfile } from "../../../../user-management-context/read/application/use-cases/teacher/teacher-profile-retrieval/retrieve-teacher";
import { ActiveConventions } from "./active-conventions.components";
import { retrieveConventions } from "../../../read/application/use-cases/teacher/conventions-retrieval/retrieve-conventions";
import { selectConventionsRetrievalForList } from "../../../read/application/use-cases/teacher/conventions-retrieval/selectors/conventions-list-selectors";
import { retrieveOldConventions } from "@academy-context/read/application/use-cases/teacher/old-conventions-retrieval/retrieve-old-conventions";

export const LessonTeacherDetail = () => {
  const dispatch = useAppDispatch();
  const { data: lessons } = useAppSelector(selectTeacherLessonsRetrievalForList);
  const { oldConventions, conventions } = useAppSelector(selectConventionsRetrievalForList);

  useEffect(() => {
    // This call is needed to fetch default price
    dispatch(retrieveTeacherProfile());
    dispatch(retrieveTeacherStudents());
    dispatch(retrieveLessons());
    dispatch(retrieveConventions());
    dispatch(retrieveOldConventions());
  }, [dispatch]);

  const saveLesson = async (body: CreateLessonBody) => {
    await dispatch(createLesson(body));
  };

  return (
    <div>
      <div className="mb-5 grid grid-cols-10 gap-4">
        <div className="col-span-7">
          <LessonCreate onSubmit={saveLesson} />
        </div>
        <div className="col-span-3">
          <ActiveConventions
            oldConventions={oldConventions.filter(c => c.hoursLeft > 0)}
            conventions={conventions.filter(c => !c.prematureEndDate).filter(c => c.hoursLeft > 0)}
          />
        </div>
      </div>
      <LessonListSummary lessons={lessons.slice(0, 10)} />
    </div>
  );
};
