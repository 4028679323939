import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { ConventionPaymentExtended } from "../../../../../../read/domain/types/admin/payment";
import { UpdateTransferModal } from "./update-transfer-modal";
import { formatDateToLocale, formatToCurrency } from "../../../../../../../utils/formatting";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";

const columns: ColumnDef<ConventionPaymentExtended>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.creationDate),
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "amount",
    header: () => "Montant à verser",
    cell: ({ row }) => formatToCurrency(row.original.amount),
    meta: {
      title: "Montant à verser",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "amountPaid",
    header: () => "Montant versé",
    cell: ({ row }) => (row.original.amountPaid ? formatToCurrency(row.original.amountPaid) : ""),
    meta: {
      title: "Montant versé",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "reference",
    header: () => "Référence du virement",
    meta: {
      title: "Référence du virement",
    },
    enableSorting: true,
  },
  {
    accessorKey: "paymentDate",
    header: () => "Date du virement",
    cell: ({ row }) => {
      const value = row.original.paymentDate;
      return value ? formatDateToLocale(value) : null;
    },
    meta: {
      title: "Date du virement",
      width: "90px",
    },
    enableSorting: true,
  },
];

interface Props {
  transfers: ConventionPaymentExtended[];
  cardClassname?: string;
}

export const ConventionTransfersTable = ({ transfers }: Props) => {
  const [dataColumns, setDataColumns] = useState<ColumnDef<ConventionPaymentExtended>[]>([...columns]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const currentSelectedLineInfo = useRef<ConventionPaymentExtended | null>(null);
  const currentSelectedLineIsChecked = useRef<boolean>(false);

  useEffect(() => {
    const statusColumn: ColumnDef<ConventionPaymentExtended> = {
      accessorKey: "status",
      header: () => "Virement effectué",
      meta: {
        title: "Virement effectué",
        width: "90px",
      },
      enableSorting: true,
      cell: ({ row }) => {
        const transfer = row.original;
        const isPaid = row.original.status === PaymentStatus.PAID;
        return (
          <Checkbox
            id={transfer.id}
            defaultChecked={isPaid}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              setIsModalOpen(true);
              currentSelectedLineInfo.current = transfer;
              currentSelectedLineIsChecked.current = isPaid;
            }}
          />
        );
      },
    };
    setDataColumns([...columns, statusColumn]);
  }, [transfers]);

  return (
    <>
      {currentSelectedLineInfo.current && (
        <UpdateTransferModal
          transfer={currentSelectedLineInfo.current}
          isPaid={currentSelectedLineIsChecked.current}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <DataTable
        columns={dataColumns}
        data={transfers}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher un paiement"
        pageSize={50}
      />
    </>
  );
};
