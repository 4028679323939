import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveProviderBatches } from "@academy-context/read/application/use-cases/provider/provider-batches-retrieval/retrieve-provider-batches";

const selectProviderBatchCreation = (state: AppState) => state.providerBatchCreation;

export const useProviderBatchCreated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectProviderBatchCreation);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Session ajoutée !" });
      dispatch(retrieveProviderBatches());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'ajout de la session", variant: "destructive" });
    }
  }, [dispatch, processing, toast]);
};
