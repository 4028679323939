import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";

const selectProviderBatchCreation = (state: AppState) => state.providerBatchCreation;

interface Props {
  providerId: string;
}

export const useAdminProviderBatchCreated = ({ providerId }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectProviderBatchCreation);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Session ajoutée !" });
      dispatch(adminRetrieveProviderBatches(providerId));
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'ajout de la session", variant: "destructive" });
    }
    // We don't want to react on providerId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, processing, toast]);
};
