import { useMemo } from "react";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Link } from "react-router-dom";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { SimpleTable } from "@shared-kernel/primary/shared/simple-table";
import { formatDateToLocale, formatToCurrency } from "@utils/formatting";
import { computeConventionStatus } from "@academy-context/read/domain/services/convention";
import { Tooltip } from "@shared-kernel/primary/shared/tooltip/tooltip";
import { AdminConventionExtended } from "@academy-context/read/domain/types/admin/convention";

interface Props {
  conventions: AdminConventionExtended[];
}

const headers = [
  { title: "Enregistré le" },
  { title: "Date de la convention" },
  { title: "Elève" },
  { title: "Professeur" },
  { title: "Heures" },
  { title: "Tarif horaire" },
  { title: "Heures restantes" },
  {
    title: (
      <div className="flex items-center justify-center">
        <span>Prix total estimé</span>
        <Tooltip tooltipContent="Ce prix peut varier si le tarif horaire d'un cours sous convention est modifié" />
      </div>
    ),
  },
  { title: "Commentaire" },
  { title: "Statut" },
];

export const ConventionListSummary = ({ conventions }: Props) => {
  const entries = useMemo(
    () =>
      conventions.map(c => {
        return [
          { value: formatDateToLocale(c.creationDate) },
          { value: formatDateToLocale(c.date) },
          { value: c.student },
          { value: c.teacher },
          { value: c.hours },
          { value: formatToCurrency(c.hourlyPrice) },
          { value: c.hoursLeft },
          { value: c.totalPrice },
          { value: c.commentary },
          { value: computeConventionStatus(c.prematureEndDate, c.hoursLeft) },
        ];
      }),
    [conventions]
  );

  return (
    <CustomCard
      title="Historique des dernières conventions"
      headerChildren={
        <Link to={"/conventions/list"}>
          <Button>Voir tous les conventions</Button>
        </Link>
      }
    >
      <SimpleTable headers={headers} entries={entries} />
    </CustomCard>
  );
};
