import { createReducer } from "@reduxjs/toolkit";
import { teacherRetrieveTraining } from "./retrieve-training";
import { EducationalAdvisorQualiopiTrainingFollowUpVM } from "@academy-context/read/domain/types/training";
import { RetrievalState } from "@redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<EducationalAdvisorQualiopiTrainingFollowUpVM>> = { fetching: "idle", data: null };

export const teacherRetrieveTrainingReducer = createReducer<RetrievalState<Nullable<EducationalAdvisorQualiopiTrainingFollowUpVM>>>(
  initialState,
  builder => {
    builder.addCase(teacherRetrieveTraining.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(teacherRetrieveTraining.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(teacherRetrieveTraining.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
