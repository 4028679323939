import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { EducationalAdvisorQualiopiTrainingFollowUpList } from "@academy-context/primary/shared/qualiopi-training-follow-up/qualiopi-training-follow-up-list.components";
import { teacherRetrieveTrainings } from "@academy-context/read/application/use-cases/teacher/trainings-retrieval/retrieve-trainings";

export const TeacherQualiopiTrainingFollowUpListContainer = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: AppState) => state.teacherTrainingsRetrieval);

  useEffect(() => {
    dispatch(teacherRetrieveTrainings());
  }, [dispatch]);

  return <EducationalAdvisorQualiopiTrainingFollowUpList trainingFollowUps={data} isTeacher />;
};
