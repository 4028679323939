import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { resetUpdateFundingRequestError, updateFundingRequestError } from "./update-funding-request-error";

const initialState: SimpleState = { processing: "idle" };

export const updateFundingRequestErrorReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(updateFundingRequestError.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(updateFundingRequestError.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateFundingRequestError.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUpdateFundingRequestError, () => {
    return initialState;
  });
});
