import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { deleteFundingRequest, resetDeleteFundingRequest } from "./delete-funding-request";

const initialState: SimpleState = {
  processing: "idle",
};

export const deleteFundingRequestReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(deleteFundingRequest.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(deleteFundingRequest.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(deleteFundingRequest.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetDeleteFundingRequest, () => {
    return initialState;
  });
});
