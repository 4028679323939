import { useAppDispatch } from "@redux/hooks";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { updateFundingRequestError } from "@academy-context/write/application/use-cases/admin/funding-request-error-update/update-funding-request-error";
import { FUNDING_REQUEST_ERROR_REASONS } from "@academy-context/read/domain/types/admin/funding-request";
import { Nullable } from "@shared-kernel/core/types/nullable";

interface Props {
  fundingRequestId: Nullable<string>;
  student: Nullable<string>;
  isOpen: boolean;
  onClose: () => void;
}

export const SendAfdasRefreshNotificationModal = ({ fundingRequestId, student, isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();

  const handleOnSubmit = (sendNotificationToStudent: boolean) => {
    onClose();
    if (fundingRequestId)
      dispatch(
        updateFundingRequestError({ id: fundingRequestId, error: FUNDING_REQUEST_ERROR_REASONS.PASSWORD, sendNotificationToStudent })
      );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Envoyer notification à l'élève</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">Voulez-vous envoyer le mail du mot de passe oublié à {student}?</div>
        <DialogFooter>
          <Button onClick={() => handleOnSubmit(false)} variant="outline">
            Ne pas envoyer
          </Button>
          <Button type="submit" onClick={() => handleOnSubmit(true)}>
            Envoyer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
