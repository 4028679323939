import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { EducationalAdvisorQualiopiTrainingFollowUpVM } from "../../../../domain/types/training";

export const teacherRetrieveTraining = createAsyncThunk<
  EducationalAdvisorQualiopiTrainingFollowUpVM,
  string,
  { extra: Partial<Dependencies> }
>("trainings/teacherRetrieveTraining", async (id: string, { extra: { trainingGateway } }) => {
  return trainingGateway!.teacherGetOne(id);
});
