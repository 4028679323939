import { useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { StandardPayment } from "../../../../../read/domain/types/admin/payment";
import { FormProvider, useForm } from "react-hook-form";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyData,
  formatTransferDataToFormData,
} from "./form-validation/transfer/transfer";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateTransfer } from "../../../../../write/application/use-cases/admin/admin-transfer-update/update-transfer";
import { formatToCurrency } from "../../../../../../utils/formatting";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { PopoverCalendar } from "../../../../../../shared-kernel/primary/shared/calendar/popover-calendar";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/alert-dialog";

interface Props {
  transfer: StandardPayment;
  isPaid: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const UpdateTransferModal = ({ transfer, isPaid, isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
    watch,
  } = form;

  const handleOnSubmit = async (formBody: FormInputs) => {
    onClose();
    const body = formatFormDataToBodyData(formBody);
    dispatch(updateTransfer({ id: transfer.id, body: { ...body, type: "standard" } }));
  };

  useEffect(() => {
    resetForm();
    const status = isPaid ? PaymentStatus.PENDING : PaymentStatus.PAID;
    setValue("status", status, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer]);

  const resetForm = () => {
    if (transfer) reset(formatTransferDataToFormData({ ...transfer, paymentDate: transfer.paymentDate ?? today }));
  };

  const label = isPaid ? "Êtes-vous sûr de vouloir annuler ce virement?" : "Êtes-vous sûr de vouloir réaliser ce virement?";
  const Amountlabel = isPaid && transfer ? `Montant versé` : `Montant à verser`;
  const today = new Date().toISOString();

  const amountPaidRaw = watch("amountPaid");
  const amountPaid = Number(amountPaidRaw);
  const isDistinctAmount = transfer.amount !== amountPaid;
  const shouldDisplayWarning = isPaid ? false : isDistinctAmount;

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormProvider {...form}>
        <AlertDialog open={isOpen} onOpenChange={onClose}>
          <AlertDialogContent className="sm:max-w-[425px]">
            <AlertDialogHeader>
              <AlertDialogTitle>Modifier un virement</AlertDialogTitle>
              <AlertDialogDescription />
            </AlertDialogHeader>
            <div className="grid gap-4 py-4">
              <p>{label}</p>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="teacher" className="text-right">
                  Professeur
                </Label>
                <Input id="teacher" value={transfer.teacher} className="col-span-3" disabled />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="amount" className="text-right">
                  {Amountlabel}
                </Label>
                <Input id="amount" value={formatToCurrency(transfer.amount)} className="col-span-3" disabled />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="amountPaid" className="text-right">
                  Montant versé
                </Label>
                <FormField
                  control={form.control}
                  name="amountPaid"
                  render={({ field }) => (
                    <FormItem className="col-span-3 flex flex-col">
                      <FormControl>
                        <Input disabled={isPaid} type="number" min={0} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {shouldDisplayWarning && (
                  <span className="col-span-full text-sm text-red-500">Le montant renseigné est différent du montant calculé</span>
                )}
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="teacher" className="text-right">
                  Date du virement
                </Label>
                <FormField
                  control={form.control}
                  name="paymentDate"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-3 flex flex-col">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} disabled={isPaid} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="teacher" className="text-right">
                  Référence
                </Label>
                <FormField
                  control={form.control}
                  name="reference"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="text" {...field} disabled={isPaid} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={onClose} type="button">
                Annuler
              </AlertDialogCancel>
              <AlertDialogAction type="submit" disabled={!isDirty} onClick={handleSubmit(d => handleOnSubmit(d))}>
                Valider
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </FormProvider>
    </form>
  );
};
