import { useEffect, useState } from "react";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@components/ui/card";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { Label } from "@components/ui/label";
import { Input } from "@components/ui/input";
import { Button } from "@components/ui/button";
import { Briefcase, GraduationCap, Mail, School, Settings } from "lucide-react";
import { InviteUserBody } from "@user-management-context/write/domain/types/admin/user-invitation";
import { FetchingState } from "@redux/app-state";
import { CreatableSelect } from "@shared-kernel/primary/shared/creatable-select/creatable-select";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import InputError from "@shared-kernel/primary/shared/input-error";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { UseFormReset, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData } from "./form-validation/user-invitation";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";

interface Props {
  onSubmit: (user: InviteUserBody) => void;
  checkIfUserExist: (email: string) => void;
  resetCheckIfUserExist: () => void;
  teacherEmails: ComboboxOptions[];
  studentEmails: ComboboxOptions[];
  providerEmails: ComboboxOptions[];
  doesAccountExist: boolean;
  processing: FetchingState;
  submitHook: (props: { reset: UseFormReset<FormInputs> }) => void;
}

const roles = [
  {
    label: "Elève",
    value: ROLES.STUDENT,
    icon: <GraduationCap className="mb-3 size-6" />,
  },
  {
    label: "Prof.",
    value: ROLES.TEACHER,
    icon: <Briefcase className="mb-3 size-6" />,
  },
  {
    label: "Presta.",
    value: ROLES.PROVIDER,
    icon: <School className="mb-3 size-6" />,
  },
  {
    label: "Admin.",
    value: ROLES.ADMIN,
    icon: <Settings className="mb-3 size-6" />,
  },
];

export const UserInvitationFormComponent = ({
  onSubmit,
  teacherEmails,
  studentEmails,
  providerEmails,
  doesAccountExist,
  processing,
  checkIfUserExist,
  resetCheckIfUserExist,
  submitHook,
}: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const [emailOptions, setEmailOptions] = useState<ComboboxOptions[]>([]);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const {
    control,
    reset,
    formState: { isDirty, errors },
    setError,
    clearErrors,
    watch,
    resetField,
    setValue,
    handleSubmit,
  } = form;

  submitHook({ reset });

  const email = watch("email");
  const role = watch("role");

  useEffect(() => {
    if (email?.value && doesAccountExist) {
      setError("email.value", { message: "Cet utilisateur dispose déjà d'un compte", type: "custom" });
      setDisableSubmit(true);
    } else {
      clearErrors("email.value");
      setDisableSubmit(false);
    }
  }, [clearErrors, doesAccountExist, email, setError]);

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  // This part is used to check if user account is already registered
  useEffect(() => {
    if (email?.value) checkIfUserExist(email.value);
  }, [checkIfUserExist, email]);

  // This part is only triggered when role changes
  useEffect(() => {
    resetField("email");
    clearErrors("email.value");
    setDisableSubmit(false);
    resetCheckIfUserExist();
  }, [clearErrors, resetCheckIfUserExist, resetField, role]);

  useEffect(() => {
    if (role === ROLES.STUDENT) setEmailOptions(studentEmails);
    if (role === ROLES.TEACHER) setEmailOptions(teacherEmails);
    if (role === ROLES.PROVIDER) setEmailOptions(providerEmails);
  }, [providerEmails, role, studentEmails, teacherEmails]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Nouvel utilisateur</CardTitle>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <CardContent className="grid gap-6">
            <FormField
              control={control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Rôle</FormLabel>
                  <FormControl>
                    <RadioGroup onValueChange={field.onChange} defaultValue={field.value} className="grid grid-cols-4 gap-4">
                      {roles.map(({ icon, label, value }) => (
                        <FormItem key={value}>
                          <FormControl>
                            <RadioGroupItem value={value} className="peer sr-only" />
                          </FormControl>
                          <FormLabel className="flex flex-col items-center justify-between rounded-md border-2 border-muted p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary">
                            {icon}
                            {label}
                          </FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              {role === ROLES.ADMIN ? (
                <Input
                  type="email"
                  value={email?.value || ""}
                  onChange={e => {
                    setValue("email", { label: e.target.value, value: e.target.value });
                  }}
                />
              ) : (
                <CreatableSelect
                  formName="email"
                  control={control}
                  options={emailOptions}
                  styleOptions={{ menu: { width: "700px" } }}
                  allowCreate={false}
                />
              )}
              <InputError error={errors.email && errors.email.value?.message} />
            </div>
          </CardContent>
          <CardFooter className="flex items-center justify-center">
            <Button type="submit" disabled={!isDirty || processing === "pending" || disableSubmit || !email?.value}>
              <Mail />
              Inviter
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
};
