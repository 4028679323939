import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateExpenseBody } from "@academy-context/write/domain/types/admin/expense";
import { ExpenseCreate } from "./expense-create.component";
import { selectExpensesRetrievalForSummmaryList } from "../../../read/application/use-cases/admin/expenses-retrieval/selectors/expenses-list-summary-selectors";
import { ExpenseListSummary } from "./expense-list-summary.components";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { retrieveExpenses } from "../../../read/application/use-cases/admin/expenses-retrieval/retrieve-expenses";
import { createExpense } from "../../../write/application/use-cases/admin/expense-creation/create-expense";

export const ExpenseAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: expenses } = useAppSelector(selectExpensesRetrievalForSummmaryList);

  useEffect(() => {
    dispatch(retrieveStudents());
    dispatch(retrieveExpenses());
  }, [dispatch]);

  const saveExpense = async (expense: CreateExpenseBody) => {
    await dispatch(createExpense(expense));
  };

  return (
    <div className="space-y-5">
      <ExpenseCreate onSubmit={saveExpense} />
      <ExpenseListSummary expenses={expenses} />
    </div>
  );
};
