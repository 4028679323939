import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  ExtendedTeacherConventionListVM,
  selectConventionsRetrievalForList,
} from "../../../read/application/use-cases/teacher/conventions-retrieval/selectors/conventions-list-selectors";
import { retrieveConventions } from "../../../read/application/use-cases/teacher/conventions-retrieval/retrieve-conventions";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import clsx from "clsx";
import { retrieveOldConventions } from "@academy-context/read/application/use-cases/teacher/old-conventions-retrieval/retrieve-old-conventions";
import { computeConventionStatus } from "@academy-context/read/domain/services/convention";

const statusFormatter = (status: string, hasBeenPaid: boolean) => {
  return (
    <div>
      <span className={clsx(hasBeenPaid && "font-bold text-success")}>{status}</span>
    </div>
  );
};

const columns: ColumnDef<ExtendedTeacherConventionListVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
  },
  {
    accessorKey: "date",
    header: () => "Date",
    cell: ({ row }) => formatDateToLocale(row.original.date),
    enableSorting: true,
    meta: {
      title: "Date",
      width: "90px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
      width: "300px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "hours",
    header: () => "Nombre d'heures",
    meta: {
      title: "Nombre d'heures",
      width: "90px",
    },
  },
  {
    accessorKey: "hoursDone",
    header: () => "Heures consommées",
    meta: {
      title: "Heures consommées",
      width: "100px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire (€/heure)",
    cell: ({ row }) => formatToCurrency(row.original.hourlyPrice),
    meta: {
      title: "Tarif horaire (€/heure)",
      width: "90px",
    },
  },
  {
    accessorKey: "paymentStatus",
    header: () => "Statut du paiement",
    cell: ({ row }) => {
      const { status, hasBeenPaid } = row.original;
      return statusFormatter(status, hasBeenPaid);
    },
    meta: {
      title: "Statut du paiement",
    },
  },
  {
    accessorKey: "prematureEndDate",
    header: () => "Statut",
    meta: {
      title: "Statut",
      width: "90px",
    },
    cell: ({ row }) => {
      const { prematureEndDate, hoursLeft } = row.original;
      return computeConventionStatus(prematureEndDate, hoursLeft);
    },
  },
];

export const TeacherConventionList = () => {
  const dispatch = useAppDispatch();
  const { data: conventions } = useAppSelector(selectConventionsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveConventions());
    dispatch(retrieveOldConventions());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES CONVENTIONS">
      <DataTable
        columns={columns}
        data={conventions}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher une convention"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
