import { InvitationsListContainer } from "@user-management-context/primary/admin/user-invitations/list/invitation-list.container";
import { UserInvitationFormContainer } from "@user-management-context/primary/admin/user-invitations/form/user-invitations-form.container";

export const InvitationsPage = () => {
  return (
    <>
      <UserInvitationFormContainer />
      <InvitationsListContainer />
    </>
  );
};
