import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { ProviderBatchBuilder } from "@user-management-context/test-utils/provider-batch-builder";

const providerBatchItem: ProviderBatchItem = ProviderBatchBuilder().build();
const providerBatchItem2: ProviderBatchItem = ProviderBatchBuilder()
  .withId("batch-2")
  .withTitle("TVi2")
  .withInterval({
    start: "2025-02-05T10:00:00.000Z",
    end: "2025-04-05T10:00:00.000Z",
  })
  .build();
const providerBatchItem3: ProviderBatchItem = ProviderBatchBuilder()
  .withId("batch-3")
  .withTitle("TVi3")
  .withInterval({
    start: "2024-03-05T10:00:00.000Z",
    end: "2024-12-05T10:00:00.000Z",
  })
  .build();
const providerBatchItem4: ProviderBatchItem = ProviderBatchBuilder()
  .withId("batch-4")
  .withTitle("TVi4")
  .withInterval({
    start: "2022-01-05T10:00:00.000Z",
    end: "2022-04-05T10:00:00.000Z",
  })
  .build();

export const providerBatches = [providerBatchItem3, providerBatchItem, providerBatchItem2, providerBatchItem4];
