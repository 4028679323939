import { AppState } from "@redux/app-state";
import { Entry, StudentSummaryExtended } from "../../lessons-retrieval/selectors/shared";
import { convertDateToMonthAndYear } from "../../../../../../../utils/formatting";

const labels: Record<
  "expense" | "standard_lesson" | "lesson_under_convention" | "budget" | "convention" | "refund" | "participation",
  string
> = {
  expense: "Frais / Masterclass",
  standard_lesson: "Cours",
  lesson_under_convention: "Cours sous convention",
  budget: "Financement",
  convention: "Convention",
  refund: "Remboursement via demande",
  participation: "Stage prestataire",
};
export const selectStudentSummaryRetrieval = (state: AppState) => {
  const {
    studentSummaryRetrieval: { data: summary },
  } = state;

  let res: StudentSummaryExtended | null = null;
  let entries: Entry[] = [];
  if (summary) {
    entries = [...summary.lastEntries].map(l => ({
      date: l.date,
      localeDate: new Date(l.date).toLocaleDateString("fr-FR"),
      category: labels[l.type],
      label: l.detail,
      totalAmount: l.totalAmount,
      isUnderConvention: l.type === "lesson_under_convention",
      commentary: l.commentary,
    }));

    res = {
      ...summary,
      localeNextFundingDate: summary.nextFundingDate ? convertDateToMonthAndYear(summary.nextFundingDate) : null,
      lastEntries: entries.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    };
  }

  return {
    data: res,
  };
};
