import { useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { FundingRequestForm } from "./funding-request-form.component";
import { createFundingRequest } from "../../../write/application/use-cases/student/funding-request-creation/create-funding-request";
import { CreateFundingRequestBody } from "@academy-context/write/domain/types/student/funding-request";
import { useFundingRequestCreate } from "./use-funding-request-create";
import { retrieveTeachers } from "../../../../user-management-context/read/application/use-cases/student/teachers-retrieval/retrieve-teachers";
import { resetUploadBio } from "../../../write/application/use-cases/student/bio-upload/upload-bio";
import { resetUploadResume } from "../../../write/application/use-cases/student/resume-upload/upload-resume";
import { retrieveProviders } from "@user-management-context/read/application/use-cases/student/providers-retrieval/retrieve-providers";

export const FundingRequestCreate = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(retrieveTeachers());
    dispatch(retrieveProviders());
    return () => {
      dispatch(resetUploadBio());
      dispatch(resetUploadResume());
    };
  }, [dispatch]);

  const saveFundingRequest = (fundingRequest: CreateFundingRequestBody) => {
    dispatch(createFundingRequest(fundingRequest));
  };

  useFundingRequestCreate();

  return <FundingRequestForm onSubmit={saveFundingRequest} />;
};
