import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { ConfirmBatchEnrollmentBody } from "@user-management-context/shared/application/ports/provider-repository";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import {
  BatchEnrollmentConfirmationFormInputs,
  batchEnrollmentConfirmationSchema,
  defaultBatchEnrollmentConfirmationValues,
  formatEnrollmentDataToFormData,
  formatFormDataToBodyData,
} from "@academy-context/primary/provider/provider-batches/form-validation/batch-enrollment-confirmation/batch-enrollment-confirmation";

interface Props {
  enrollment: ProviderBatchItem["enrollments"][number];
  isOpen: boolean;
  onClose: () => void;
  onConfirmBatchEnrollment: (body: ConfirmBatchEnrollmentBody) => void;
}

export const ConfirmBatchEnrollmentModal = ({ isOpen, onClose, enrollment, onConfirmBatchEnrollment }: Props) => {
  const form = useForm<BatchEnrollmentConfirmationFormInputs>({
    resolver: yupResolver(batchEnrollmentConfirmationSchema),
    defaultValues: defaultBatchEnrollmentConfirmationValues,
  });
  const { handleSubmit, reset } = form;

  const handleOnSubmit = async (formBody: BatchEnrollmentConfirmationFormInputs) => {
    const body = formatFormDataToBodyData({ ...formBody, enrollmentId: enrollment.id });
    onConfirmBatchEnrollment(body);
    onClose();
  };

  useEffect(() => {
    reset(formatEnrollmentDataToFormData(enrollment));
  }, [enrollment, reset, isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:min-w-[425px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Confirmer session</DialogTitle>
          <DialogDescription>
            La confirmation de la session bloque le montant du solde de l’élève.
            <br />
            Si l’élève a effectué une autre session, merci de l'annuler de cette session.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="grid py-4">
              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem className="col-span-3 flex flex-col">
                    <FormLabel>Prix</FormLabel>
                    <FormControl>
                      <Input type="number" min={0} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button onClick={onClose} variant="outline" type="button">
                Annuler
              </Button>
              <Button type="submit">Confirmer session</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
