import { StudentAdminDetail } from "@academy-context/primary/admin/student-detail/student-admin-detail.components";
import { StudentCreate } from "@academy-context/primary/admin/student-detail/student-create.components";
import { StudentsList } from "@academy-context/primary/admin/student-list/student-list.components";
import { TeacherCreate } from "@academy-context/primary/admin/teacher-detail/teacher-create.components";
import { TeacherAdminDetail } from "@academy-context/primary/admin/teacher-detail/teacher-admin-detail.components";
import { TeachersList } from "@academy-context/primary/admin/teacher-list/teacher-list.components";
import { BudgetAdminDetail } from "@academy-context/primary/admin/budget-detail/budget-admin-detail.components";
import { BudgetsList } from "@academy-context/primary/admin/budget-list/budget-list.components";
import { UserDetail } from "@user-management-context/primary/user/user-detail/user-detail.components";
import { LessonAdminDetail } from "@academy-context/primary/admin/lesson-admin-detail/lesson-admin-detail.components";
import { LessonsList } from "@academy-context/primary/admin/lesson-list/lesson-list.components";
import { TransfersList } from "@academy-context/primary/admin/transfers-list/transfers-list.components";
import { LessonTeacherDetail } from "@academy-context/primary/teacher/lesson-teacher-detail/lesson-teacher-detail.components";
import { InvitationsPage } from "./pages/admin/user-invitations.page";
import { TransfersSummary } from "@academy-context/primary/admin/transfers-summary/transfers-summary.components";
import { TeacherUserDetail } from "@user-management-context/primary/teacher/teacher-user-detail/teacher-user-detail.components";
import { TeacherLessonsList } from "@academy-context/primary/teacher/lesson-list/lesson-list.components";
import { StudentUserDetail } from "@user-management-context/primary/student/student-user-detail/student-user-detail.components";
import { StudentLessonList } from "@academy-context/primary/student/lesson-list/lesson-list.components";
import { ExpenseAdminDetail } from "@academy-context/primary/admin/expense-detail/expense-admin-detail.components";
import { ExpensesList } from "@academy-context/primary/admin/expense-list/expense-list.components";
import { StudentExpenseList } from "@academy-context/primary/student/expense-list/expense-list.components";
import { ConventionAdminDetail } from "@academy-context/primary/admin/convention-detail/convention-admin-detail.components";
import { ConventionsList } from "@academy-context/primary/admin/convention-list/convention-list.components";
import { ConventionTransfersList } from "@academy-context/primary/admin/convention-transfers-list/convention-transfers-list.components";
import { TeacherConventionList } from "@academy-context/primary/teacher/convention-list/convention-list.components";
import { StudentConventionList } from "@academy-context/primary/student/convention-list/convention-list.components";
import { StudentBudgetsList } from "@academy-context/primary/student/budget-list/budget-list.components";
import { StudentSummaryContainer } from "@academy-context/primary/student/student-summary/student-summary-container.components";
import { FundingRequestHomeScreen } from "@academy-context/primary/student/funding-request/home-screen.components";
import { FundingRequestCreate } from "@academy-context/primary/student/funding-request/funding-request-create.components";
import { FundingRequestSubmissionSuccess } from "@academy-context/primary/student/funding-request/submission-success-screen.components";
import { FundingRequestToSubmitList } from "@academy-context/primary/admin/funding-request-list/funding-request-to-submit-list.components";
import { FundingRequestDetail } from "@academy-context/primary/admin/funding-request-detail/funding-request-detail.components";
import { StudentAttendanceSheetForm } from "@academy-context/primary/shared/attendance-sheet-signature-form/student-attendance-sheet-form";
import { StudentTrainingDetail } from "@academy-context/primary/student/training-detail/training-detail.components";
import { TrainingSurvey } from "@academy-context/primary/student/training-detail/training-survey.components";
import { StudentTrainingList } from "@academy-context/primary/student/training-list/training-list.components";
import { StudentTrainingObjectives } from "@academy-context/primary/student/training-detail/student-training-summary";
import { TrainingList } from "@academy-context/primary/admin/training-list/training-list.components";
import { AdminTrainingDetail } from "@academy-context/primary/admin/training-detail/training-detail.components";
import { AdminTrainingObjectives } from "@academy-context/primary/admin/training-detail/admin-training-objectives";
import { OldConventionsList } from "@academy-context/primary/admin/convention-list/old-convention-list.components";
import { RefundRequestDetail } from "@academy-context/primary/student/refund-request-detail/refund-request-detail.components";
import { RefundRequestSuccess } from "@academy-context/primary/student/refund-request-detail/refund-request-success.components";
import { CollaborationRequests } from "@academy-context/primary/admin/collaboration-request-list/collaboration-request-list";
import { AdminProviderTrainingsPage } from "@pages/admin/provider-trainings.page";
import { ProviderListPage } from "@pages/admin/provider-list.page";
import { ProviderCreatePage } from "@pages/admin/provider-create.page";
import { ProviderDetailsPage } from "@pages/admin/provider-details.page";
import { AdminProviderBatchListPage } from "@pages/admin/provider-batch-list.page";
import { ProviderProfilePage } from "@pages/provider/provider-profile.page";
import { ProviderTrainingsPage } from "@pages/provider/provider-trainings.page";
import { ProviderBatchListPage } from "@pages/provider/provider-batch-list.page";
import { ProviderQualiopiTrainingFollowUpListPage } from "@pages/provider/qualiopi-training-follow-up-list.page";
import { TeacherQualiopiTrainingFollowUpListPage } from "@pages/teacher/qualiopi-training-follow-up-list.page";
import { ProviderQualiopiTrainingFollowUpDetailPage } from "@pages/provider/qualiopi-training-follow-up-detail.page";
import { TeacherQualiopiTrainingFollowUpDetailPage } from "@pages/teacher/qualiopi-training-follow-up-detail.page";
import { TeacherQualiopiTrainingObjectivesPage } from "@pages/teacher/qualiopi-training-objectives.page";
import { ProviderQualiopiTrainingObjectivesPage } from "@pages/provider/qualiopi-training-objectives.page";
import { TeacherQualiopiTrainingAttendanceSheetListPage } from "@pages/teacher/qualiopi-training-attendance-sheet-list.page";
import { AdminQualiopiTrainingAttendanceSheetListPage } from "@pages/admin/qualiopi-training-attendance-sheet-list.page";
import { ProviderQualiopiTrainingAttendanceSheetListPage } from "@pages/provider/qualiopi-training-attendance-sheet-list.page";
import { FundingRequestToReviewList } from "@academy-context/primary/admin/funding-request-list/funding-request-to-review-list.components";

export interface AppRoute {
  path: string;
  element: JSX.Element;
}
export const TRAININGS_ROUTE_PATH = "trainings";
export const REFUND_REQUEST_ROUTE_PATH = "refund-requests";

const adminRoutes: AppRoute[] = [
  {
    path: `lessons`,
    element: <LessonAdminDetail />,
  },
  {
    path: `lessons/list`,
    element: <LessonsList />,
  },
  {
    path: `budgets`,
    element: <BudgetAdminDetail />,
  },
  {
    path: `budgets/list`,
    element: <BudgetsList />,
  },
  {
    path: `expenses`,
    element: <ExpenseAdminDetail />,
  },
  {
    path: `expenses/list`,
    element: <ExpensesList />,
  },
  {
    path: `conventions`,
    element: <ConventionAdminDetail />,
  },
  {
    path: `conventions/list`,
    element: <ConventionsList />,
  },
  {
    path: `old-conventions/list`,
    element: <OldConventionsList />,
  },
  {
    path: `collaboration-requests`,
    element: <CollaborationRequests />,
  },
  {
    path: `invitations`,
    element: <InvitationsPage />,
  },
  {
    path: `providers`,
    element: <ProviderListPage />,
  },
  {
    path: `providers/create`,
    element: <ProviderCreatePage />,
  },
  {
    path: `providers/:id`,
    element: <ProviderDetailsPage />,
  },
  {
    path: `provider-trainings`,
    element: <AdminProviderTrainingsPage />,
  },
  {
    path: `provider-batches`,
    element: <AdminProviderBatchListPage />,
  },
  {
    path: `students`,
    element: <StudentsList />,
  },
  {
    path: `students/create`,
    element: <StudentCreate />,
  },
  {
    path: `students/:id`,
    element: <StudentAdminDetail />,
  },
  {
    path: `teachers`,
    element: <TeachersList />,
  },
  {
    path: `teachers/create`,
    element: <TeacherCreate />,
  },
  {
    path: `teachers/:id`,
    element: <TeacherAdminDetail />,
  },
  {
    path: `payments`,
    element: <TransfersSummary />,
  },
  {
    path: `transfers/list`,
    element: <TransfersList />,
  },
  {
    path: `convention-transfers`,
    element: <ConventionTransfersList />,
  },
  {
    path: `to-submit-funding-requests`,
    element: <FundingRequestToSubmitList />,
  },
  {
    path: `funding-requests`,
    element: <FundingRequestToReviewList />,
  },
  {
    path: TRAININGS_ROUTE_PATH,
    element: <TrainingList />,
  },
  {
    path: `trainings/:id`,
    element: <AdminTrainingDetail />,
  },
  {
    path: `trainings/:id/sessions/student`,
    element: <StudentAttendanceSheetForm isAdmin />,
  },
  {
    path: `trainings/:id/sessions/teacher`,
    element: <AdminQualiopiTrainingAttendanceSheetListPage />,
  },
  {
    path: `trainings/:id/pre-training-survey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/post-training-survey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/objectives`,
    element: <AdminTrainingObjectives />,
  },
  {
    path: `funding-requests/:id`,
    element: <FundingRequestDetail />,
  },
  {
    path: `profile`,
    element: <UserDetail />,
  },
];

export const STUDENT_FUNDING_REQUEST_MENU_LABEL = "Dossier de financement";
const studentRoutes: AppRoute[] = [
  {
    path: `dashboard`,
    element: <StudentSummaryContainer />,
  },
  {
    path: `funding-request`,
    element: <FundingRequestHomeScreen />,
  },
  {
    path: TRAININGS_ROUTE_PATH,
    element: <StudentTrainingList />,
  },
  {
    path: REFUND_REQUEST_ROUTE_PATH,
    element: <RefundRequestDetail />,
  },
  {
    path: "refund-requests/success",
    element: <RefundRequestSuccess />,
  },
  {
    path: `trainings/:id`,
    element: <StudentTrainingDetail />,
  },
  {
    path: `trainings/:id/sessions`,
    element: <StudentAttendanceSheetForm />,
  },
  {
    path: `trainings/:id/pre-training-survey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/post-training-survey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/objectives`,
    element: <StudentTrainingObjectives />,
  },
  {
    path: `lessons`,
    element: <StudentLessonList />,
  },
  {
    path: `expenses`,
    element: <StudentExpenseList />,
  },
  {
    path: `budgets`,
    element: <StudentBudgetsList />,
  },
  {
    path: `conventions/list`,
    element: <StudentConventionList />,
  },
  {
    path: `funding-request/new`,
    element: <FundingRequestCreate />,
  },
  {
    path: `funding-request/success`,
    element: <FundingRequestSubmissionSuccess />,
  },
  {
    path: `profile`,
    element: <StudentUserDetail />,
  },
];

const teacherRoutes: AppRoute[] = [
  {
    path: `dashboard`,
    element: <LessonTeacherDetail />,
  },
  {
    path: TRAININGS_ROUTE_PATH,
    element: <TeacherQualiopiTrainingFollowUpListPage />,
  },
  {
    path: `trainings/:id`,
    element: <TeacherQualiopiTrainingFollowUpDetailPage />,
  },
  {
    path: `trainings/:id/sessions`,
    element: <TeacherQualiopiTrainingAttendanceSheetListPage />,
  },
  {
    path: `trainings/:id/objectives`,
    element: <TeacherQualiopiTrainingObjectivesPage />,
  },
  {
    path: `lessons/list`,
    element: <TeacherLessonsList />,
  },
  {
    path: `conventions/list`,
    element: <TeacherConventionList />,
  },
  {
    path: `profile`,
    element: <TeacherUserDetail />,
  },
];

const providerRoutes: AppRoute[] = [
  {
    path: `dashboard`,
    element: <ProviderBatchListPage />,
  },
  {
    path: `provider-trainings`,
    element: <ProviderTrainingsPage />,
  },
  {
    path: TRAININGS_ROUTE_PATH,
    element: <ProviderQualiopiTrainingFollowUpListPage />,
  },
  {
    path: `trainings/:id`,
    element: <ProviderQualiopiTrainingFollowUpDetailPage />,
  },
  {
    path: `trainings/:id/sessions`,
    element: <ProviderQualiopiTrainingAttendanceSheetListPage />,
  },
  {
    path: `trainings/:id/objectives`,
    element: <ProviderQualiopiTrainingObjectivesPage />,
  },
  {
    path: `profile`,
    element: <ProviderProfilePage />,
  },
];

export { studentRoutes, adminRoutes, teacherRoutes, providerRoutes };
