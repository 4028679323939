import { number, object } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ConfirmBatchEnrollmentBody } from "@user-management-context/shared/application/ports/provider-repository";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

export const MIN_BATCH_PRICE = 0;

export type BatchEnrollmentConfirmationFormInputs = {
  price: number;
};

export const defaultBatchEnrollmentConfirmationValues = {
  price: 0,
};

export const batchEnrollmentConfirmationSchema = object().shape({
  price: number().min(MIN_BATCH_PRICE, `minimum ${MIN_BATCH_PRICE}€`).typeError("Une valeur est nécessaire").required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (
  enrollment: BatchEnrollmentConfirmationFormInputs & { enrollmentId: string }
): ConfirmBatchEnrollmentBody => {
  const { price, enrollmentId } = enrollment;

  return {
    price,
    enrollmentId,
  };
};

export const formatEnrollmentDataToFormData = (
  enrollment: ProviderBatchItem["enrollments"][number]
): BatchEnrollmentConfirmationFormInputs => {
  const { price } = enrollment;

  return {
    price,
  };
};
