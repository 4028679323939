import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ProviderTrainingListSkeleton } from "@academy-context/primary/shared/provider-trainings/provider-training-list.skeleton";
import { adminRetrieveProviderTrainings } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings";
import { adminSelectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import { useProviderTrainingCreated } from "@academy-context/primary/admin/provider-trainings/list/use-training-created";
import { useProviderTrainingUpdated } from "@academy-context/primary/admin/provider-trainings/list/use-training-updated";
import { Separator } from "@components/ui/separator";
import { Label } from "@components/ui/label";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { selectProviderRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { Skeleton } from "@components/ui/skeleton";
import { adminCreateProviderTraining } from "@academy-context/write/application/use-cases/admin/provider-training-creation/create-provider-training";
import { adminUpdateProviderTraining } from "@academy-context/write/application/use-cases/admin/provider-training-update/update-provider-training";
import {
  AdminCreateProviderTrainingBody,
  AdminUpdateProviderTrainingBody,
} from "@user-management-context/shared/application/ports/admin-provider-repository";
import { adminDeleteProviderTraining } from "@academy-context/write/application/use-cases/admin/provider-training-deletion/delete-provider-training";
import { useProviderTrainingDeleted } from "@academy-context/primary/admin/provider-trainings/list/use-training-deleted";
import { ProviderTrainingListComponent } from "@academy-context/primary/shared/provider-trainings/provider-training-list.components";

export const AdminProviderTrainingListContainer = () => {
  const dispatch = useAppDispatch();
  const { isLoading, trainings } = useAppSelector(adminSelectProviderTrainingsRetrievalForList);
  const { isLoading: isLoadingProviders, providers } = useAppSelector(selectProviderRetrievalForList);
  const [selectedProviderId, setSelectedProviderId] = useState("");

  useEffect(() => {
    dispatch(adminRetrieveProviders());
  }, [dispatch]);

  useEffect(() => {
    const firstElement = providers[0];
    if (firstElement) setSelectedProviderId(firstElement.id);
  }, [providers]);

  useEffect(() => {
    if (selectedProviderId) dispatch(adminRetrieveProviderTrainings(selectedProviderId));
  }, [dispatch, selectedProviderId]);

  useProviderTrainingCreated({ providerId: selectedProviderId });
  useProviderTrainingUpdated();
  useProviderTrainingDeleted({ providerId: selectedProviderId });

  const selectedProvider = providers.find(provider => provider.id === selectedProviderId);

  const providerOptions = providers.map(provider => ({
    label: provider.name,
    value: provider.id,
  }));

  const onCreate = (body: Omit<AdminCreateProviderTrainingBody, "providerId">) => {
    dispatch(adminCreateProviderTraining({ ...body, providerId: selectedProviderId }));
  };
  const onUpdate = (body: Omit<AdminUpdateProviderTrainingBody, "providerId">) => {
    dispatch(adminUpdateProviderTraining({ ...body, providerId: selectedProviderId }));
  };

  const onDelete = (trainingId: string) => {
    dispatch(adminDeleteProviderTraining(trainingId));
  };

  return (
    <>
      <h1 className="text-5xl font-bold">Formations</h1>
      <h2 className="mt-2 text-xs font-light">
        Liste des formations proposées par OPERA Off ainsi que ses prestataires. <br />
        Les informations de cette section sont utilisées pour générer les devis qualitatifs et quantitatifs qui seront envoyés à l’AFDAS.
        <br />
        L’admin peut modifier toutes les formations, les prestataires ne peuvent modifier que leurs formations.
      </h2>
      <div className="mt-2">
        <Label>Prestataire</Label>
        <div className="w-1/2">
          {isLoadingProviders ? (
            <Skeleton className="h-[40px]" />
          ) : (
            <Combobox
              options={providerOptions}
              value={selectedProviderId}
              onChange={value => setSelectedProviderId(value)}
              placeholder="Selectionner prestataire..."
              search={{
                notFoundText: "Pas de prestataire trouvé.",
                commandInputPlaceHolder: "Chercher prestataire...",
              }}
            />
          )}
        </div>
      </div>
      <Separator className="mt-3" />
      <div className="mt-3">
        {isLoading ? (
          <ProviderTrainingListSkeleton />
        ) : (
          selectedProvider && (
            <ProviderTrainingListComponent trainings={trainings} onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} />
          )
        )}
      </div>
    </>
  );
};
