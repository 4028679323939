import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const adminUploadProviderBatchParticipationInvoice = createAsyncThunk<
  string,
  { providerBatchParticipationInvoice: File; providerId: string },
  { extra: Partial<Dependencies> }
>(
  "providerParticipations/adminUploadProviderBatchParticipationInvoice",
  async (
    { providerBatchParticipationInvoice, providerId }: { providerBatchParticipationInvoice: File; providerId: string },
    { extra: { adminProviderRepository } }
  ) => {
    return adminProviderRepository!.uploadBatchParticipationInvoice(providerBatchParticipationInvoice, providerId);
  }
);

export const adminResetUploadProviderBatchParticipationInvoice = createAction(
  "providerParticipations/resetUploadProviderBatchParticipationInvoice"
);
