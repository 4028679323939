import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../config/backend";
import { FundingRequestPort } from "../../application/ports/funding-request-port";
import { FundingRequestEligibilityVM } from "@academy-context/read/domain/types/student/funding-request";
import {
  FUNDING_REQUEST_ERROR_REASONS,
  FundingRequestVM,
  FundingRequestsVM,
} from "@academy-context/read/domain/types/admin/funding-request";
import { CreateFundingRequestBody } from "@academy-context/write/domain/types/student/funding-request";
import { CreateInstantFundingRequestBody, ReviewFundingRequestBody } from "@academy-context/write/domain/types/admin/funding-request";
import { FUNDING_REQUEST_STATUS } from "@academy-context/read/domain/types/enums/funding-request-status";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";

export class BackendFundingRequestGateway extends HttpGateway implements FundingRequestPort {
  private _route: string = "fundingRequests/v1";
  private _adminRoute: string = "admin/fundingRequests/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async create(fundingRequest: CreateFundingRequestBody): Promise<string> {
    const { data: fundingRequestId } = await this._instance.post(this._route, fundingRequest);
    return fundingRequestId;
  }

  async createInstantFundingRequest(fundingRequest: CreateInstantFundingRequestBody): Promise<string> {
    const { data: fundingRequestId } = await this._instance.post(this._adminRoute, fundingRequest);
    return fundingRequestId;
  }

  async uploadDocument(file: File): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("document", file);

    const res = await this._instance.post(`${this._route}/documents:upload`, formDataBody);
    return res.data;
  }

  async fundingRequestEligibility(): Promise<FundingRequestEligibilityVM> {
    const { data: fundingRequestEligibility } = await this._instance.get(`${this._route}/eligibility`);
    return fundingRequestEligibility;
  }

  async getAll(filters?: {
    status: FUNDING_REQUEST_STATUS[];
    funders?: string[];
    educationalAdvisors?: EDUCATIONAL_ADVISOR[];
  }): Promise<FundingRequestsVM> {
    const queryParams: string[] = [];
    if (filters?.status?.length) {
      queryParams.push(...filters.status.map(s => `status=${encodeURIComponent(s)}`));
    }

    if (filters?.funders?.length) {
      queryParams.push(...filters.funders.map(f => `funders=${encodeURIComponent(f)}`));
    }

    if (filters?.educationalAdvisors?.length) {
      queryParams.push(...filters.educationalAdvisors.map(ea => `educational-advisors=${encodeURIComponent(ea)}`));
    }

    const queryParameter = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const { data: fundingRequests } = await this._instance.get(this._adminRoute + queryParameter);
    return fundingRequests;
  }

  async review(fundingRequestId: string, fundingRequest: ReviewFundingRequestBody): Promise<void> {
    await this._instance.put(`${this._adminRoute}/${fundingRequestId}`, fundingRequest);
  }

  async get(fundingRequestId: string): Promise<FundingRequestVM> {
    const { data: fundingRequest } = await this._instance.get(`${this._adminRoute}/${fundingRequestId}`);
    return fundingRequest;
  }

  async delete(fundingRequestId: string): Promise<void> {
    await this._instance.delete(`${this._adminRoute}/${fundingRequestId}`);
  }

  async submitToAfdas(fundingRequestId: string, body: { hasBeenSubmitted: boolean }): Promise<void> {
    await this._instance.put(`${this._adminRoute}/${fundingRequestId}:submittedToAfdas`, body);
  }

  async updateErrorReason(
    fundingRequestId: string,
    body: { error: FUNDING_REQUEST_ERROR_REASONS; sendNotificationToStudent: boolean }
  ): Promise<void> {
    await this._instance.put(`${this._adminRoute}/${fundingRequestId}:updateErrorReason`, body);
  }

  async revertFundingRequestToReview(fundingRequestId: string): Promise<void> {
    return this._instance.post(`${this._adminRoute}/${fundingRequestId}:revert`);
  }
}
