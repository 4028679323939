import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../../config/backend";
import { StudentBudgetListVM } from "@academy-context/read/domain/types/student/budget";
import { StudentBudgetPort } from "@academy-context/shared/application/ports/student-budget-port";

export class BackendStudentBudgetGateway extends HttpGateway implements StudentBudgetPort {
  private _route: string = "student/v1/budgets";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<StudentBudgetListVM[]> {
    const { data: budgets } = await this._instance.get(this._route);
    return budgets;
  }
}
