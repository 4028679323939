import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { ProviderBatchParticipationPayment } from "@academy-context/read/domain/types/admin/provider-batch-participation-payment";
import {
  resetRetrieveProviderBatchParticipationPaymentList,
  retrieveProviderBatchParticipationPaymentList,
} from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/retrieve-provider-batch-participation-payment-list";

const initialState: RetrievalState<ProviderBatchParticipationPayment[]> = { data: [], fetching: "idle" };

export const retrieveProviderBatchParticipationPaymentListReducer = createReducer<RetrievalState<ProviderBatchParticipationPayment[]>>(
  initialState,
  builder => {
    builder.addCase(retrieveProviderBatchParticipationPaymentList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(retrieveProviderBatchParticipationPaymentList.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(retrieveProviderBatchParticipationPaymentList.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
    builder.addCase(resetRetrieveProviderBatchParticipationPaymentList, () => {
      return initialState;
    });
  }
);
