import { useMemo } from "react";
import { ACTION_URGENCY_LEVEL } from "@academy-context/read/domain/types/training";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { Check } from "lucide-react";
import clsx from "clsx";

interface Props {
  title: string;
  status: ACTION_URGENCY_LEVEL;
  subTitle?: string;
  link?: string;
}

export const TrainingElement = ({ title, status, subTitle }: Props) => {
  const classnames = useMemo(() => {
    if (status === ACTION_URGENCY_LEVEL.DONE) return "bg-[#ebebeb]";
    if (status === ACTION_URGENCY_LEVEL.NOT_AVAILABLE) return "bg-[#666666]";
    return "bg-[#ffdfdf] border border-red-600";
  }, [status]);
  return (
    <div className="flex justify-center">
      <div className={clsx("mt-3 flex h-[50px] w-3/4 items-center", classnames)}>
        <div>
          {[ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH].includes(status) && <DangerSign className="ml-4" />}
          {status === ACTION_URGENCY_LEVEL.DONE && <Check className="ml-4" color="green" />}
        </div>
        <h3 className="ml-3">{title}</h3>
        {subTitle && <span className="ml-2">{subTitle}</span>}
      </div>
    </div>
  );
};
