import config from "../../../../../config/backend";
import { StudentLessonPort } from "@academy-context/shared/application/ports/student-lesson-port";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { StudentLesson } from "@academy-context/read/domain/types/student/lesson";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";

export class StudentBackendLessonGateway extends HttpGateway implements StudentLessonPort {
  private _route: string = "student/v1/lessons";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<StudentLesson[]> {
    const { data: lessons } = await this._instance.get(this._route);
    return lessons;
  }
}
