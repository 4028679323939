import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

export const retrieveProviderBatches = createAsyncThunk<ProviderBatchItem[], void, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderBatches",
  async (_, { extra: { providerRepository } }) => {
    return providerRepository!.batchList();
  }
);
