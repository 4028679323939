interface Props {
  to: string;
  children: React.ReactNode;
}

export const DownloadLink = ({ to, children }: Props) => (
  <a href={to} download target="_blank" rel="noreferrer">
    {children}
  </a>
);
