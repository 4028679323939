import { useAppDispatch } from "@redux/hooks";
import { usePasswordUpdate } from "./use-password-update";
import { updatePassword } from "../../../write/application/use-cases/password-update/update-password";
import { PasswordForm } from "../shared/password-form/password-form.components";
import { UserPassword } from "@user-management-context/write/domain/types/user";

export const PasswordUpdatePage = () => {
  const dispatch = useAppDispatch();

  const onSubmit = (body: UserPassword) => {
    dispatch(updatePassword(body));
  };

  return (
    <PasswordForm
      title="Veuillez saisir votre nouveau mot de passe pour le compte suivant:"
      onSubmit={onSubmit}
      useFunction={usePasswordUpdate}
    />
  );
};
