import { Card, CardContent, CardHeader, CardTitle } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Progress } from "@shared-kernel/primary/shared/shadcn/ui/progress";
import clsx from "clsx";

interface Props {
  convention: { teacher: string; hours: number; hoursDone: number };
}

export const ConventionCard = ({ convention }: Props) => {
  const { teacher, hours, hoursDone } = convention;

  const isFinished = hours === hoursDone;

  return (
    <Card className="w-full bg-theme text-white">
      <CardHeader className="border-b border-gray-400 p-2">
        <CardTitle>{teacher}</CardTitle>
      </CardHeader>
      <CardContent className="flex h-[60px] items-center  px-2 py-0">
        <Progress value={(hoursDone / hours) * 100} indicatorClassName={clsx(isFinished && "bg-success")} />
        <div className={clsx("flex flex-col items-center justify-center pl-1", isFinished && "text-success")}>
          <span className="text-base">
            {hoursDone}/{hours}
          </span>
          <span className="text-center text-xs font-normal">heures prises</span>
        </div>
      </CardContent>
    </Card>
  );
};
