import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { UserWithPassword } from "@user-management-context/read/domain/types/user";

const adminUser: UserWithPassword = {
  id: "adminId",
  name: "Marc",
  lastName: "Siebert",
  email: "admin@example.com",
  password: "password",
  role: ROLES.ADMIN,
  createdAt: "2022-06-02T12:00:00.000Z",
};
const studentUser: UserWithPassword = {
  id: "studentId",
  name: "Youssef",
  lastName: "Farkhani",
  email: "student@example.com",
  password: "password",
  role: ROLES.STUDENT,
  createdAt: "2022-06-02T12:00:00.000Z",
};
const teacherUser: UserWithPassword = {
  id: "teacherId",
  name: "Clélia",
  lastName: "Timsit",
  email: "teacher@example.com",
  password: "password",
  role: ROLES.TEACHER,
  createdAt: "2022-06-02T12:00:00.000Z",
};

export const users = [adminUser, studentUser, teacherUser];
