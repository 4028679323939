import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { approveProviderPayment, resetApproveProviderPayment } from "./approve-provider-payment";

const initialState: SimpleState = {
  processing: "idle",
};

export const approveProviderPaymentReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(approveProviderPayment.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(approveProviderPayment.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(approveProviderPayment.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetApproveProviderPayment, () => {
    return initialState;
  });
});
