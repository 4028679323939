import { StudentListElement } from "@user-management-context/read/domain/types/admin/student";
import { AppState } from "src/redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";

export interface RegisteredStudent {
  id: string;
  name: string;
  lastName: string;
  balance: number;
  convention: Nullable<{
    teacherId: string;
    hourlyPrice: number;
    hoursLeft: number;
    isOldConvention: boolean;
  }>;
}

export const selectRegisteredStudents = (state: AppState) => {
  const { data } = state.studentsRetrieval;

  const registeredUserStudents = data.filter((s): s is StudentListElement & { details: NonNullable<StudentListElement["details"]> } =>
    Boolean(s.details)
  );

  const result: RegisteredStudent[] = registeredUserStudents.map(s => {
    return {
      id: s.details.studentId,
      name: s.name,
      lastName: s.lastName,
      balance: s.details.balance,
      convention: s.details.convention,
    };
  });

  return result;
};
