import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { studentRetrieveTraining } from "../../../read/application/use-cases/student/training-retrieval/retrieve-training";
import { AppState } from "@redux/app-state";
import { DownloadableElement } from "../../shared/training/downloadable-element";
import { LinkTrainingElement } from "../../shared/training/link-element";
import { TrainingDetailsHeader } from "../../shared/training/header";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { ACTION_URGENCY_LEVEL } from "@academy-context/read/domain/types/training";

export const StudentTrainingDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: training } = useAppSelector((state: AppState) => state.studentTrainingRetrieval);

  useEffect(() => {
    if (id) dispatch(studentRetrieveTraining(id));
  }, [dispatch, id]);

  if (!training) return <></>;

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton link="/trainings" label="Suivi qualité de votre formation" />
        <TrainingDetailsHeader title={`${training.title} | ${training.educationalAdvisor}`} date={training.date} />
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Emargements</h2>
            <span className="ml-3 leading-8">(cliquer sur la zone pour ouvrir la page et le module d’émargement)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement title="Emargements" status={training.attendanceSheetsStatus} link={`/trainings/${id}/sessions`} />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Evaluations</h2>
            <span className="ml-3 leading-8">
              (à remplir au cours de votre formation, les questionnaires seront accessibles au fur et à mesure votre formation...)
            </span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Questionnaire PRE-FORMATION"
            status={training.preTrainingSurveyStatus}
            link={
              [ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH].includes(training.preTrainingSurveyStatus)
                ? `/trainings/${id}/pre-training-survey`
                : undefined
            }
          />
          <LinkTrainingElement
            title="Questionnaire A FROID"
            status={training.postTrainingSurveyStatus}
            subTitle="(2-3 mois après la fin de votre formation)"
            link={
              [ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH].includes(training.postTrainingSurveyStatus)
                ? `/trainings/${id}/post-training-survey`
                : undefined
            }
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Projet Pédagogique</h2>
            <span className="ml-3 leading-8">(Apparait lorsque la convention est finie ou 3 mois après la date de fin du stage)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Projet Pédagogique"
            status={training.objectivesStatus}
            link={
              [ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH, ACTION_URGENCY_LEVEL.DONE].includes(training.objectivesStatus)
                ? `/trainings/${id}/objectives`
                : undefined
            }
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Documents à télécharger</h2>
          </div>
          <Separator className="mt-2" />
          <DownloadableElement title="Convocation" url={training.trainingInvitationUrl} />
          <DownloadableElement title="Attestation d'assiduité" url={training.attendanceCertificateUrl} />
          <DownloadableElement title="Certificat de réalisation de l'action de formation" url={training.completionCertificateUrl} />
        </div>
      </div>
    </div>
  );
};
