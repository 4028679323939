import { useCallback, useEffect, useState } from "react";
import { Dialog, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@components/ui/form";
import {
  ProviderStudentEnrollmentFormInputs,
  providerStudentEnrollmentDefaultValues,
  providerStudentEnrollmentSchema,
  formatFormDataToBodyData,
  formatEnrollmentDataToFormData,
} from "@user-management-context/primary/provider/student-enrollment/form-validation/provider-student-enrollment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PhoneInput } from "@user-management-context/primary/shared/phone-input/phone-input";
import {
  resetRetrieveProviderStudentByEmail,
  retrieveProviderStudentByEmail,
} from "@user-management-context/read/application/use-cases/provider/student-retrieval-by-email/retrieve-student-by-email";
import { ProviderStudentEnrollmentToBatchBody } from "@user-management-context/shared/application/ports/provider-repository";
import {
  isEmailUsed,
  resetIsEmailUsed,
} from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used";
import { cn } from "@components/utils/utils";
import { formatDateToLocale } from "@utils/formatting";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { Avatar, AvatarFallback, AvatarImage } from "@components/ui/avatar";
import { Skeleton } from "@components/ui/skeleton";
import { ScrollableDialogContent } from "@components/ui/scrollable-dialog";
import { resetCheckStudentBalance } from "@academy-context/read/application/use-cases/provider/student-balance-check/check-student-balance";
import { EnrollmentAlert } from "@user-management-context/primary/provider/student-enrollment/enrollment-alert";

interface Props {
  batch: ProviderBatchItem;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (body: ProviderStudentEnrollmentToBatchBody) => void;
}

export const EnrollProviderStudentModal = ({ isOpen, onClose, onSubmit, batch }: Props) => {
  // :TODO: remove dispatches
  const dispatch = useAppDispatch();
  const { data: studentData } = useAppSelector((state: AppState) => state.providerStudentRetrievalByEmail);
  const { doesExist } = useAppSelector((state: AppState) => state.userEmailExistence);
  const form = useForm<ProviderStudentEnrollmentFormInputs>({
    resolver: yupResolver(providerStudentEnrollmentSchema),
    defaultValues: providerStudentEnrollmentDefaultValues,
  });
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    formState: { errors },
    clearErrors,
    trigger,
  } = form;
  const [doesStudentAlreadyExist, setDoesStudentAlreadyExist] = useState(false);
  const retrieveStudent = useCallback((email: string) => dispatch(retrieveProviderStudentByEmail(email)), [dispatch]);
  const isExistingStudent = Boolean(studentData);

  const phone = watch("mobile");
  const email = watch("email");
  const isValidEmail = watch("isValidEmail");
  const price = watch("price");

  const resetForm = useCallback(() => {
    reset();
    dispatch(resetRetrieveProviderStudentByEmail());
    dispatch(resetIsEmailUsed());
    dispatch(resetCheckStudentBalance());
  }, [dispatch, reset]);

  const handleOnSubmit = async (formBody: Omit<ProviderStudentEnrollmentFormInputs, "batchId">) => {
    const body = formatFormDataToBodyData({ ...formBody, batchId: batch.id });
    handleEnrollmentSubmission(body);
  };

  const handleEnrollmentSubmission = (body: ProviderStudentEnrollmentToBatchBody) => {
    onSubmit(body);
    onClose();
    resetForm();
  };

  // Open / Close the modal
  useEffect(() => {
    resetForm();
  }, [isOpen, resetForm]);

  // This part is used to check if student account is already registered or email is already used
  useEffect(() => {
    if (email) {
      dispatch(isEmailUsed(email));
      retrieveStudent(email);
    }
  }, [dispatch, email, retrieveStudent]);

  useEffect(() => {
    if (isOpen) reset(formatEnrollmentDataToFormData(batch));
  }, [reset, isOpen, batch]);

  useEffect(() => {
    const isOtherRoleEmail = !isExistingStudent && doesExist;
    if (isOtherRoleEmail) {
      setValue("isValidEmail", false);
      setError("isValidEmail", { message: "Cet email est déjà utilisé par un autre type d'utilisateur", type: "custom" });
    } else {
      setValue("isValidEmail", true);
      clearErrors("isValidEmail");
      if (isExistingStudent) {
        setDoesStudentAlreadyExist(true);
      } else {
        setDoesStudentAlreadyExist(false);
      }
    }
  }, [clearErrors, doesExist, isExistingStudent, setError, setValue]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <ScrollableDialogContent onPointerDownOutside={onClose} className="max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Ajouter un élève à la session</DialogTitle>
          <div className="flex flex-col items-center">
            <div>{batch.title}</div>
            <div>
              <span className="ml-3 font-light">du</span>
              <span className="ml-3 text-2xl font-extrabold">{formatDateToLocale(batch.interval.start)}</span>
              <span className="ml-3 font-light">au</span>
              <span className="ml-3 text-2xl font-extrabold">{formatDateToLocale(batch.interval.end)}</span>
            </div>
          </div>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)} className="space-y-4">
            <div className="space-y-2">
              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem className="col-span-3 flex flex-col">
                    <FormLabel>Prix</FormLabel>
                    <FormDescription>Ne modifiez cela que si l’élève a un prix différent du prix de base de la session.</FormDescription>
                    <FormControl>
                      <Input type="number" min={0} max={3000} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <EnrollmentAlert price={price} email={email} />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Email</FormLabel>
                    <FormDescription>
                      Si un email correspond, la fiche de l’élève existant va apparaitre à droite et vous pourrez l’ajouter en cliquant sur
                      le bouton “ajout élève existant” sous cette fiche.
                    </FormDescription>
                    <FormControl>
                      <Input type="email" {...field} />
                    </FormControl>
                    <FormMessage />
                    {errors.isValidEmail?.message && (
                      <p className={cn("text-sm font-medium text-destructive")}>{errors.isValidEmail?.message}</p>
                    )}
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid items-center gap-4">
                <FormField
                  control={form.control}
                  name="mobile"
                  render={() => (
                    <FormItem>
                      <FormLabel>Téléphone portable</FormLabel>
                      <FormControl>
                        <PhoneInput
                          phone={phone}
                          onChange={value => {
                            setValue("mobile", value as string, { shouldDirty: true });
                          }}
                          disabled={doesStudentAlreadyExist}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Nom</FormLabel>
                      <FormControl>
                        <Input type="text" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Prénom</FormLabel>
                      <FormControl>
                        <Input type="text" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" disabled={!isValidEmail || doesStudentAlreadyExist}>
                  Créer élève (Demande de financement + Qualiopi)
                </Button>
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex gap-3 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none">
                  {studentData ? (
                    <>
                      <Avatar>
                        <AvatarImage src={studentData.profilePictureUrl ?? undefined} />
                        <AvatarFallback>{`${studentData.name[0]?.toUpperCase()}${studentData.lastName[0]?.toUpperCase()}`}</AvatarFallback>
                      </Avatar>
                      <div className="flex flex-col gap-1 truncate">
                        <span className="truncate text-base font-semibold">
                          {studentData.name} {studentData.lastName}
                        </span>
                        <span className=" truncate text-xs font-normal text-gray-500">{studentData.email}</span>
                        <span className="text-xs font-normal ">{studentData.mobile}</span>
                      </div>
                    </>
                  ) : (
                    <Skeleton className="h-[66px] w-full" />
                  )}
                </div>
                <div className="mt-4 flex justify-center">
                  <Button
                    type="button"
                    onClick={async () => {
                      if (studentData) {
                        // We don't want to trigger all form validation
                        const isValid = await trigger("price", { shouldFocus: true });
                        if (isValid) handleEnrollmentSubmission({ student: studentData, batchId: batch.id, price });
                      }
                    }}
                    disabled={!studentData}
                  >
                    Ajouter élève existant
                  </Button>
                </div>
              </div>
            </div>
            <DialogFooter className="mt-4 flex sm:justify-center">
              <Button onClick={onClose} variant="outline" type="button">
                Annuler
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </ScrollableDialogContent>
    </Dialog>
  );
};
