import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateLessonBody } from "@academy-context/write/domain/types/admin/lesson";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData } from "./form-validation/lesson";
import { selectLessonCreate, useLessonCreate } from "./use-lesson-create";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { getLocationPrice } from "@academy-context/write/domain/services/locations";
import {
  MAX_HOURLY_PRICE,
  ADMIN_MAX_LESSON_HOURS,
  ADMIN_MIN_HOURLY_PRICE,
  MIN_STUDIO_PRICE,
  MAX_STUDIO_PRICE,
} from "@academy-context/write/domain/constants/shared";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { formatNumberToLocale } from "../../../../utils/formatting";
import { locationOptions as allLocationOptions, PLACE_VALUE } from "@academy-context/read/domain/services/locations";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { RadioGroup, RadioGroupItem } from "@shared-kernel/primary/shared/shadcn/ui/radio-group";
import { Textarea } from "@shared-kernel/primary/shared/shadcn/ui/textarea";
import {
  RegisteredStudent,
  selectRegisteredStudents,
} from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";
import { STUDIO_CHOICES, StudioChoicesMap } from "@academy-context/read/domain/types/admin/lesson";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

interface Props {
  onSubmit: (body: CreateLessonBody) => void;
}

export const LessonCreate = ({ onSubmit }: Props) => {
  const {
    teachersRetrieval: { data: teachers },
  } = useAppSelector((state: AppState) => state);
  const students = useAppSelector(selectRegisteredStudents);
  const { fetching } = useAppSelector(selectLessonCreate);
  const contextForm = useRef<{ hoursLeftOnConvention: number | null }>({ hoursLeftOnConvention: null });
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues, context: contextForm.current });
  const [isUnderConvention, setIsUnderConvention] = useState(false);
  const [isUnderOldConvention, setIsUnderOldConvention] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<RegisteredStudent | undefined>(undefined);

  const {
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = form;

  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);
  useLessonCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody, isUnderOldConvention);
    onSubmit(body);
  };

  const hours = watch("hours");
  const hourlyPrice = watch("hourlyPrice");
  const teacher = watch("teacher");
  const student = watch("student");
  const place = watch("placeName");
  const placeOption = watch("placeOption");
  const isPaidByTeacher = placeOption === STUDIO_CHOICES.PAID_BY_TEACHER;
  const placePrice = isPaidByTeacher ? watch("placePrice") : place ? getLocationPrice(place) : 0;
  const totalAmountForStudent = Number(hours ?? 0) * (Number(hourlyPrice ?? 0) + Number(placePrice ?? 0));
  const totalAmountForTeacher =
    Number(hours ?? 0) * ((isUnderOldConvention ? 0 : Number(hourlyPrice ?? 0)) + Number(isPaidByTeacher ? placePrice : 0));

  useEffect(() => {
    const selectedStudentData = student ? students.find(s => s.id === student) : undefined;
    setSelectedStudent(selectedStudentData);
    const selectedTeacher = teacher ? teachers.find(t => t.teacherId === teacher) : undefined;

    const isUnderConvention: boolean =
      selectedStudentData?.convention && teacher ? selectedStudentData.convention.teacherId === teacher : false;
    setIsUnderConvention(isUnderConvention);
    const isUnderOldConvention = isUnderConvention && Boolean(selectedStudentData?.convention?.isOldConvention);
    setIsUnderOldConvention(isUnderOldConvention);

    if (isUnderConvention && selectedStudentData?.convention) {
      contextForm.current.hoursLeftOnConvention = selectedStudentData.convention.hoursLeft;
      setValue("hourlyPrice", selectedStudentData.convention.hourlyPrice);
    } else {
      contextForm.current.hoursLeftOnConvention = null;
      if (selectedTeacher?.defaultPrice) setValue("hourlyPrice", selectedTeacher.defaultPrice);
      else setValue("hourlyPrice", ADMIN_MIN_HOURLY_PRICE);
    }
  }, [teacher, teachers, setValue, student, students]);

  const studentOptions = students.map(s => ({
    value: s.id,
    label: `${s.name} ${s.lastName} | ${formatNumberToLocale(s.balance, 2)}€`,
  }));
  const teacherOptions = teachers.map(t => ({ value: t.teacherId, label: `${t.name} ${t.lastName}` }));
  // Removes perso option when not needed
  const locationOptions = [...allLocationOptions.filter(s => placeOption === STUDIO_CHOICES.FREE || s.value !== "perso")];

  useEffect(() => {
    if (placeOption === STUDIO_CHOICES.PAID_BY_OPERA_OFF) setValue("placeName", PLACE_VALUE.ACCORD_PARFAIT);
    else if (placeOption === STUDIO_CHOICES.FREE) setValue("placeName", PLACE_VALUE.PERSO);
  }, [placeOption, setValue]);

  return (
    <CustomCard title="Nouveau cours">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-3 gap-3">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col">
                    <FormLabel>Date du cours</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} maxDate={new Date()} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="teacher"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Professeur</FormLabel>
                  <FormControl>
                    <Combobox
                      options={teacherOptions}
                      value={teacher}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner professeur..."
                      search={{
                        notFoundText: "Pas de professeur trouvé.",
                        commandInputPlaceHolder: "Chercher professeur...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="student"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Élève</FormLabel>
                  <FormControl>
                    <Combobox
                      options={studentOptions}
                      value={student}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner élève..."
                      search={{
                        notFoundText: "Pas d'élève trouvé.",
                        commandInputPlaceHolder: "Chercher élève...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div>
              {isUnderConvention && (
                <span className="text-red-500">
                  Il reste {selectedStudent?.convention?.hoursLeft} heure(s) sous convention entre ce professeur et cet élève
                </span>
              )}
            </div>
          </div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="hours"
              render={({ field }) => (
                <FormItem>
                  <div className="flex">
                    <FormLabel>Durée du cours (heures)</FormLabel>
                  </div>
                  <FormControl>
                    <Input type="number" step="0.25" min={0} max={ADMIN_MAX_LESSON_HOURS} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="hourlyPrice"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tarif horaire (€/heure)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      step="1"
                      min={ADMIN_MIN_HOURLY_PRICE}
                      max={MAX_HOURLY_PRICE}
                      disabled={isUnderOldConvention}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem>
              <FormLabel>Prix total du cours pour l'élève (€)</FormLabel>
              <FormControl>
                <Input type="number" disabled value={totalAmountForStudent} />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormItem>
              <FormLabel>Montant payé au professeur (€)</FormLabel>
              <FormControl>
                <Input type="number" disabled value={totalAmountForTeacher} />
              </FormControl>
              <FormMessage />
            </FormItem>
          </div>
          <div className="space-y-4">
            <FormItem className="space-y-3">
              <div className="flex">
                <FormLabel>Studio</FormLabel>
              </div>
              <FormControl>
                <RadioGroup
                  onValueChange={value => setValue("placeOption", value as STUDIO_CHOICES)}
                  value={placeOption}
                  className="flex flex-col space-y-1"
                >
                  {Object.values(STUDIO_CHOICES).map((v, index) => (
                    <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                      <FormControl>
                        <RadioGroupItem value={v} />
                      </FormControl>
                      <FormLabel className="font-normal">{StudioChoicesMap[v]}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
            {isPaidByTeacher ? (
              <FormField
                control={form.control}
                name="placePrice"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Prix studio - payé par le professeur en €/heure</FormLabel>
                    <FormControl>
                      <Input type="number" step="1" min={MIN_STUDIO_PRICE} max={MAX_STUDIO_PRICE} {...field} value={watch("placePrice")} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              <FormField
                control={form.control}
                name="placeName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Studio</FormLabel>
                    {place && (
                      <FormControl>
                        <Combobox
                          options={locationOptions}
                          value={place}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner le lieu..."
                          search={{
                            notFoundText: "Pas de lieu trouvé.",
                            commandInputPlaceHolder: "Chercher lieu...",
                          }}
                          disabled={placeOption !== STUDIO_CHOICES.PAID_BY_OPERA_OFF}
                        />
                      </FormControl>
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Commentaire</FormLabel>
                  <FormControl>
                    <Textarea maxLength={240} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-3 mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || fetching === "pending"}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
