import { isLoadingState } from "@utils/utils";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { AppState } from "@redux/app-state";
import { ROLES_MAP } from "@user-management-context/primary/admin/user-invitations/form/form-validation/user-invitation";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

export interface UserInvitation {
  role: ROLES;
  roleLabel: string;
  localeDate: string;
  email: string;
  updatedAt: string;
  name: Nullable<string>;
  lastName: Nullable<string>;
}

export const selectUserInvitationsRetrievalForList = (state: AppState) => {
  const {
    userInvitationsRetrieval: { data, fetching },
  } = state;

  const extendedData = data
    .map(i => ({ ...i, roleLabel: ROLES_MAP[i.role], localeDate: new Date(i.updatedAt).toLocaleDateString("fr-FR") }))
    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

  const valid: UserInvitation[] = [];
  const expired: UserInvitation[] = [];
  extendedData.forEach(i => {
    if (i.isExpired) expired.push(i);
    else valid.push(i);
  });

  return {
    valid,
    expired,
    isLoading: isLoadingState(fetching),
  };
};
