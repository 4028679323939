import { AnyAction, ListenerMiddlewareInstance, ThunkDispatch } from "@reduxjs/toolkit";
import { LOGIN_TIMEOUT_ERROR_NAME, UNAUTHENTICATED_ERROR_NAME, UNAUTHORIZED_ERROR_NAME } from "@shared-kernel/domain/errors/errors";
import { logout } from "@user-management-context/write/application/use-cases/user-login/login-user";
import { AppState } from "./app-state";

export function addListeners(
  listenerMiddleware: ListenerMiddlewareInstance<AppState, ThunkDispatch<AppState, unknown, AnyAction>, unknown>
) {
  listenerMiddleware.startListening({
    predicate: action =>
      action?.["error"]?.name === UNAUTHENTICATED_ERROR_NAME ||
      action?.["error"]?.name === UNAUTHORIZED_ERROR_NAME ||
      action?.["error"]?.name === LOGIN_TIMEOUT_ERROR_NAME,
    effect: (action, listenerApi) => {
      const error: string = action["error"].name;
      listenerApi.dispatch(logout(error));
    },
  });
}
