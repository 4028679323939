import { Separator } from "@components/ui/separator";
import { PropsWithChildren } from "react";

export const Page = ({ children, title, subtitle }: PropsWithChildren<{ title: string; subtitle?: string }>) => {
  return (
    <>
      <h1 className="text-5xl font-bold">{title}</h1>
      <h2 className="mt-2 text-xs font-light">{subtitle}</h2>
      <Separator className="mt-3" />
      <div className="mt-3">{children}</div>
    </>
  );
};
