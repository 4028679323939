import { Skeleton } from "@components/ui/skeleton";
import { Label } from "@components/ui/label";

export const ProviderAccountCreationSkeleton = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="space-y-2">
        <Label>Raison sociale</Label>
        <Skeleton className="h-10 px-3 py-2" />
      </div>
      <div className="space-y-2">
        <Label>Téléphone</Label>
        <Skeleton className="h-10 px-3 py-2" />
      </div>
      <div className="space-y-2">
        <Label>Email</Label>
        <Skeleton className="h-10 px-3 py-2" />
      </div>
      <div className="space-y-2">
        <Label>IBAN (zone Euro)</Label>
        <Skeleton className="h-10 px-3 py-2" />
      </div>
      <div className="space-y-2">
        <Label>Adresse complète du lieu des cours</Label>
        <Skeleton className="h-10 px-3 py-2" />
      </div>
    </div>
  );
};
