import { useBlocker } from "react-router-dom";
import { useEffect, useState } from "react";

export const useFormBlocker = (shouldBlockNavigation: boolean) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => shouldBlockNavigation && currentLocation.pathname !== nextLocation.pathname
  );

  const isBlocked = blocker.state === "blocked";
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    if (isBlocked) blocker.reset();
  };

  const handleProceed = () => {
    setModalOpen(false);
    if (isBlocked) blocker.proceed();
  };

  useEffect(() => {
    if (isBlocked) setModalOpen(true);
  }, [isBlocked]);

  return {
    isModalOpen,
    handleCloseModal,
    handleProceed,
  };
};
