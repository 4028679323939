import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { funderOptions } from "@academy-context/read/domain/types/admin/budget";
import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData } from "./form-validation/budget/budget";
import { selectBudgetCreate, useBudgetCreate } from "./use-budget-create";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import InputError from "@shared-kernel/primary/shared/input-error";
import { CreatableSelect } from "@shared-kernel/primary/shared/creatable-select/creatable-select";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { addMonths, subMonths } from "date-fns";
import { formatNumberToLocale } from "../../../../utils/formatting";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Textarea } from "@shared-kernel/primary/shared/shadcn/ui/textarea";
import { Equal, Minus } from "lucide-react";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { selectRegisteredStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";
import { CreateInstantFundingRequestModal } from "@academy-context/primary/admin/budget-detail/create-instant-funding-request-modal";
import { AFDAS_FUNDER } from "@academy-context/primary/admin/funding-request-detail/form-validation/afdas-funding-request";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

interface Props {
  onSubmit: (body: CreateBudgetBody) => void;
}

const today = new Date();
const maxDate = addMonths(today, 6);
const minDate = subMonths(today, 6);

export const BudgetCreate = ({ onSubmit }: Props) => {
  const students = useAppSelector(selectRegisteredStudents);
  const { data: fundingRequests } = useAppSelector((state: AppState) => state.submittedToFunderFundingRequestsRetrieval);
  const { fetching } = useAppSelector(selectBudgetCreate);
  const [isCreateInstantFundingRequestOpen, setIsCreateInstantFundingRequestOpen] = useState(false);

  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = form;
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useBudgetCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const allocatedAmount = watch("allocatedAmount");
  const operaOffCommission = watch("operaOffCommission");
  const funder = watch("funder");
  const fundingRequestId = watch("fundingRequestId");
  const totalAmount = allocatedAmount - operaOffCommission;
  const student = watch("student");

  const studentOptions = students.map(s => ({
    value: s.id,
    label: `${s.name} ${s.lastName} | ${formatNumberToLocale(s.balance, 2)}€`,
  }));
  const fundingRequestOptions = fundingRequests
    .filter(r => r.funder === funder?.value)
    .map(r => ({
      value: r.id,
      label: `${r.student.name} | ${r.educationalAdvisor.name} | ${new Date(r.submissionDate).toLocaleDateString("fr-FR")}`,
    }));
  const isAfdasFunder = funder?.value && funder.value === AFDAS_FUNDER;
  const isFundingRequestDefined = Boolean(fundingRequestId);

  useEffect(() => {
    if (!isAfdasFunder) {
      setValue("externalFundingRequestId", null);
      setValue("expirationTimeInMonths", null);
      setValue("fundingRequestId", "");
    } else setValue("expirationTimeInMonths", 6);
  }, [setValue, isAfdasFunder]);

  useEffect(() => {
    const selectedFundingRequest = fundingRequests.find(r => r.id === fundingRequestId);
    if (selectedFundingRequest) {
      setValue("internshipEndDate", selectedFundingRequest.training.endDate);
      setValue("allocatedAmount", selectedFundingRequest.training.price);
      const selectedStudent = studentOptions.find(s => s.value === selectedFundingRequest.student.id);
      if (selectedStudent) {
        setValue("student", selectedStudent.value);
      }
    }
    // studentOptions triggers an infinite loop for some reasons
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, isFundingRequestDefined, fundingRequestId, fundingRequests]);

  return (
    <CustomCard
      title="Nouveau budget"
      headerChildren={<Button onClick={() => setIsCreateInstantFundingRequestOpen(true)}>Demande de financement hors AFDAS</Button>}
    >
      <CreateInstantFundingRequestModal
        isOpen={isCreateInstantFundingRequestOpen}
        onClose={() => setIsCreateInstantFundingRequestOpen(false)}
      />
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-3 gap-3">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Financeur</Label>
              <CreatableSelect formName="funder" control={control} options={funderOptions} />
              <InputError error={errors.funder && errors.funder.value?.message} />
            </div>
            <FormField
              control={form.control}
              name="obtentionDate"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date d'obtention</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} maxDate={maxDate} minDate={minDate} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="internshipEndDate"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date (de fin de stage)</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} maxDate={maxDate} minDate={minDate} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="expirationTimeInMonths"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mois de carence</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      step="1"
                      min="1"
                      disabled={!isAfdasFunder}
                      value={watch("expirationTimeInMonths") ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="student"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Élève</FormLabel>
                  <FormControl>
                    <Combobox
                      options={studentOptions}
                      value={student}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner élève..."
                      search={{
                        notFoundText: "Pas d'élève trouvé.",
                        commandInputPlaceHolder: "Chercher élève...",
                      }}
                      disabled={isFundingRequestDefined}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="externalFundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Module</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" placeholder="Exemple : IS-0069683-1" value={watch("externalFundingRequestId") ?? ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="fundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Demande de financement</FormLabel>
                  <FormControl>
                    <Combobox
                      options={fundingRequestOptions}
                      value={watch("fundingRequestId")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner demande de financement..."
                      search={{
                        notFoundText: "Pas de demande de financement trouvée.",
                        commandInputPlaceHolder: "Chercher demande de financement...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="transmitNotificationToAdmin"
              render={({ field }) => (
                <FormItem className="flex flex-row space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} disabled={!isAfdasFunder} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Envoyer le mail à vrd.siebert et non à l’élève</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <div className="flex items-end gap-2">
              <FormField
                control={form.control}
                name="allocatedAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Budget obtenu</FormLabel>
                    <FormControl>
                      <Input type="number" step="0.01" min="1" {...field} value={watch("allocatedAmount")} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Minus className="mb-2" />
              <FormField
                control={form.control}
                name="operaOffCommission"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>OPERA Off</FormLabel>
                    <FormControl>
                      <Input type="number" step="0.01" {...field} value={watch("operaOffCommission")} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Equal className="mb-2" />
              <FormItem>
                <FormLabel>Total crédité</FormLabel>
                <FormControl>
                  <Input type="number" disabled value={totalAmount} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </div>
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Commentaire</FormLabel>
                  <FormControl>
                    <Textarea maxLength={240} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-3 mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || fetching === "pending"}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
