import { ProviderQualiopiTrainingFollowUpDetailContainer } from "@academy-context/primary/provider/qualiopi-training-follow-up/qualiopi-training-follow-up-detail.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const ProviderQualiopiTrainingFollowUpDetailPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <ProviderQualiopiTrainingFollowUpDetailContainer />
    </Page>
  );
};
