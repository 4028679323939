import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import {
  providerRetrieveQualiopiTrainingFollowUps,
  resetProviderRetrieveQualiopiTrainingFollowUps,
} from "./retrieve-qualiopi-training-follow-ups";
import { TrainingFollowUpListVM } from "@academy-context/read/domain/types/training";

const initialState: RetrievalState<TrainingFollowUpListVM[]> = { fetching: "idle", data: [] };

export const providerRetrieveQualiopiTrainingFollowUpsReducer = createReducer<RetrievalState<TrainingFollowUpListVM[]>>(
  initialState,
  builder => {
    builder.addCase(providerRetrieveQualiopiTrainingFollowUps.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(providerRetrieveQualiopiTrainingFollowUps.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(providerRetrieveQualiopiTrainingFollowUps.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
    builder.addCase(resetProviderRetrieveQualiopiTrainingFollowUps, () => {
      return initialState;
    });
  }
);
