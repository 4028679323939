import { array, date, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { CreateInstantFundingRequestBody } from "@academy-context/write/domain/types/admin/funding-request";
import { INSTANT_FUNDERS } from "@academy-context/shared/domain/types/enums/instant-funders";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";

const defaultPrice = 2400;

export const instantFunderMap: Record<INSTANT_FUNDERS, { label: string; value: INSTANT_FUNDERS }> = {
  [INSTANT_FUNDERS.AFDAS_HORS_IS]: { label: "AFDAS hors IS", value: INSTANT_FUNDERS.AFDAS_HORS_IS },
  [INSTANT_FUNDERS.ATLAS]: { label: "ATLAS", value: INSTANT_FUNDERS.ATLAS },
  [INSTANT_FUNDERS.FIFPL]: { label: "FIFPL", value: INSTANT_FUNDERS.FIFPL },
  [INSTANT_FUNDERS.FRANCE_TRAVAIL]: { label: "FRANCE TRAVAIL", value: INSTANT_FUNDERS.FRANCE_TRAVAIL },
  [INSTANT_FUNDERS.AUTRE]: { label: "Autre", value: INSTANT_FUNDERS.AUTRE },
};

export const instantFunderOptions = Object.values(instantFunderMap).map(v => v);

export type FormInputs = {
  student: string;
  educationalAdvisorType: EDUCATIONAL_ADVISOR;
  educationalAdvisorId: string;
  funder: INSTANT_FUNDERS;
  personalizedEducationalProject: {
    title: { label: string; value: string };
    description: string;
    descriptionText: string;
  }[];
  internalTrainingId: string;
  providerBatchId: string;
  administrativeInternalTrainingId: string;
  trainingPrice: number;
  address: string;
  additionalAddress: string;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
  day8: string;
  day9: string;
  day10: string;
};

export const defaultValues = {
  student: "",
  educationalAdvisorType: EDUCATIONAL_ADVISOR.TEACHER,
  educationalAdvisorId: "",
  funder: INSTANT_FUNDERS.FRANCE_TRAVAIL,
  address: "",
  additionalAddress: "",
  personalizedEducationalProject: [],
  internalTrainingId: "",
  providerBatchId: "",
  administrativeInternalTrainingId: "",
  trainingPrice: defaultPrice,
  day1: "",
  day2: "",
  day3: "",
  day4: "",
  day5: "",
  day6: "",
  day7: "",
  day8: "",
  day9: "",
  day10: "",
};

export const schema = object().shape({
  student: string().max(255).required(REQUIRED_FIELD),
  educationalAdvisorType: string().max(255).required(REQUIRED_FIELD).oneOf(Object.values(EDUCATIONAL_ADVISOR)),
  educationalAdvisorId: string().max(255).required(REQUIRED_FIELD),
  funder: string().max(255).required(REQUIRED_FIELD),
  address: string().max(255).required(REQUIRED_FIELD),
  additionalAddress: string().max(255).required(REQUIRED_FIELD),
  internalTrainingId: string()
    .max(255)
    .when("educationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.TEACHER,
      then: string().required(REQUIRED_FIELD),
      otherwise: string().nullable(),
    }),
  providerBatchId: string()
    .max(255)
    .when("educationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.PROVIDER,
      then: string().required(REQUIRED_FIELD),
      otherwise: string().nullable(),
    }),
  administrativeInternalTrainingId: string()
    .max(255)
    .when("educationalAdvisorType", {
      is: EDUCATIONAL_ADVISOR.PROVIDER,
      then: string().required(REQUIRED_FIELD),
      otherwise: string().nullable(),
    }),
  trainingPrice: number()
    .typeError(`Minimum 0€`)
    .positive(`Minimum 0€`)
    .min(0, `Minimum 0€`)
    .max(4000, `Maximum 4000€`)
    .required(REQUIRED_FIELD),
  day1: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day2: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day3: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day4: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day5: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day6: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day7: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day8: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day9: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  day10: date().required(REQUIRED_FIELD).typeError("Date invalide"),
  personalizedEducationalProject: array()
    .min(1, "Requiert au moins un objectif")
    .of(
      object().shape({
        title: object().shape({
          label: string().max(255).required(REQUIRED_FIELD),
          value: string().max(255).required(REQUIRED_FIELD),
        }),
        description: string().required(REQUIRED_FIELD),
        descriptionText: string().required(REQUIRED_FIELD),
      })
    ),
});

export const formatFormDataToBodyDataInstantFundingRequest = (fundingRequest: FormInputs): CreateInstantFundingRequestBody => {
  return {
    studentId: fundingRequest.student,
    educationalAdvisor: { id: fundingRequest.educationalAdvisorId, type: fundingRequest.educationalAdvisorType },
    funder: fundingRequest.funder,
    address: fundingRequest.address,
    additionalAddress: fundingRequest.additionalAddress,
    reviewedPersonalizedEducationalProject: fundingRequest.personalizedEducationalProject.map(p => ({
      description: p.description,
      title: p.title.value,
    })),
    internalTrainingId: fundingRequest.internalTrainingId || null,
    providerBatchId: fundingRequest.providerBatchId || null,
    administrativeInternalTrainingId: fundingRequest.administrativeInternalTrainingId || null,
    trainingPrice: fundingRequest.trainingPrice,
    trainingDays: {
      day1: fundingRequest.day1,
      day2: fundingRequest.day2,
      day3: fundingRequest.day3,
      day4: fundingRequest.day4,
      day5: fundingRequest.day5,
      day6: fundingRequest.day6,
      day7: fundingRequest.day7,
      day8: fundingRequest.day8,
      day9: fundingRequest.day9,
      day10: fundingRequest.day10,
    },
  };
};
