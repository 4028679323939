import { ProviderQualiopiTrainingObjectivesContainer } from "@academy-context/primary/provider/qualiopi-training-follow-up/qualiopi-training-objectives.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const ProviderQualiopiTrainingObjectivesPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <ProviderQualiopiTrainingObjectivesContainer />
    </Page>
  );
};
