import { resetCreateTeacher } from "../../../../user-management-context/write/application/use-cases/admin/admin-teacher-creation/create-teacher";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

const selectTeacherCreate = (state: AppState) => state.teacherCreate;

export const useTeacherCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedTeacherId, fetching } = useAppSelector(selectTeacherCreate);

  useEffect(() => {
    if (justCreatedTeacherId) {
      navigate(`/teachers/${justCreatedTeacherId}`);
      toast({ description: "Nouveau professeur crée!" });
      dispatch(resetCreateTeacher());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la création du professeur", variant: "destructive" });
    }
  }, [justCreatedTeacherId, dispatch, navigate, toast, fetching]);
};
