import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectStudentRetrieval } from "../../../../user-management-context/read/application/use-cases/admin/student-retrieval/selectors/student-detail-selectors";
import { useStudentUpdate } from "./use-student-update";
import { StudentForm } from "./student-form.component";
import { AdminCreateStudentBody, AdminUpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { retrieveStudent } from "../../../../user-management-context/read/application/use-cases/admin/student-retrieval/retrieve-student";
import { updateStudent } from "../../../../user-management-context/write/application/use-cases/admin/student-update/update-student";
import { StudentSummary } from "../../student/student-summary/student-summary.components";
import { retrieveStudentSummary } from "../../../read/application/use-cases/student/summary-retrieval/retrieve-student-summary";
import { selectStudentSummaryRetrieval } from "../../../read/application/use-cases/student/summary-retrieval/selectors/student-summary-selectors";

export const StudentAdminDetail = () => {
  const dispatch = useAppDispatch();
  const student = useAppSelector(selectStudentRetrieval);
  const { data } = useAppSelector(selectStudentSummaryRetrieval);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(retrieveStudent(id));
      dispatch(retrieveStudentSummary(id));
    }
    dispatch(adminRetrieveTeachers());
  }, [id, dispatch]);

  const saveStudent = async (student: AdminCreateStudentBody | AdminUpdateStudentBody) => {
    await dispatch(updateStudent({ id: id as string, body: student as AdminUpdateStudentBody }));
  };

  useStudentUpdate();

  return (
    <div className="space-y-5">
      <StudentForm student={student} onSubmit={saveStudent} />
      {data && <StudentSummary summary={data} lastActiveConvention={data.lastActiveConvention} adminView={true} />}
    </div>
  );
};
