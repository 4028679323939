import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/alert-dialog";
import { ReactNode } from "react";

interface Props {
  title: string;
  body: ReactNode;
  onSubmit: () => void;
  onClose: () => void;
  isOpen: boolean;
  submitText: string;
  cancelText?: string;
}

export const AlertModal = ({ title, body, submitText, isOpen, onClose, onSubmit, cancelText = "Annuler" }: Props) => {
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{body}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>{cancelText}</AlertDialogCancel>
          <AlertDialogAction onClick={onSubmit}>{submitText}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
