import { SignatureCanvas } from "../signature-canvas/signature-canvas";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { signAttendanceSheet } from "../../../write/application/use-cases/shared/attendance-sheet-signature/sign-attendance-sheet";
import { useAppDispatch } from "@redux/hooks";
import { useAttendanceSheetSignature } from "./use-attendance-sheet-signature";

interface AttendanceSheet {
  id: string;
  scheduledDate: Date;
  signatureUrl: Nullable<string>;
  number: number;
}

export interface AttendanceSheetFormProps {
  role: "student" | "teacher";
  conventionDate: Date;
  title: string;
  attendanceSheets: AttendanceSheet[];
  trainingFollowUpId: string;
  isAdmin: boolean;
}

export const AttendanceSheetForm = ({
  conventionDate,
  title,
  attendanceSheets,
  role,
  trainingFollowUpId,
  isAdmin,
}: AttendanceSheetFormProps) => {
  const dispatch = useAppDispatch();

  const onSubmit = (id: string) => (signatureUrl: string) => {
    if (trainingFollowUpId) dispatch(signAttendanceSheet({ trainingId: trainingFollowUpId, id, signatureUrl, role }));
  };

  useAttendanceSheetSignature(trainingFollowUpId, role, isAdmin);

  const defaultSignatureUrl = attendanceSheets.find(s => s.number === 1)?.signatureUrl ?? null;

  const sortedAttendanceSheet = attendanceSheets.sort((a, b) => a.number - b.number);

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton link={`/trainings/${trainingFollowUpId}`} label="Suivi qualité de votre formation" />
        <div className="mt-5">
          <span>
            <strong>{new Date(conventionDate).toLocaleDateString("fr-FR")}</strong>
          </span>
          <span className="ml-2">
            <strong className="text-theme">{title}</strong>
          </span>
          <div className="mt-3 grid grid-cols-[5%_95%]">
            <div className="flex items-center">
              <DangerSign />
            </div>
            <div className="w-3/4">
              <span>
                <strong>Emargement</strong>
              </span>
              <br />
              <span>
                Vous devez émarger pour chaque demi-journée de formation, soit au total 10 fois.
                <br />
                En cliquant sur la zone de signature, vous pourrez signer avec votre souris.
              </span>
            </div>
          </div>
        </div>
        <div className="mt-5 grid grid-cols-2">
          <div>
            {sortedAttendanceSheet
              .map((s, index) => {
                if (index % 2 === 0) {
                  return (
                    <div className="mt-3 h-[270px]" key={index}>
                      <SignatureCanvas
                        signatureUrl={s.signatureUrl}
                        scheduledDate={new Date(s.scheduledDate)}
                        onSubmit={onSubmit(s.id)}
                        defaultSignatureUrl={defaultSignatureUrl}
                      />
                    </div>
                  );
                }
                return null;
              })
              .filter(Boolean)}
          </div>
          <div>
            {sortedAttendanceSheet
              .map((s, index) => {
                if (index % 2 !== 0) {
                  return (
                    <div className="mt-3 h-[270px]" key={index}>
                      <SignatureCanvas
                        signatureUrl={s.signatureUrl}
                        scheduledDate={new Date(s.scheduledDate)}
                        onSubmit={onSubmit(s.id)}
                        defaultSignatureUrl={defaultSignatureUrl}
                      />
                    </div>
                  );
                }
                return null;
              })
              .filter(Boolean)}
          </div>
        </div>
      </div>
    </div>
  );
};
