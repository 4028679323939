import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ConfirmBatchEnrollmentBody } from "@user-management-context/shared/application/ports/provider-repository";
import { Dependencies } from "src/redux/store";

export const confirmProviderBatchEnrollment = createAsyncThunk<void, ConfirmBatchEnrollmentBody, { extra: Partial<Dependencies> }>(
  "providers/confirmProviderBatchEnrollment",
  async (body: ConfirmBatchEnrollmentBody, { extra: { providerRepository } }) => {
    return providerRepository!.confirmEnrollment(body);
  }
);

export const resetConfirmProviderBatchEnrollment = createAction("providers/resetConfirmProviderBatchEnrollment");
