import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import {} from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings";
import { InternalTraining } from "@academy-context/read/domain/types/shared/internal-training";
import {
  adminRetrieveInternalTrainings,
  resetAdminRetrieveInternalTrainings,
} from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/retrieve-internal-trainings";

const initialState: RetrievalState<InternalTraining[]> = { data: [], fetching: "idle" };

export const adminRetrieveInternalTrainingsReducer = createReducer<RetrievalState<InternalTraining[]>>(initialState, builder => {
  builder.addCase(adminRetrieveInternalTrainings.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveInternalTrainings.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveInternalTrainings.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetAdminRetrieveInternalTrainings, () => {
    return initialState;
  });
});
