import { ProviderBatchStudent } from "@academy-context/primary/provider/provider-batches/provider-batch.components";
import { ConfirmBatchEnrollmentModal } from "@academy-context/primary/provider/provider-batches/confirm-batch-enrollment-modal";
import { PROVIDER_BATCH_ENROLLMENT_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-enrollment-status";
import { PROVIDER_BATCH_PARTICIPATION_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-participation-status";
import { Button } from "@components/ui/button";
import { cn } from "@components/utils/utils";
import { useModal } from "@hooks/useModal";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { PropsWithChildren, ReactElement } from "react";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import {
  ConfirmBatchEnrollmentBody,
  SubmitBatchParticipationInvoiceBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { SubmitBatchParticipationInvoiceModal } from "@academy-context/primary/provider/provider-batches/submit-batch-participation-invoice-modal";
import { ProviderBatchParticipationInvoiceUploadState } from "@redux/app-state";
import { formatToCurrency } from "@utils/formatting";
import { PhoneIncoming, UserPlus } from "lucide-react";
import { commonAdapters } from "src/common-adapters";
import { differenceInDays } from "date-fns";
import { contactAdminForEnrollment } from "@academy-context/write/application/use-cases/admin/student-enrollment-contact/contact-admin-for-enrollment";
import { useAppDispatch } from "@redux/hooks";
import { inviteStudent } from "@academy-context/write/application/use-cases/admin/student-invitation/invite-student";

const EnrollmentStatusText = ({
  children,
  dotColor = "before:bg-gray-400",
  textColor = "text-gray-400",
}: PropsWithChildren<{
  dotColor?: "before:bg-gray-400" | "before:bg-green-500" | "before:bg-red-500";
  textColor?: "text-black" | "text-gray-400";
}>): ReactElement => (
  <div className={cn(`text-sm font-medium`, textColor)}>
    <span className={cn("before:mr-2 before:inline-block before:size-3 before:rounded-full", `${dotColor}`)}>{children}</span>
  </div>
);

const enrollmentStatusGeneratorMap: Record<
  PROVIDER_BATCH_ENROLLMENT_STATUS,
  (enrollmentId: string, onAction: Nullable<() => void>) => ReactElement
> = {
  [PROVIDER_BATCH_ENROLLMENT_STATUS.CONTACT_PENDING]: () => <EnrollmentStatusText>Prise de contact en attente</EnrollmentStatusText>,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_SENT_BY_ADMIN]: () => <EnrollmentStatusText>Invitation envoyée</EnrollmentStatusText>,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.ACCOUNT_REGISTERED]: () => <EnrollmentStatusText>Attente dossier élève</EnrollmentStatusText>,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_SUBMITTED_BY_STUDENT]: () => (
    <EnrollmentStatusText>Dossier finalisé par l’élève</EnrollmentStatusText>
  ),
  [PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_SUBMITTED_TO_FUNDER]: () => (
    <EnrollmentStatusText>Attente réponse financeur</EnrollmentStatusText>
  ),
  [PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER]: (_, onAction) => (
    <Button
      className="h-auto bg-green-500 px-[10px] py-1 text-xs"
      onClick={() => {
        if (onAction) onAction();
      }}
    >
      <span className="font-semibold">Confirmer session</span>
    </Button>
  ),
  // Unused
  [PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_REFUSED_BY_ADMIN]: () => <></>,
  [PROVIDER_BATCH_ENROLLMENT_STATUS.ENROLLMENT_CONFIRMED]: () => <></>,
};

const participationStatusGeneratorMap: Record<
  PROVIDER_BATCH_PARTICIPATION_STATUS,
  (participationId: string, onAction: Nullable<() => void>) => ReactElement
> = {
  [PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_CONFIRMED]: () => <EnrollmentStatusText>Stage confirmé</EnrollmentStatusText>,
  [PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_FINISHED]: (_, onAction) => (
    <Button
      className="h-auto bg-orange-400 px-[10px] py-1 text-xs"
      onClick={() => {
        if (onAction) onAction();
      }}
    >
      <span className="font-semibold">Joindre facture</span>
    </Button>
  ),
  [PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_RECEIVED]: () => <EnrollmentStatusText>Facture envoyée</EnrollmentStatusText>,
  [PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_PAID]: () => (
    <EnrollmentStatusText textColor="text-black" dotColor="before:bg-green-500">
      Facture payée
    </EnrollmentStatusText>
  ),
  [PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_REFUSED]: () => (
    <EnrollmentStatusText textColor="text-black" dotColor="before:bg-red-500">
      Facture refusée
    </EnrollmentStatusText>
  ),
};

const cancellationStatusGenerator: (status?: string) => ReactElement = status => {
  if (status === PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_REFUSED_BY_ADMIN)
    return (
      <EnrollmentStatusText textColor="text-black" dotColor="before:bg-red-500">
        Non admis
      </EnrollmentStatusText>
    );
  return (
    <EnrollmentStatusText textColor="text-black" dotColor="before:bg-red-500">
      Annulé
    </EnrollmentStatusText>
  );
};

interface EnrollmentItemProps {
  batch: ProviderBatchItem;
  enrollment: ProviderBatchItem["enrollments"][number];
  onConfirmBatchEnrollment: (body: ConfirmBatchEnrollmentBody) => void;
  isAdmin: boolean;
}

export const EnrollmentItem = ({ enrollment, onConfirmBatchEnrollment, batch, isAdmin }: EnrollmentItemProps) => {
  const dispatch = useAppDispatch();
  const {
    id,
    student: { name, lastName },
    status,
    price,
    hasAnActiveAccountAtEnrollment,
    lastUpdatedStatusDate,
  } = enrollment;
  const { isModalOpen, openModal, closeModal } = useModal();
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();

  let action: Nullable<() => void> = null;
  if (status === PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER) {
    action = openModal;
  }

  const isStudentEnrollmentPriceDifferentFromBatchPrice = batch.price !== price;

  const diffDays = differenceInDays(now, new Date(lastUpdatedStatusDate));

  let Action = null;
  if (isAdmin && status === PROVIDER_BATCH_ENROLLMENT_STATUS.CONTACT_PENDING) {
    if (hasAnActiveAccountAtEnrollment) {
      Action = <PhoneIncoming className="ml-2 cursor-pointer" onClick={() => dispatch(contactAdminForEnrollment(enrollment.id))} />;
    } else {
      Action = (
        <UserPlus className="ml-2 cursor-pointer" onClick={() => dispatch(inviteStudent({ studentId: enrollment.student.id, now }))} />
      );
    }
  }

  return (
    <div className="flex items-center py-1">
      <ConfirmBatchEnrollmentModal
        isOpen={isModalOpen}
        onClose={closeModal}
        enrollment={enrollment}
        onConfirmBatchEnrollment={onConfirmBatchEnrollment}
      />
      <div className="flex w-1/3">
        <span>
          {name} {lastName} {isStudentEnrollmentPriceDifferentFromBatchPrice && `(${formatToCurrency(price)})`}
        </span>
        {Action}
      </div>
      <div className="ml-3 flex items-center">
        {enrollmentStatusGeneratorMap[status](id, action)} <span className="ml-2">({diffDays} jours)</span>
      </div>
    </div>
  );
};

interface ParticipationItemProps {
  batch: ProviderBatchItem;
  participation: ProviderBatchItem["participations"][number];
  onSubmitBatchParticipationInvoice: (body: SubmitBatchParticipationInvoiceBody) => void;
  uploadedInvoice: ProviderBatchParticipationInvoiceUploadState;
  onUploadInvoice: (invoice: File) => void;
  onResetUploadInvoice: () => void;
}

export const ParticipationItem = ({
  participation,
  onSubmitBatchParticipationInvoice,
  uploadedInvoice,
  onUploadInvoice,
  onResetUploadInvoice,
  batch,
}: ParticipationItemProps) => {
  const {
    id,
    student: { name, lastName },
    status,
    price,
  } = participation;
  const { isModalOpen, openModal, closeModal } = useModal();

  let action: Nullable<() => void> = null;
  if (status === PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_FINISHED) {
    action = openModal;
  }

  const isStudentParticipationPriceDifferentFromBatchPrice = batch.price !== price;

  return (
    <div className="flex items-center py-1">
      <SubmitBatchParticipationInvoiceModal
        isOpen={isModalOpen}
        onClose={closeModal}
        participation={participation}
        onSubmitBatchParticipationInvoice={onSubmitBatchParticipationInvoice}
        uploadedInvoice={uploadedInvoice}
        onUploadInvoice={onUploadInvoice}
        onResetUploadInvoice={onResetUploadInvoice}
      />
      <span className="w-1/3">
        {name} {lastName} {isStudentParticipationPriceDifferentFromBatchPrice && `(${formatToCurrency(price)})`}
      </span>
      <div className="flex items-center">
        <div className="ml-3">{participationStatusGeneratorMap[status](id, action)}</div>
      </div>
    </div>
  );
};

export const CancellationItem = ({ student: { name, lastName, status } }: { student: ProviderBatchStudent }) => {
  return (
    <div className="flex items-center py-1">
      <span className="w-1/3">
        {name} {lastName}
      </span>
      <div className="flex items-center">
        <div className="ml-3">{cancellationStatusGenerator(status)}</div>
      </div>
    </div>
  );
};
