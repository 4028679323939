import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { FormProvider, useForm } from "react-hook-form";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyDataInstantFundingRequest,
  instantFunderOptions,
} from "./form-validation/funding-request/instant-funding-request";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { selectTeachersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/selectors/teachers-list-selectors";
import { adminRetrieveTeachers } from "@user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { TrainingDaysForm } from "@academy-context/primary/admin/funding-request-detail/training-days";
import {
  computeDaysSpreadOver2Months,
  selectDateOptions,
} from "@academy-context/primary/admin/shared/selectors/funding-request/date-options-selectors";
import { TrainingDays } from "@academy-context/read/domain/types/admin/funding-request";
import { ScrollableDialogContent } from "@components/ui/scrollable-dialog";
import { retrieveStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { selectRegisteredStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";
import { formatNumberToLocale } from "@utils/formatting";
import { PersonalizedEducationalProject } from "@academy-context/primary/admin/funding-request-detail/personalized-educational-project";
import { useInstantFundingRequestCreate } from "@academy-context/primary/admin/budget-detail/use-instant-funding-request-create";
import {
  resetCreateInstantFundingRequest,
  createInstantFundingRequest,
} from "@academy-context/write/application/use-cases/admin/instant-funding-request-creation/create-instant-funding-request";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { selectProviderRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { adminRetrieveInternalTrainings } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/retrieve-internal-trainings";
import { adminSelectInternalTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/internal-trainings-retrieval/selectors/internal-training-list-selectors";
import { adminSelectProviderBatchesRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/selectors/provider-batch-list-selectors";
import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateInstantFundingRequestModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { data: teachers } = useAppSelector(selectTeachersRetrievalForList);
  const students = useAppSelector(selectRegisteredStudents);
  const { providers } = useAppSelector(selectProviderRetrievalForList);
  const { trainings: internalTrainings } = useAppSelector(adminSelectInternalTrainingsRetrievalForList);
  const { batches: providerBatches } = useAppSelector(adminSelectProviderBatchesRetrievalForList);
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isDirty },
    control,
  } = form;
  const now = new Date();
  const dateOptions = selectDateOptions(now);
  // We start at M+2
  const secondDateOption = dateOptions[1]!.value;
  const [month, setMonth] = useState(secondDateOption);
  const trainingDays = useMemo(() => computeDaysSpreadOver2Months(new Date(month)), [month]);
  const [switchState, setSwitchState] = useState(true);

  const educationalAdvisorId = watch("educationalAdvisorId");
  const educationalAdvisorType = watch("educationalAdvisorType");
  const isProvider = educationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;
  const student = watch("student");

  useEffect(() => {
    dispatch(adminRetrieveTeachers());
    dispatch(retrieveStudents());
    dispatch(adminRetrieveProviders());
    dispatch(adminRetrieveInternalTrainings());
    return () => {
      reset();
      dispatch(resetCreateInstantFundingRequest());
    };
  }, [dispatch, reset]);

  useEffect(() => {
    setValue("educationalAdvisorId", "", { shouldDirty: false });
    setValue("internalTrainingId", "", { shouldDirty: false });
    setValue("providerBatchId", "", { shouldDirty: false });
    setValue("administrativeInternalTrainingId", "", { shouldDirty: false });
    setValue("address", "");
    setValue("additionalAddress", "");
  }, [switchState, setValue]);

  useEffect(() => {
    if (isProvider && educationalAdvisorId) dispatch(adminRetrieveProviderBatches(educationalAdvisorId));
  }, [dispatch, educationalAdvisorId, isProvider]);

  useEffect(() => {
    for (const [k, v] of Object.entries(trainingDays)) {
      setValue(k as keyof TrainingDays, v, { shouldDirty: true, shouldTouch: true });
    }
  }, [trainingDays, setValue, isOpen]);

  useEffect(() => {
    let address: string;
    let additionalAddress: string;
    if (isProvider) {
      const provider = providers.find(p => p.id === educationalAdvisorId);
      address = provider?.address ?? "";
      additionalAddress = provider?.additionalAddress ?? "";
    } else {
      const teacher = teachers.find(t => t.teacherId === educationalAdvisorId);
      address = teacher?.address ?? "";
      additionalAddress = teacher?.additionalAddress ?? "";
    }
    setValue("address", address);
    setValue("additionalAddress", additionalAddress);
  }, [educationalAdvisorId, isProvider, providers, setValue, teachers]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyDataInstantFundingRequest(formBody);
    dispatch(createInstantFundingRequest(body));
    handleClose();
  };

  const teacherOptions: ComboboxOptions[] = teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.teacherId }));
  const providerOptions: ComboboxOptions[] = useMemo(() => {
    const allProviderOptions = providers.map(p => ({ label: p.name, value: p.id }));
    return allProviderOptions;
  }, [providers]);

  const studentOptions = students.map(s => ({
    value: s.id,
    label: `${s.name} ${s.lastName} | ${formatNumberToLocale(s.balance, 2)}€`,
  }));

  useInstantFundingRequestCreate({ reset });

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormProvider {...form}>
        <Dialog open={isOpen} onOpenChange={handleClose}>
          <ScrollableDialogContent className="sm:min-w-[1000px]" onPointerDownOutside={handleClose}>
            <DialogHeader>
              <DialogTitle>Créer une demande de financement hors AFDAS IS</DialogTitle>
              <DialogDescription>
                Ajout d’une demande de financement pour démarrer une convention entre un prof / prestataire et un élève, ainsi que le suivi
                QUALIOPI.
                <br />{" "}
                <span className="text-red-500">
                  Fonctionnalité à n’utiliser que lorsque l’on veut démarrer un suivi QUALIOPI d’un dossier autre que AFDAS (hors IS).
                </span>
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 text-sm">
              <div className="grid grid-cols-2 gap-5">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <FormField
                      control={control}
                      name="funder"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Financeur</FormLabel>
                          <FormControl>
                            <Combobox
                              options={instantFunderOptions}
                              value={watch("funder")}
                              onChange={value => field.onChange(value)}
                              placeholder="Selectionner financeur..."
                              search={{
                                notFoundText: "Pas de financeur trouvé.",
                                commandInputPlaceHolder: "Chercher financeur...",
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="educationalAdvisorId"
                    render={() => (
                      <FormItem>
                        <FormLabel>Professeur / Prestataire principal</FormLabel>
                        <RadioGroup
                          onValueChange={() => {
                            const opposite = isProvider ? EDUCATIONAL_ADVISOR.TEACHER : EDUCATIONAL_ADVISOR.PROVIDER;
                            setValue("educationalAdvisorType", opposite);
                            setSwitchState(!switchState);
                          }}
                          value={educationalAdvisorType}
                          className="flex flex-col space-y-1"
                        >
                          {Object.values(EDUCATIONAL_ADVISOR).map((v, index) => (
                            <FormItem className="flex items-center space-x-3 space-y-0" key={index}>
                              <FormControl>
                                <RadioGroupItem value={v} />
                              </FormControl>
                              <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                            </FormItem>
                          ))}
                        </RadioGroup>
                        <FormControl>
                          {isProvider ? (
                            <Combobox
                              options={providerOptions}
                              value={educationalAdvisorId}
                              onChange={value => setValue("educationalAdvisorId", value, { shouldDirty: true })}
                              placeholder="Selectionner prestataire..."
                              search={{
                                notFoundText: "Pas de prestataire trouvé.",
                                commandInputPlaceHolder: "Chercher prestataire...",
                              }}
                            />
                          ) : (
                            <Combobox
                              options={teacherOptions}
                              value={educationalAdvisorId}
                              onChange={value => setValue("educationalAdvisorId", value, { shouldDirty: true })}
                              placeholder="Selectionner professeur..."
                              search={{
                                notFoundText: "Pas de professeur trouvé.",
                                commandInputPlaceHolder: "Chercher professeur...",
                              }}
                            />
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="student"
                    render={() => (
                      <FormItem>
                        <FormLabel>Elève principal</FormLabel>
                        <FormControl>
                          <Combobox
                            options={studentOptions}
                            value={student}
                            onChange={value => setValue("student", value, { shouldDirty: true })}
                            placeholder="Selectionner élève..."
                            search={{
                              notFoundText: "Pas d'élève trouvé.",
                              commandInputPlaceHolder: "Chercher élève...",
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {isProvider ? (
                    <>
                      <FormField
                        control={form.control}
                        name="providerBatchId"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Session</FormLabel>
                            <FormControl>
                              <Combobox
                                options={providerBatches}
                                value={watch("providerBatchId")}
                                onChange={value => field.onChange(value)}
                                placeholder="Selectionner session..."
                                search={{
                                  notFoundText: "Pas de session trouvée.",
                                  commandInputPlaceHolder: "Chercher session...",
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="administrativeInternalTrainingId"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Formation administrative</FormLabel>
                            <FormControl>
                              <Combobox
                                options={internalTrainings}
                                value={watch("administrativeInternalTrainingId")}
                                onChange={value => field.onChange(value)}
                                placeholder="Selectionner formation..."
                                search={{
                                  notFoundText: "Pas de formation trouvé.",
                                  commandInputPlaceHolder: "Chercher formation...",
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  ) : (
                    <FormField
                      control={form.control}
                      name="internalTrainingId"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Intitulé de la formation</FormLabel>
                          <FormControl>
                            <Combobox
                              options={internalTrainings}
                              value={watch("internalTrainingId")}
                              onChange={value => field.onChange(value)}
                              placeholder="Selectionner formation..."
                              search={{
                                notFoundText: "Pas de formation trouvé.",
                                commandInputPlaceHolder: "Chercher formation...",
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                  {student && (
                    <TrainingDaysForm
                      studentId={student}
                      now={now}
                      dateOptions={dateOptions}
                      currentMonth={month}
                      onChangeMonth={(value: string) => setMonth(value)}
                    />
                  )}
                </div>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Adresse du lieu des cours</FormLabel>
                        <FormControl>
                          <Input placeholder="Ex: 111 rue de vaugirard" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="additionalAddress"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Code postal, Ville</FormLabel>
                        <FormControl>
                          <Input placeholder="Ex: 75006 Paris" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="trainingPrice"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Prix de la formation</FormLabel>
                        <FormControl className="w-[100px]">
                          <Input type="number" step="1" min={0} max={4000} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <PersonalizedEducationalProject />
                </div>
              </div>
            </div>
            <DialogFooter>
              <Button onClick={handleClose} variant="outline">
                Annuler
              </Button>
              <Button type="submit" disabled={!isDirty} onClick={handleSubmit(d => handleOnSubmit(d))}>
                Créer
              </Button>
            </DialogFooter>
          </ScrollableDialogContent>
        </Dialog>
      </FormProvider>
    </form>
  );
};
