import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";
import { formatBatchesForList } from "@academy-context/read/application/use-cases/shared/selectors/format-batches-for-list";

export const selectProviderBatchesRetrievalForList = createSelector(
  [(state: AppState) => state.providerBatchesRetrieval],
  providerBatchesRetrieval => {
    const { data, fetching } = providerBatchesRetrieval;

    const batches = formatBatchesForList(data);

    return {
      batches,
      isLoading: isLoadingState(fetching),
    };
  }
);
