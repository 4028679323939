import { Nullable } from "@shared-kernel/core/types/nullable";
import { DownloadLink } from "@shared-kernel/primary/shared/download-link/download-link.components";
import { TrainingElement } from "./element";
import { ACTION_URGENCY_LEVEL } from "@academy-context/read/domain/types/training";

interface Props {
  title: string;
  url: Nullable<string>;
  action?: boolean;
}

export const DownloadableElement = ({ title, url, action }: Props) => {
  let status: ACTION_URGENCY_LEVEL = ACTION_URGENCY_LEVEL.NOT_AVAILABLE;
  if (action) status = ACTION_URGENCY_LEVEL.HIGH;
  else if (url) status = ACTION_URGENCY_LEVEL.DONE;
  const content = <TrainingElement title={title} status={status} />;
  if (!url) return content;
  return <DownloadLink to={url}>{content}</DownloadLink>;
};
