import { useCallback, useEffect } from "react";
import { InviteUserBody } from "@user-management-context/write/domain/types/admin/user-invitation";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UserInvitationFormComponent } from "@user-management-context/primary/admin/user-invitations/form/user-invitations-form.components";
import { inviteUser } from "@user-management-context/write/application/use-cases/admin/admin-user-invitation/invite-user";
import { selectUnsubscribedUserList } from "@user-management-context/read/application/use-cases/admin/unsubscribed-user-list-retrieval/unsubscribed-user-list-selectors";
import { AppState } from "src/redux/app-state";
import { useInvitationSent } from "@user-management-context/primary/admin/user-invitations/form/use-invitation-sent";
import { retrieveUnsubscribedUserList } from "@user-management-context/read/application/use-cases/admin/unsubscribed-user-list-retrieval/retrieve-unsubscribed-user-list";
import { UserInvitationFormSkeleton } from "@user-management-context/primary/admin/user-invitations/form/user-invitations-form.skeleton";
import {
  doesUserExist,
  resetDoesUserExist,
} from "@user-management-context/read/application/use-cases/admin/user-account-existence/does-user-account-exist";

export const UserInvitationFormContainer = () => {
  const dispatch = useAppDispatch();
  const { teacherEmails, studentEmails, providerEmails, isLoading } = useAppSelector(selectUnsubscribedUserList);
  const { doesExist } = useAppSelector((state: AppState) => state.userAccountExistence);
  const { processing } = useAppSelector((state: AppState) => state.userInvite);

  useEffect(() => {
    dispatch(retrieveUnsubscribedUserList());
  }, [dispatch]);

  const onSubmit = (user: InviteUserBody) => {
    dispatch(inviteUser(user));
  };

  const checkIfUserExist = useCallback(
    (email: string) => {
      dispatch(doesUserExist(email));
    },
    [dispatch]
  );

  const resetCheckIfUserExist = useCallback(() => {
    dispatch(resetDoesUserExist());
  }, [dispatch]);

  if (isLoading) return <UserInvitationFormSkeleton />;

  return (
    <UserInvitationFormComponent
      onSubmit={onSubmit}
      checkIfUserExist={checkIfUserExist}
      resetCheckIfUserExist={resetCheckIfUserExist}
      teacherEmails={teacherEmails}
      studentEmails={studentEmails}
      providerEmails={providerEmails}
      doesAccountExist={doesExist}
      processing={processing}
      submitHook={useInvitationSent}
    />
  );
};
