import { ProviderQualiopiTrainingAttendanceSheetListContainer } from "@academy-context/primary/provider/qualiopi-training-follow-up/qualiopi-training-attendance-sheet-list.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const ProviderQualiopiTrainingAttendanceSheetListPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <ProviderQualiopiTrainingAttendanceSheetListContainer />
    </Page>
  );
};
