import { object, string } from "yup";
import { ProviderBatchParticipationPaymentRow } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/selectors/provider-batch-participation-payment-list-selectors";
import { RefuseProviderBatchParticipationPaymentBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export type ProviderPaymentRefusalFormInputs = {
  adminCommentary: string;
};

export const providerPaymentRefusalDefaultValues = {
  adminCommentary: "",
};

export const providerPaymentRefusalSchema = object().shape({
  adminCommentary: string().max(255),
});

export const formatProviderPaymentRefusalDataToFormData = (
  payment: ProviderBatchParticipationPaymentRow
): ProviderPaymentRefusalFormInputs => {
  const { adminCommentary } = payment;

  return {
    adminCommentary: adminCommentary ?? providerPaymentRefusalDefaultValues.adminCommentary,
  };
};

export const formatProviderPaymentRefusalFormDataToBodyData = (
  payment: ProviderPaymentRefusalFormInputs,
  data: ProviderBatchParticipationPaymentRow
): RefuseProviderBatchParticipationPaymentBody => {
  const { adminCommentary } = payment;

  return {
    paymentId: data.id,
    adminCommentary: adminCommentary || null,
  };
};
