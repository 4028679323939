import { retrieveUserInvitations } from "../../../../read/application/use-cases/admin/user-invitations-retrieval/retrieve-user-invitations";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetInviteUser } from "../../../../write/application/use-cases/admin/admin-user-invitation/invite-user";

const selectUserInvite = (state: AppState) => state.userInvite;

export const useInvitationReSent = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justInvitedUserId, processing } = useAppSelector(selectUserInvite);

  useEffect(() => {
    if (justInvitedUserId) {
      toast({ description: "Invitation renvoyée !" });
      dispatch(resetInviteUser());
      dispatch(retrieveUserInvitations());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors du renvoi de l'invitation", variant: "destructive" });
    }
  }, [justInvitedUserId, processing, dispatch, toast]);
};
