import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { retrieveProviderBatchParticipationPaymentList } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/retrieve-provider-batch-participation-payment-list";

const selectProviderPaymentRefused = (state: AppState) => state.providerPaymentRefusal;

export const useProviderPaymentRefused = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { processing } = useAppSelector(selectProviderPaymentRefused);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Paiement refusé !" });
      dispatch(retrieveProviderBatchParticipationPaymentList());
    } else if (processing === "failed") {
      toast({
        description: "Erreur lors du traitement du paiement. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [dispatch, processing, toast]);
};
