import { createReducer } from "@reduxjs/toolkit";
import { resetReviewFundingRequest, reviewFundingRequest } from "./review-funding-request";
import { SimpleState } from "@redux/app-state";

const initialState: SimpleState = { processing: "idle" };

export const reviewFundingRequestReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(reviewFundingRequest.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(reviewFundingRequest.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(reviewFundingRequest.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetReviewFundingRequest, () => {
    return initialState;
  });
});
