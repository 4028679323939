import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";

export const adminSelectInternalTrainingsRetrievalForList = createSelector(
  [(state: AppState) => state.adminInternalTrainingsRetrieval],
  internalTrainingsRetrieval => {
    const { data, fetching } = internalTrainingsRetrieval;

    const formattedData = [...data]
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(training => ({ label: training.title, value: training.id }));

    return {
      trainings: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
