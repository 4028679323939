import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { isStudentBalanceSufficientForBatchEnrollmentBody } from "@user-management-context/shared/application/ports/provider-repository";

export const checkStudentBalance = createAsyncThunk<
  boolean,
  isStudentBalanceSufficientForBatchEnrollmentBody,
  { extra: Partial<Dependencies> }
>("providers/checkStudentBalance", async (payload: isStudentBalanceSufficientForBatchEnrollmentBody, { extra: { providerRepository } }) => {
  return providerRepository!.isStudentBalanceSufficientForBatchEnrollment(payload);
});

export const resetCheckStudentBalance = createAction("admins/resetCheckStudentBalance");
