import { HttpGateways } from "@shared-kernel/secondary/http-gateway";
import { createReducer } from "@reduxjs/toolkit";
import { UserLoginState } from "@redux/app-state";
import { loginUser, logout } from "./login-user";
import Cookies from "universal-cookie";
import { UserWithAccessToken } from "../../../../read/domain/types/user";
const cookies = new Cookies();
const userInfoCookie = cookies.get("userInfo");

export const setUserInfoCookie = (user: UserWithAccessToken, expiresIn: number) => {
  const cookieOptions = { path: "/", maxAge: expiresIn };
  cookies.set("userInfo", user, cookieOptions);
  HttpGateways.setBearerToken();
};

export const clearUserInfoCookie = () => {
  cookies.remove("userInfo", { path: "/" });
};

const initialState: UserLoginState = { fetching: "idle", data: userInfoCookie ?? null, error: null };

export const loginUserReducer = createReducer<UserLoginState>(initialState, builder => {
  builder.addCase(loginUser.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(loginUser.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(loginUser.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(logout, (_, { payload }) => {
    clearUserInfoCookie();
    const fetching = payload != null ? "failed" : "idle";
    return { ...initialState, data: null, error: payload, fetching };
  });
});
