import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { resetRevertFundingRequestToReview, revertFundingRequestToReview } from "./revert-funding-request-to-review";

const initialState: SimpleState = { processing: "idle" };

export const revertFundingRequestToReviewReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(revertFundingRequestToReview.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(revertFundingRequestToReview.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(revertFundingRequestToReview.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetRevertFundingRequestToReview, () => {
    return initialState;
  });
});
