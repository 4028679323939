import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { providerRetrieveQualiopiTrainingFollowUps } from "@academy-context/read/application/use-cases/provider/qualiopi-training-follow-ups-retrieval/retrieve-qualiopi-training-follow-ups";
import { EducationalAdvisorQualiopiTrainingFollowUpList } from "@academy-context/primary/shared/qualiopi-training-follow-up/qualiopi-training-follow-up-list.components";

export const ProviderQualiopiTrainingFollowUpListContainer = () => {
  const dispatch = useAppDispatch();
  const { data: trainingFollowUps } = useAppSelector((state: AppState) => state.providerQualiopiTrainingFollowUpsRetrieval);

  useEffect(() => {
    dispatch(providerRetrieveQualiopiTrainingFollowUps());
  }, [dispatch]);

  return <EducationalAdvisorQualiopiTrainingFollowUpList trainingFollowUps={trainingFollowUps} />;
};
