import { ProviderBatchListContainer } from "@academy-context/primary/provider/provider-batches/provider-batch-list.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const ProviderBatchListPage = () => {
  return (
    <Page title="Tableau de bord">
      <ProviderBatchListContainer />
    </Page>
  );
};
