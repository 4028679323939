import { number, object, string } from "yup";
import { PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD } from "@academy-context/read/domain/types/admin/provider-batch-participation-payment";
import { ProviderBatchParticipationPaymentRow } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/selectors/provider-batch-participation-payment-list-selectors";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ApproveProviderBatchParticipationPaymentBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { formatDateToLocale } from "@utils/formatting";

export type ProviderPaymentApprovalFormInputs = {
  amountPaid: number;
  adminCommentary: string;
  paymentDate: string;
  paymentMethod: PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD;
  paymentReference: string;
};

export const providerPaymentApprovalDefaultValues = {
  amountPaid: 0,
  adminCommentary: "",
  paymentDate: new Date().toISOString(),
  paymentMethod: PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD.MANUAL,
  paymentReference: "",
};

export const providerPaymentApprovalSchema = object().shape({
  amountPaid: number().typeError("Minimum 0 €").min(0, "Minimum 0 €").required(REQUIRED_FIELD),
  adminCommentary: string().max(255),
  paymentDate: string().max(255).required(REQUIRED_FIELD),
  paymentMethod: string().oneOf(Object.values(PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD)),
  paymentReference: string().max(255).required(REQUIRED_FIELD),
});

export const formatProviderPaymentApprovalDataToFormData = (
  payment: ProviderBatchParticipationPaymentRow
): ProviderPaymentApprovalFormInputs => {
  const {
    payment: { date, amountPaid, method, reference, amount },
    adminCommentary,
    invoice,
    student,
    batch: {
      interval: { start, end },
    },
    training: { title },
  } = payment;

  const formattedReference = `${invoice.number} | ${title} | ${student} | ${formatDateToLocale(start)} - ${formatDateToLocale(end)}`;

  return {
    amountPaid: amountPaid ?? amount,
    paymentDate: date ?? providerPaymentApprovalDefaultValues.paymentDate,
    paymentMethod: method ?? providerPaymentApprovalDefaultValues.paymentMethod,
    paymentReference: reference ?? formattedReference ?? providerPaymentApprovalDefaultValues.paymentReference,
    adminCommentary: adminCommentary ?? providerPaymentApprovalDefaultValues.adminCommentary,
  };
};

export const formatProviderPaymentApprovalFormDataToBodyData = (
  payment: ProviderPaymentApprovalFormInputs,
  data: ProviderBatchParticipationPaymentRow
): ApproveProviderBatchParticipationPaymentBody => {
  const { amountPaid, adminCommentary, paymentDate, paymentMethod, paymentReference } = payment;

  return {
    paymentId: data.id,
    adminCommentary: adminCommentary || null,
    paymentDate: new Date(paymentDate),
    paymentMethod: paymentMethod,
    paymentReference: paymentReference,
    amountPaid: amountPaid,
  };
};
