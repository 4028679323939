import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingFollowUpListVM } from "../../../../domain/types/training";

export const providerRetrieveQualiopiTrainingFollowUps = createAsyncThunk<TrainingFollowUpListVM[], void, { extra: Partial<Dependencies> }>(
  "trainings/providerRetrieveQualiopiTrainingFollowUps",
  async (_: void, { extra: { providerQualiopiTrainingFollowUpRepository } }) => {
    return providerQualiopiTrainingFollowUpRepository!.list();
  }
);

export const resetProviderRetrieveQualiopiTrainingFollowUps = createAction("trainings/resetProviderRetrieveQualiopiTrainingFollowUps");
