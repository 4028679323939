import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";

const selectTeacherAccountCreate = (state: AppState) => state.createTeacherAccount;

export const useTeacherAccountCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useAppSelector(selectTeacherAccountCreate);

  useEffect(() => {
    if (fetching === "success") {
      navigate(`/login`);
      toast({ description: "Votre compte a été crée!" });
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la création de votre compte", variant: "destructive" });
    }
  }, [dispatch, fetching, navigate, toast]);
};
