import { TokenStatus } from "@user-management-context/shared/domain/types/enums/token";
import config from "../../../config/frontend";

export const generateMessage = (tokenStatus: TokenStatus) => {
  if (tokenStatus === "expired") {
    return (
      <p>
        Votre lien d'invitation a expiré. <br />
        Contacter l'administrateur afin qu'il puisse vous renvoyer une invitation.
      </p>
    );
  } else
    return (
      <p>
        Il y a un problème avec votre lien d'invitation.
        <br /> Si vous avez déjà créé votre compte, cliquez sur ce{" "}
        <a href={`${config.url}/login`} className="text-theme underline">
          lien
        </a>{" "}
        afin de vous connecter.
        <br /> Si le problème subsiste, contactez l'administrateur afin qu'il puisse vous renvoyer une invitation.
      </p>
    );
};
