export enum PROVIDER_BATCH_ENROLLMENT_STATUS {
  CONTACT_PENDING = "contact_pending",
  INVITATION_SENT_BY_ADMIN = "invitation_sent_by_admin",
  INVITATION_REFUSED_BY_ADMIN = "invitation_refused_by_admin",
  ACCOUNT_REGISTERED = "account_registered",
  FUNDING_REQUEST_SUBMITTED_BY_STUDENT = "funding_request_submitted_by_student",
  FUNDING_REQUEST_SUBMITTED_TO_FUNDER = "funding_request_submitted_to_funder",
  FUNDING_REQUEST_ACCEPTED_BY_FUNDER = "funding_request_accepted_by_funder",
  ENROLLMENT_CONFIRMED = "enrollment_confirmed",
}

export const CANCELLED_BATCH_ENROLLMENT_STATUSES = [PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_REFUSED_BY_ADMIN];
