import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import {
  ProviderBatchFormInputs,
  providerBatchSchema,
  defaultProviderBatchValues,
  formatFormDataToBodyData,
} from "@academy-context/primary/shared/provider-batch/form-validation/batch";
import { CreateProviderBatchBody } from "@user-management-context/shared/application/ports/provider-repository";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";

interface Props {
  trainings: ProviderTraining[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (body: CreateProviderBatchBody) => void;
}

export const CreateBatchModal = ({ isOpen, onClose, onSubmit, trainings }: Props) => {
  const form = useForm<ProviderBatchFormInputs>({ resolver: yupResolver(providerBatchSchema), defaultValues: defaultProviderBatchValues });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = form;

  const handleOnSubmit = (formBody: ProviderBatchFormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  const today = new Date().toISOString();
  const trainingId = watch("trainingId");
  const trainingOptions: ComboboxOptions[] = trainings.map(training => ({ label: training.title, value: training.id }));

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Ajouter une session</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="trainingId" className="text-right">
                  Formation
                </Label>
                <FormField
                  control={form.control}
                  name="trainingId"
                  render={({ field }) => (
                    <FormItem className="col-span-3 flex flex-col">
                      <FormControl>
                        <Combobox
                          options={trainingOptions}
                          value={trainingId}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner formation..."
                          search={{
                            notFoundText: "Pas de formation trouvée.",
                            commandInputPlaceHolder: "Chercher formation...",
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="startDate" className="text-right">
                  Date de début
                </Label>
                <FormField
                  control={form.control}
                  name="startDate"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-3 flex flex-col">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="endDate" className="text-right">
                  Date de fin
                </Label>
                <FormField
                  control={form.control}
                  name="endDate"
                  render={({ field }) => {
                    return (
                      <FormItem className="col-span-3 flex flex-col">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            <DialogFooter>
              <Button onClick={onClose} variant="outline" type="button">
                Annuler
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Valider
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
