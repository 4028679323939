import { TeacherQualiopiTrainingFollowUpListContainer } from "@academy-context/primary/teacher/qualiopi-training-follow-up/qualiopi-training-follow-up-list.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const TeacherQualiopiTrainingFollowUpListPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <TeacherQualiopiTrainingFollowUpListContainer />
    </Page>
  );
};
