import { TestContext, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { AnyObject } from "yup/lib/types";
import { CreateProviderBatchBody } from "@user-management-context/shared/application/ports/provider-repository";

export type ProviderBatchFormInputs = {
  trainingId: string;
  startDate: string;
  endDate: string;
};

export const defaultProviderBatchValues = {
  trainingId: "",
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
};

const validateIntervalCoherence = (end: string | undefined, context: TestContext<AnyObject>): boolean => {
  const {
    parent: { startDate },
  } = context;

  if (!end || !startDate) {
    return true;
  }

  return new Date(end).getTime() >= new Date(startDate).getTime();
};

export const providerBatchSchema = object().shape({
  trainingId: string().max(255).required(REQUIRED_FIELD),
  startDate: string().max(255).required(REQUIRED_FIELD),
  endDate: string()
    .max(255)
    .required(REQUIRED_FIELD)
    .test("end is after start", "La date de fin doit être après la date de début", validateIntervalCoherence),
});

export const formatFormDataToBodyData = (providerBatch: ProviderBatchFormInputs): CreateProviderBatchBody => {
  const { startDate, endDate, trainingId } = providerBatch;

  return {
    trainingId,
    interval: {
      start: new Date(startDate).toISOString(),
      end: new Date(endDate).toISOString(),
    },
  };
};
