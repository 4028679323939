import { TeacherQualiopiTrainingObjectivesContainer } from "@academy-context/primary/teacher/qualiopi-training-follow-up/qualiopi-training-objectives.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const TeacherQualiopiTrainingObjectivesPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <TeacherQualiopiTrainingObjectivesContainer />
    </Page>
  );
};
