import { DateProvider } from "@shared-kernel/application/ports/date-provider/date-provider";
import { NumberGenerator } from "@shared-kernel/application/ports/number-generator";

export interface Adapters {
  numberGenerator: NumberGenerator;
  dateProvider: DateProvider;
}

export let commonAdapters: Partial<Adapters> = {};

export const initCommonAdapters = (adapters: Partial<Adapters>) => {
  commonAdapters = adapters;
};
