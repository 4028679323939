import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminUpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";

export const updateStudent = createAsyncThunk<void, { id: string; body: AdminUpdateStudentBody }, { extra: Partial<Dependencies> }>(
  "students/updateStudent",
  async ({ id, body }: { id: string; body: AdminUpdateStudentBody }, { extra: { adminStudentGateway } }) => {
    await adminStudentGateway!.update(id, body);
  }
);

export const resetUpdateStudent = createAction("students/resetUpdateStudent");
