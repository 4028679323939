import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveProviderBatches } from "@academy-context/read/application/use-cases/provider/provider-batches-retrieval/retrieve-provider-batches";

const selectProviderBatchEnrollmentConfirmation = (state: AppState) => state.providerBatchEnrollmentConfirmation;
const selectProviderBatchParticipationInvoiceSubmission = (state: AppState) => state.providerBatchParticipationInvoiceSubmission;

export const useProviderBatchUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing: confirmationProcessing } = useAppSelector(selectProviderBatchEnrollmentConfirmation);
  const { processing: invoiceProcessing } = useAppSelector(selectProviderBatchParticipationInvoiceSubmission);
  useEffect(() => {
    if (confirmationProcessing === "success" || invoiceProcessing === "success") {
      toast({ description: "Dossier élève mis à jour !" });
      dispatch(retrieveProviderBatches());
    } else if (confirmationProcessing === "failed" || invoiceProcessing === "failed") {
      toast({ description: "Erreur lors de la mise à jour du dossier élève", variant: "destructive" });
    }
  }, [confirmationProcessing, dispatch, invoiceProcessing, toast]);
};
