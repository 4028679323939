import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";

const selectPasswordUpdate = (state: AppState) => state.updatePassword;

export const usePasswordUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useAppSelector(selectPasswordUpdate);

  useEffect(() => {
    if (fetching === "success") {
      navigate(`/login`);
      toast({ description: "Mot de passe mis à jour!" });
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la mise à jour du mot de passe. Veuillez contacter l'administrateur", variant: "destructive" });
    }
  }, [fetching, dispatch, navigate, toast]);
};
