import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentProviderBatchItem } from "@academy-context/read/domain/types/student/provider-batch";

export const studentRetrieveProviderBatches = createAsyncThunk<StudentProviderBatchItem[], string, { extra: Partial<Dependencies> }>(
  "providers/studentRetrieveProviderBatches",
  async (providerId: string, { extra: { studentProviderRepository } }) => {
    return studentProviderRepository!.batchList(providerId);
  }
);

export const resetStudentRetrieveProviderBatches = createAction("providers/resetStudentRetrieveProviderBatches");
