import { CreateLessonBody } from "@academy-context/write/domain/types/admin/lesson";
import { AnySchema, mixed, number, object, string } from "yup";
import {
  MAX_HOURLY_PRICE,
  ADMIN_MAX_LESSON_HOURS,
  MIN_LESSON_HOURS,
  ADMIN_MIN_HOURLY_PRICE,
  MIN_STUDIO_PRICE,
  MAX_STUDIO_PRICE,
} from "../../../../write/domain/constants/shared";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { AnyObject } from "immer/dist/internal";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { PLACE_VALUE } from "../../../../read/domain/services/locations";
import { STUDIO_CHOICES } from "@academy-context/read/domain/types/admin/lesson";

export type FormInputs = {
  date: string;
  student: string;
  teacher: string;
  hours: number;
  hourlyPrice: number;
  commentary: string;
  placeOption: STUDIO_CHOICES;
  placeName: Nullable<PLACE_VALUE>;
  placePrice: number;
};

export const defaultValues: FormInputs = {
  date: "",
  student: "",
  teacher: "",
  hours: 1,
  hourlyPrice: ADMIN_MIN_HOURLY_PRICE,
  commentary: "",
  placeOption: STUDIO_CHOICES.FREE,
  placeName: PLACE_VALUE.PERSO,
  placePrice: 0,
};

const setMaxHours = (hoursLeftOnConvention: number | null, schema: AnyObject) =>
  hoursLeftOnConvention != null && hoursLeftOnConvention < ADMIN_MAX_LESSON_HOURS
    ? schema["max"](hoursLeftOnConvention, `Maximum ${hoursLeftOnConvention}h`)
    : schema["max"](ADMIN_MAX_LESSON_HOURS, `Maximum ${ADMIN_MAX_LESSON_HOURS}h`);

export const schema = object().shape({
  date: string().max(255).required(REQUIRED_FIELD),
  student: string().max(255).required(REQUIRED_FIELD),
  teacher: string().max(255).required(REQUIRED_FIELD),
  hours: number()
    .typeError("Minimum 0,25h")
    .min(MIN_LESSON_HOURS, "Minimum 0,25h")
    .when("$hoursLeftOnConvention", setMaxHours)
    .required(REQUIRED_FIELD),
  hourlyPrice: number()
    .typeError(`Minimum ${ADMIN_MIN_HOURLY_PRICE}€`)
    .positive(`Minimum ${ADMIN_MIN_HOURLY_PRICE}€`)
    .min(ADMIN_MIN_HOURLY_PRICE, `Minimum ${ADMIN_MIN_HOURLY_PRICE}€`)
    .max(MAX_HOURLY_PRICE, `Maximum ${MAX_HOURLY_PRICE}€`)
    .required(REQUIRED_FIELD),
  placeOption: mixed<STUDIO_CHOICES>().oneOf(Object.values(STUDIO_CHOICES)),
  placeName: string()
    .max(255)
    .when("placeOption", {
      is: (value: STUDIO_CHOICES) => {
        return value !== STUDIO_CHOICES.PAID_BY_TEACHER;
      },
      then: (schema: AnySchema) => schema.required(REQUIRED_FIELD),
      otherwise: (schema: AnySchema) => schema.nullable(),
    }),
  placePrice: number()
    .typeError(`Minimum ${MIN_STUDIO_PRICE}€`)
    .positive(`Minimum ${MAX_STUDIO_PRICE}€`)
    .min(MIN_STUDIO_PRICE, `Minimum ${MIN_STUDIO_PRICE}€`)
    .max(MAX_STUDIO_PRICE, `Maximum ${MAX_STUDIO_PRICE}€`)
    .when("placeOption", {
      is: (value: STUDIO_CHOICES) => {
        return value === STUDIO_CHOICES.PAID_BY_TEACHER;
      },
      then: (schema: AnySchema) => schema.required("Le prix ou la valeur doit être remplie"),
      otherwise: (schema: AnySchema) => schema.nullable(),
    }),
  commentary: string().max(240),
});

export const formatFormDataToBodyData = (lesson: FormInputs, isUnderOldConvention: boolean): CreateLessonBody => {
  let place: {
    id: Nullable<PLACE_VALUE>;
    price: Nullable<number>;
  };
  if (lesson.placeOption === STUDIO_CHOICES.FREE) {
    place = { id: PLACE_VALUE.PERSO, price: null };
  } else if (lesson.placeOption === STUDIO_CHOICES.PAID_BY_TEACHER) {
    place = { id: null, price: lesson.placePrice };
  } else if (lesson.placeOption === STUDIO_CHOICES.PAID_BY_OPERA_OFF && lesson.placeName) {
    place = { id: lesson.placeName, price: null };
  } else {
    throw new Error("Wrong studio choice");
  }

  return {
    date: lesson.date,
    commentary: lesson.commentary,
    studentId: lesson.student,
    teacherId: lesson.teacher,
    hours: { quantity: lesson.hours, hourlyPrice: isUnderOldConvention ? null : lesson.hourlyPrice },
    place,
  };
};
