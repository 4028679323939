import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveProviderBatches } from "@academy-context/read/application/use-cases/provider/provider-batches-retrieval/retrieve-provider-batches";

const selectStudentBatchEnrollment = (state: AppState) => state.providerStudentBatchEnrollment;

export const useStudentEnrolled = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectStudentBatchEnrollment);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Elève ajouté !" });
      dispatch(retrieveProviderBatches());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'ajout de l'élève", variant: "destructive" });
    }
  }, [processing, dispatch, toast]);
};
