import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../../redux/store";
import { ReviewedFundingRequestVM } from "../../../../../domain/types/admin/funding-request";

export const retrieveReviewedFundingRequests = createAsyncThunk<ReviewedFundingRequestVM[], void, { extra: Partial<Dependencies> }>(
  "fundingRequests/retrieveReviewedFundingRequests",
  async (_: void, { extra: { fundingRequestGateway } }) => {
    const requests = await fundingRequestGateway!.getAll({ status: ["reviewed"] });
    const response: ReviewedFundingRequestVM[] = [];
    for (const r of requests) {
      if (r.status === "reviewed") {
        response.push(r);
      }
    }
    return response;
  }
);
