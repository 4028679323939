import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { QualiopiTrainingFollowUpDetailComponent } from "@academy-context/primary/shared/qualiopi-training-follow-up/qualiopi-training-follow-up-detail.components";
import { teacherRetrieveTraining } from "@academy-context/read/application/use-cases/teacher/training-retrieval/retrieve-training";

export const TeacherQualiopiTrainingFollowUpDetailContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingFollowUp } = useAppSelector((state: AppState) => state.teacherTrainingRetrieval);

  useEffect(() => {
    if (id) dispatch(teacherRetrieveTraining(id));
  }, [dispatch, id]);

  return <QualiopiTrainingFollowUpDetailComponent trainingFollowUp={trainingFollowUp} />;
};
