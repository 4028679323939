import { TeacherQualiopiTrainingAttendanceSheetListContainer } from "@academy-context/primary/teacher/qualiopi-training-follow-up/qualiopi-training-attendance-sheet-list.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const AdminQualiopiTrainingAttendanceSheetListPage = () => {
  return (
    <Page title="Suivi Qualiopi">
      <TeacherQualiopiTrainingAttendanceSheetListContainer isAdmin />
    </Page>
  );
};
