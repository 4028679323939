import { addDays, addMonths, endOfMonth, format, formatISO, isSunday, startOfDay, startOfMonth } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { fr } from "date-fns/locale";
import { commonAdapters } from "src/common-adapters";
import { assertArrLength } from "@utils/utils";
import { TrainingDays } from "@academy-context/read/domain/types/admin/funding-request";
import { USER_TIMEZONE } from "@user-management-context/read/domain/types/user";

export const selectDateOptions = (now: Date) => {
  const zonedDate = toZonedTime(now, USER_TIMEZONE);

  const dateOptions = [];
  for (let index = 1; index < 9; index++) {
    const start = addMonths(zonedDate, index);
    const endMonth = addMonths(zonedDate, index + 1);
    const startMonthLabel = formatToLabel(start);
    const endMonthLabel = formatToLabel(endMonth);
    dateOptions.push({ label: `${startMonthLabel} - ${endMonthLabel}`, value: startOfMonth(start).toISOString() });
  }

  return dateOptions;
};

function formatToLabel(date: Date): string {
  const label = format(date, "MMMM yyyy", { locale: fr });
  return label[0]!.toUpperCase() + label.substring(1);
}

export function computeDaysSpreadOver2Months(startDate: Date): TrainingDays {
  const { numberGenerator } = commonAdapters;

  const zonedDate = toZonedTime(startDate, USER_TIMEZONE);
  const zonedStartOfMonth = startOfMonth(zonedDate);
  let current = new Date(zonedStartOfMonth);
  const result: Date[] = [];

  result.push(current);

  // Spread over two months
  while (result.length < 10) {
    // Add a day to the current date

    current = addDays(current, numberGenerator!.getRandomNumberBetween(10, 11));
    // If the date falls on a Sunday, skip it
    if (isSunday(current)) {
      current = addDays(current, 1);
    }
    result.push(current);

    current = addDays(current, 1);
    // If the date falls on a Sunday, skip it
    if (isSunday(current)) {
      current = addDays(current, 1);
    }
    result.push(current);

    // If we reached 9 dates, get the last day of the next month for the 10th date
    if (result.length === 9) {
      // Move to the next month
      current = addMonths(zonedStartOfMonth, 1);

      // Get the last day of that month
      current = startOfDay(endOfMonth(current));

      result.push(current);
    }
  }

  assertArrLength(result, 10);

  return {
    day1: formatISO(result[0]),
    day2: formatISO(result[1]),
    day3: formatISO(result[2]),
    day4: formatISO(result[3]),
    day5: formatISO(result[4]),
    day6: formatISO(result[5]),
    day7: formatISO(result[6]),
    day8: formatISO(result[7]),
    day9: formatISO(result[8]),
    day10: formatISO(result[9]),
  };
}
