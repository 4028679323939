import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../../config/backend";
import { StudentConventionListVM } from "@academy-context/read/domain/types/student/convention";
import { StudentConventionPort } from "@academy-context/shared/application/ports/student-convention-port";

export class BackendStudentConventionGateway extends HttpGateway implements StudentConventionPort {
  private _route: string = "student/v1/conventions";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<StudentConventionListVM[]> {
    const { data: conventions } = await this._instance.get(this._route);
    return conventions;
  }
}
