import { ConventionCard } from "./convention-card";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { ScrollArea } from "@shared-kernel/primary/shared/shadcn/ui/scroll-area";

interface Props {
  convention: { teacher: string; hours: number; hoursDone: number } | null;
}

export const ActiveConventions = ({ convention }: Props) => {
  return (
    <CustomCard title="CONVENTION ACTIVE" cardClassname="h-full">
      <ScrollArea className="size-full">{convention && <ConventionCard convention={convention} />}</ScrollArea>
    </CustomCard>
  );
};
