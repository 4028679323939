import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ApproveProviderBatchParticipationPaymentBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export const approveProviderPayment = createAsyncThunk<
  void,
  ApproveProviderBatchParticipationPaymentBody,
  { extra: Partial<Dependencies> }
>(
  "providerPayments/approveProviderPayment",
  async (data: ApproveProviderBatchParticipationPaymentBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.approvePayment(data);
  }
);

export const resetApproveProviderPayment = createAction("providerPayments/resetApproveProviderPayment");
