import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../../../config/backend";
import { StudentProviderRepository } from "@user-management-context/shared/application/ports/student-provider-repository";
import { StudentProviderBatchItem } from "@academy-context/read/domain/types/student/provider-batch";
import { StudentProviderEnrollment } from "@academy-context/read/domain/types/student/provider-enrollment";

export class StudentBackendProviderRepository extends HttpGateway implements StudentProviderRepository {
  private _route: string = "v1/student/providers";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async batchList(providerId: string): Promise<StudentProviderBatchItem[]> {
    const { data: batches } = await this._instance.get(`${this._route}/${providerId}/batches`);
    return batches;
  }

  async getEligibleEnrollment(): Promise<StudentProviderEnrollment> {
    const { data: enrollment } = await this._instance.get(`${this._route}/eligible-enrollment`);
    return enrollment;
  }
}
