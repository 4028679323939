import { boolean, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { ProviderStudentEnrollmentToBatchBody } from "@user-management-context/shared/application/ports/provider-repository";
import { MIN_BATCH_PRICE } from "@academy-context/primary/provider/provider-batches/form-validation/batch-enrollment-confirmation/batch-enrollment-confirmation";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

export type ProviderStudentEnrollmentFormInputs = {
  price: number;
  email: string;
  name: string;
  lastName: string;
  mobile: string;
  isValidEmail: boolean;
};

export const providerStudentEnrollmentDefaultValues = {
  price: 0,
  email: "",
  name: "",
  lastName: "",
  mobile: "",
  isValidEmail: true,
};

export const providerStudentEnrollmentSchema = object().shape({
  price: number()
    .min(MIN_BATCH_PRICE, `minimum ${MIN_BATCH_PRICE}€`)
    .max(3000, `maximum ${3000}€`)
    .typeError("Une valeur est nécessaire")
    .required(REQUIRED_FIELD),
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  mobile: string()
    .required("Veuillez saisir un numéro de téléphone")
    .test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  isValidEmail: boolean().isTrue("Cet email est déjà utilisé par un autre utilisateur"),
});

export const formatFormDataToBodyData = (
  enrollment: ProviderStudentEnrollmentFormInputs & { batchId: string }
): ProviderStudentEnrollmentToBatchBody => {
  const { batchId, name, lastName, mobile, email, price } = enrollment;

  return {
    batchId,
    price,
    student: {
      name,
      lastName,
      mobile,
      email,
    },
  };
};

export const formatEnrollmentDataToFormData = (batch: ProviderBatchItem): ProviderStudentEnrollmentFormInputs => {
  const { price } = batch;

  return {
    price: price ?? providerStudentEnrollmentDefaultValues.price,
    email: providerStudentEnrollmentDefaultValues.email,
    name: providerStudentEnrollmentDefaultValues.name,
    lastName: providerStudentEnrollmentDefaultValues.lastName,
    mobile: providerStudentEnrollmentDefaultValues.mobile,
    isValidEmail: providerStudentEnrollmentDefaultValues.isValidEmail,
  };
};
