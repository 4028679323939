import clsx from "clsx";

interface Props {
  error?: string;
  className?: string;
}

const InputError = ({ error = "", className }: Props) => {
  return error ? <span className={clsx("mt-2 inline-block text-red-500", className)}>{error}</span> : null;
};

export default InputError;
