import { ProviderBatchParticipationPaymentRow } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/selectors/provider-batch-participation-payment-list-selectors";
import { formatDateToLocale, formatToCurrency } from "@utils/formatting";
import { Separator } from "@components/ui/separator";
import { AlertDialogDescription, AlertDialogHeader, AlertDialogTitle } from "@components/ui/alert-dialog";

interface Props {
  payment: ProviderBatchParticipationPaymentRow;
}

export const ProviderPaymentInformationHeader = ({ payment: providerPayment }: Props) => {
  const { provider, invoice, payment, creationDate, training, batch, student } = providerPayment;

  return (
    <>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {training.title} - {formatDateToLocale(batch.interval.start)} - {formatDateToLocale(batch.interval.end)}
        </AlertDialogTitle>
        <AlertDialogDescription>Facture reçue le {formatDateToLocale(creationDate)}</AlertDialogDescription>
      </AlertDialogHeader>
      <Separator />
      <div className="flex flex-col text-sm text-muted-foreground">
        <span className="font-semibold">Elève Débiteur</span>
        <span>{student}</span>
      </div>
      <Separator />
      <div className="flex flex-col text-sm text-muted-foreground">
        <span className="font-semibold">Bénéficiaire</span>
        <span className="">{provider.name}</span>
        <span>{provider.iban}</span>
      </div>
      <Separator />
      <div className="flex flex-col text-sm text-muted-foreground">
        <span className="font-semibold">Informations</span>
        <div className="flex justify-between">
          <span>Numéro de facture</span>
          <span>{invoice.number}</span>
        </div>
        <div className="flex justify-between text-sm text-muted-foreground">
          <a
            href={invoice.url}
            target="_blank"
            className="text-sm text-blue-600 underline visited:text-purple-600 hover:text-blue-800"
            rel="noreferrer"
          >
            Facture
          </a>
          <span>{formatToCurrency(payment.amount)}</span>
        </div>
      </div>
      <Separator />
    </>
  );
};
