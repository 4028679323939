import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SubmitBatchParticipationInvoiceBody } from "@user-management-context/shared/application/ports/provider-repository";
import { Dependencies } from "src/redux/store";

export const submitProviderBatchParticipationInvoice = createAsyncThunk<
  void,
  SubmitBatchParticipationInvoiceBody,
  { extra: Partial<Dependencies> }
>(
  "providers/submitProviderBatchParticipationInvoice",
  async (body: SubmitBatchParticipationInvoiceBody, { extra: { providerRepository } }) => {
    return providerRepository!.submitBatchParticipationInvoice(body);
  }
);

export const resetSubmitProviderBatchParticipationInvoice = createAction("providers/resetSubmitProviderBatchParticipationInvoice");
