import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../../config/backend";
import { CreateExpenseBody } from "@academy-context/write/domain/types/admin/expense";
import { AdminExpenseListVM } from "@academy-context/read/domain/types/admin/expense";
import { AdminExpensePort } from "@academy-context/shared/application/ports/expense-port";

export class AdminBackendExpenseGateway extends HttpGateway implements AdminExpensePort {
  private _route: string = "admin/expenses/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<AdminExpenseListVM[]> {
    const { data: expenses } = await this._instance.get(this._route);
    return expenses;
  }

  async create(expense: CreateExpenseBody): Promise<string> {
    const { data: expenseId } = await this._instance.post(this._route, expense);
    return expenseId;
  }
}
