import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveTrainingObjectives } from "../../../read/application/use-cases/shared/training-objectives-retrieval/retrieve-training-objectives";
import { useObjectiveUpdate } from "../../shared/training/use-objective-update";
import { QualiopiTrainingObjectives } from "@academy-context/primary/shared/training-objective-form/training-objectives.components";

export const ProviderQualiopiTrainingObjectivesContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  // TODO: Change
  const { data: objectives } = useAppSelector((state: AppState) => state.trainingObjectivesRetrieval);

  // TODO: Change
  const role = "teacher";

  useEffect(() => {
    if (id) dispatch(retrieveTrainingObjectives({ trainingId: id, role }));
  }, [dispatch, id]);

  useObjectiveUpdate({ trainingId: id!, role });

  return <QualiopiTrainingObjectives objectives={objectives} trainingFollowUpId={id} role={role} />;
};
