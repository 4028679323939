import { useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { useForm } from "react-hook-form";
import {
  ProviderPaymentRefusalFormInputs,
  providerPaymentRefusalSchema,
  providerPaymentRefusalDefaultValues,
  formatProviderPaymentRefusalFormDataToBodyData,
  formatProviderPaymentRefusalDataToFormData,
} from "./form-validation/provider-payment/provider-payment-refusal";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProviderBatchParticipationPaymentRow } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/selectors/provider-batch-participation-payment-list-selectors";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@components/ui/form";
import { refuseProviderPayment } from "@academy-context/write/application/use-cases/admin/provider-payment-refusal/refuse-provider-payment";
import { Input } from "@components/ui/input";
import { ProviderPaymentInformationHeader } from "@academy-context/primary/admin/shared/components/transfer/payment-information-header";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter } from "@components/ui/alert-dialog";

interface Props {
  payment: ProviderBatchParticipationPaymentRow;
  isOpen: boolean;
  onClose: () => void;
}

export const RefuseProviderPaymentModal = ({ payment, isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<ProviderPaymentRefusalFormInputs>({
    resolver: yupResolver(providerPaymentRefusalSchema),
    defaultValues: providerPaymentRefusalDefaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  const handleOnSubmit = async (formBody: ProviderPaymentRefusalFormInputs) => {
    onClose();
    const body = formatProviderPaymentRefusalFormDataToBodyData(formBody, payment);
    dispatch(refuseProviderPayment(body));
  };

  useEffect(() => {
    reset(formatProviderPaymentRefusalDataToFormData(payment));
  }, [payment, reset]);

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="min-w-[600px]">
        <ProviderPaymentInformationHeader payment={payment} />
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="adminCommentary"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Commentaire</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <AlertDialogFooter className="mt-4">
              <AlertDialogCancel onClick={onClose} type="button">
                Annuler
              </AlertDialogCancel>
              <AlertDialogAction type="submit" disabled={!isDirty}>
                Valider
              </AlertDialogAction>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
};
