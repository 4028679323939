import React from "react";
import ReactDOM from "react-dom/client";
import App from "src/App";
import { Provider } from "react-redux";
import { initReduxStore } from "@redux/store";
import { InMemoryFundingRequestGateway } from "@academy-context/shared/secondary/repository/in-memory-funding-request-gateway";
import { InMemoryAdminBudgetGateway } from "@academy-context/shared/secondary/repository/admin/in-memory-admin-budget-gateway";
import { InMemoryTeacherLessonGateway } from "@academy-context/shared/secondary/repository/teacher/in-memory-lesson-gateway";
import { InMemoryTransferGateway } from "@academy-context/shared/secondary/repository/in-memory-transfer-gateway";
import { teacherListElement, teachers } from "./mocks/teachers";
import { fundingRequests } from "./mocks/funding-request";
import { budgets } from "./mocks/budgets";
import { lessons } from "./mocks/lessons";
import { transfers, transfersSummary } from "./mocks/transfers";
import { BackendUserGateway } from "@user-management-context/shared/secondary/repository/user-repository/backend-user-gateway";
import { AdminBackendUserInvitationAdapter } from "@user-management-context/shared/secondary/repository/admin/user-invitation-adapter/backend-user-invitation-adapter";
import { CookieTokenStorageGateway } from "@shared-kernel/secondary/cookies-token-storage-gateway";
import { students, studentsWithBalance } from "./mocks/students";
import { InMemoryUserGateway } from "@user-management-context/shared/secondary/repository/user-repository/in-memory-user-gateway";
import { users } from "./mocks/users";
import { AdminBackendBudgetGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-budget-gateway";
import { AdminBackendLessonGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-lesson-gateway";
import { AdminBackendStudentGateway } from "@user-management-context/shared/secondary/repository/admin/admin-backend-student-gateway";
import { AdminBackendTeacherGateway } from "@user-management-context/shared/secondary/repository/admin/admin-backend-teacher-gateway";
import { InMemoryAdminLessonGateway } from "@academy-context/shared/secondary/repository/admin/in-memory-admin-lesson-gateway";
import { InMemoryAdminStudentGateway } from "@user-management-context/shared/secondary/repository/admin/in-memory-admin-student-gateway";
import { InMemoryAdminTeacherGateway } from "@user-management-context/shared/secondary/repository/admin/in-memory-admin-teacher-gateway";
import { AdminBackendTransferGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-transfer-gateway";
import { BackendTeacherGateway } from "@user-management-context/shared/secondary/repository/teacher/backend-teacher-gateway";
import { BackendTeacherLessonGateway } from "@academy-context/shared/secondary/repository/teacher/backend-lesson-gateway";
import { BackendTeacherStudentGateway } from "@user-management-context/shared/secondary/repository/teacher/backend-student-gateway";
import { BackendStudentGateway } from "@user-management-context/shared/secondary/repository/student/backend-student-gateway";
import { StudentBackendLessonGateway } from "@academy-context/shared/secondary/repository/student/backend-lesson-gateway";
import { InMemoryAdminExpenseGateway } from "@academy-context/shared/secondary/repository/admin/in-memory-admin-expense-gateway";
import { expenses } from "./mocks/expenses";
import { AdminBackendExpenseGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-expense-gateway";
import { StudentBackendExpenseGateway } from "@academy-context/shared/secondary/repository/student/backend-expense-gateway";
import { AdminBackendConventionGateway } from "@academy-context/shared/secondary/repository/admin/admin-backend-convention-gateway";
import { InMemoryAdminConventionGateway } from "@academy-context/shared/secondary/repository/admin/in-memory-admin-convention-gateway";
import { InMemoryTeacherConventionGateway } from "@academy-context/shared/secondary/repository/teacher/in-memory-teacher-convention-gateway";
import { BackendTeacherConventionGateway } from "@academy-context/shared/secondary/repository/teacher/backend-convention-gateway";
import { InMemoryStudentConventionGateway } from "@academy-context/shared/secondary/repository/student/in-memory-student-convention-gateway";
import { BackendStudentConventionGateway } from "@academy-context/shared/secondary/repository/student/backend-convention-gateway";
import { InMemoryStudentBudgetGateway } from "@academy-context/shared/secondary/repository/student/in-memory-student-budget-gateway";
import { BackendStudentBudgetGateway } from "@academy-context/shared/secondary/repository/student/backend-budget-gateway";
import { BackendFundingRequestGateway } from "@academy-context/shared/secondary/repository/backend-funding-request";
import { BackendTrainingGateway } from "@academy-context/shared/secondary/repository/backend-training-gateway";
import { initCommonAdapters } from "./common-adapters";
import { RealNumberGenerator } from "@shared-kernel/secondary/number-generator/real-number-generator";
import { BackendRefundRequestGateway } from "@academy-context/shared/secondary/repository/student/backend-refund-request-gateway";
import { BackendCollaborationRequestGateway } from "@academy-context/shared/secondary/repository/teacher/backend-collaboration-request-gateway";
import { InMemoryProviderRepository } from "@user-management-context/shared/secondary/repository/provider/provider-repository/in-memory-provider-repository";
import { StudentBackendProviderRepository } from "@user-management-context/shared/secondary/repository/student/provider-repository/student-backend-provider-repository";
import { InMemoryAdminProviderRepository } from "@user-management-context/shared/secondary/repository/admin/provider-repository/in-memory-provider-repository";
import { AdminBackendProviderRepository } from "@user-management-context/shared/secondary/repository/admin/provider-repository/admin-backend-provider-repository";
import { providerBatches } from "src/mocks/provider-batches";
import { providers } from "src/mocks/providers";
import { providerTrainings } from "src/mocks/provider-trainings";
import { BackendProviderRepository } from "@user-management-context/shared/secondary/repository/provider/provider-repository/backend-provider-repository";
import { RealDateProvider } from "@shared-kernel/secondary/number-generator/real-date-provider";
import { InMemoryAdminInternalTrainingRepository } from "@shared-kernel/secondary/admin/internal-training-repository/in-memory-internal-training-repository";
import { AdminBackendInternalTrainingRepository } from "@shared-kernel/secondary/admin/internal-training-repository/admin-backend-internal-training-repository";
import { ProviderBackendQualiopiTrainingFollowUpRepository } from "@shared-kernel/secondary/provider/qualiopi-training-follow-up-repository/provider-backend-qualiopi-training-follow-up-repository";

let adminStudentGateway;
let adminExpenseGateway;
let adminUserInvitationGateway;
let adminBudgetGateway;
let adminTeacherGateway;
let adminLessonGateway;
let transferGateway;
let adminConventionGateway;
let providerRepository;
let userGateway;
let adminProviderRepository;
let adminInternalTrainingRepository;
const tokenStorageGateway = new CookieTokenStorageGateway();

let teacherLessonGateway;
const teacherGateway = new BackendTeacherGateway(tokenStorageGateway);
const teacherStudentGateway = new BackendTeacherStudentGateway(tokenStorageGateway);
let teacherConventionGateway;

const studentGateway = new BackendStudentGateway(tokenStorageGateway);
const studentLessonGateway = new StudentBackendLessonGateway(tokenStorageGateway);
const studentExpenseGateway = new StudentBackendExpenseGateway(tokenStorageGateway);
let studentConventionGateway;
let studentBudgetGateway;
let fundingRequestGateway;

const trainingGateway = new BackendTrainingGateway(tokenStorageGateway);
const refundRequestGateway = new BackendRefundRequestGateway(tokenStorageGateway);
const collaborationRequestGateway = new BackendCollaborationRequestGateway(tokenStorageGateway);
const studentProviderRepository = new StudentBackendProviderRepository(tokenStorageGateway);
const providerQualiopiTrainingFollowUpRepository = new ProviderBackendQualiopiTrainingFollowUpRepository(tokenStorageGateway);

if ("demo" === import.meta.env["VITE_SERVICE_ENV"]) {
  adminStudentGateway = new InMemoryAdminStudentGateway();
  adminStudentGateway.feedWith(...students);
  adminStudentGateway.feedWithStudentListElement(...studentsWithBalance);

  userGateway = new InMemoryUserGateway();
  userGateway.feedWith(...users);

  adminBudgetGateway = new InMemoryAdminBudgetGateway();
  adminBudgetGateway.feedWith(...budgets);

  adminTeacherGateway = new InMemoryAdminTeacherGateway();
  adminTeacherGateway.feedWith(...teachers);
  adminTeacherGateway.feedWithTeacherListElement(...teacherListElement);

  adminLessonGateway = new InMemoryAdminLessonGateway();
  adminLessonGateway.feedWith(...lessons);

  transferGateway = new InMemoryTransferGateway();
  transferGateway.feedWithTransferSummaryVM(transfersSummary);
  transferGateway.feedWith(...transfers);

  adminExpenseGateway = new InMemoryAdminExpenseGateway();
  adminExpenseGateway.feedWith(...expenses);
  adminConventionGateway = new InMemoryAdminConventionGateway();

  teacherConventionGateway = new InMemoryTeacherConventionGateway();
  studentConventionGateway = new InMemoryStudentConventionGateway();
  studentBudgetGateway = new InMemoryStudentBudgetGateway();

  // TODO if I have time
  // teacherGateway = new BackendTeacherGateway({} as CookieTokenStorageGateway);
  // teacherStudentGateway = new BackendTeacherStudentGateway({} as CookieTokenStorageGateway);
  // studentGateway = new BackendStudentGateway({} as CookieTokenStorageGateway);
  // studentLessonGateway = new StudentBackendLessonGateway({} as CookieTokenStorageGateway);
  // studentExpenseGateway = new StudentBackendExpenseGateway({} as CookieTokenStorageGateway);

  teacherLessonGateway = new InMemoryTeacherLessonGateway();
  teacherLessonGateway.feedWith(...lessons);

  fundingRequestGateway = new InMemoryFundingRequestGateway();
  fundingRequestGateway.feedWith(...fundingRequests);

  adminProviderRepository = new InMemoryAdminProviderRepository();
  adminProviderRepository.feedWithProviders(...providers);
  adminProviderRepository.feedWithTrainings(...providerTrainings);
  providerRepository = new InMemoryProviderRepository();
  providerRepository.feedWith(...providerBatches);
  adminInternalTrainingRepository = new InMemoryAdminInternalTrainingRepository();
} else {
  userGateway = new BackendUserGateway(tokenStorageGateway);

  adminExpenseGateway = new AdminBackendExpenseGateway(tokenStorageGateway);
  adminUserInvitationGateway = new AdminBackendUserInvitationAdapter(tokenStorageGateway);
  adminStudentGateway = new AdminBackendStudentGateway(tokenStorageGateway);
  adminBudgetGateway = new AdminBackendBudgetGateway(tokenStorageGateway);
  adminTeacherGateway = new AdminBackendTeacherGateway(tokenStorageGateway);
  adminLessonGateway = new AdminBackendLessonGateway(tokenStorageGateway);
  transferGateway = new AdminBackendTransferGateway(tokenStorageGateway);
  adminConventionGateway = new AdminBackendConventionGateway(tokenStorageGateway);

  // teacherGateway = new BackendTeacherGateway(tokenStorageGateway);
  // teacherStudentGateway = new BackendTeacherStudentGateway(tokenStorageGateway);
  // studentGateway = new BackendStudentGateway(tokenStorageGateway);
  // studentLessonGateway = new StudentBackendLessonGateway(tokenStorageGateway);
  // studentExpenseGateway = new StudentBackendExpenseGateway(tokenStorageGateway);

  teacherLessonGateway = new BackendTeacherLessonGateway(tokenStorageGateway);
  teacherConventionGateway = new BackendTeacherConventionGateway(tokenStorageGateway);

  studentConventionGateway = new BackendStudentConventionGateway(tokenStorageGateway);
  studentBudgetGateway = new BackendStudentBudgetGateway(tokenStorageGateway);
  fundingRequestGateway = new BackendFundingRequestGateway(tokenStorageGateway);
  providerRepository = new BackendProviderRepository(tokenStorageGateway);
  adminProviderRepository = new AdminBackendProviderRepository(tokenStorageGateway);
  adminInternalTrainingRepository = new AdminBackendInternalTrainingRepository(tokenStorageGateway);
}

const store = initReduxStore({
  adminStudentGateway,
  fundingRequestGateway,
  userGateway,
  adminTeacherGateway,
  adminBudgetGateway,
  teacherLessonGateway,
  transferGateway,
  adminUserInvitationGateway,
  adminLessonGateway,
  teacherGateway,
  teacherStudentGateway,
  studentGateway,
  studentLessonGateway,
  adminExpenseGateway,
  studentExpenseGateway,
  adminConventionGateway,
  teacherConventionGateway,
  studentConventionGateway,
  studentBudgetGateway,
  trainingGateway,
  refundRequestGateway,
  collaborationRequestGateway,
  adminProviderRepository,
  providerRepository,
  studentProviderRepository,
  adminInternalTrainingRepository,
  providerQualiopiTrainingFollowUpRepository,
});

initCommonAdapters({
  numberGenerator: new RealNumberGenerator(),
  dateProvider: new RealDateProvider(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(document.getElementById("main") as HTMLElement);

root.render(
  // Be careful, this mode renders elements twice in local dev
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
