import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../../redux/store";
import { ToReviewFundingRequestVM } from "../../../../../domain/types/admin/funding-request";

export const retrieveToReviewFundingRequests = createAsyncThunk<ToReviewFundingRequestVM[], void, { extra: Partial<Dependencies> }>(
  "fundingRequests/retrieveToReviewFundingRequests",
  async (_: void, { extra: { fundingRequestGateway } }) => {
    const requests = await fundingRequestGateway!.getAll({ status: ["to_review"] });
    const response: ToReviewFundingRequestVM[] = [];
    for (const r of requests) {
      if (r.status === "to_review") {
        response.push(r);
      }
    }
    return response;
  }
);
