import { ENROLLMENT_CONTEXT } from "@academy-context/read/domain/types/enums/provider-batch-enrollment-context";
import { PROVIDER_BATCH_ENROLLMENT_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-enrollment-status";
import { PROVIDER_BATCH_PARTICIPATION_STATUS } from "@academy-context/read/domain/types/enums/provider-batch-participation-status";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { providerBatchId, providerId } from "src/test/utils/common";

const defaultProviderBatchItem: ProviderBatchItem = {
  id: providerBatchId,
  title: "Example Batch",
  price: 1000,
  interval: {
    start: "2023-01-01",
    end: "2023-12-31",
  },
  creationDate: "2023-01-01",
  enrollments: [
    {
      id: "enrollment1",
      student: {
        id: "student1",
        profilePictureUrl: null,
        name: "John",
        lastName: "Doe",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.CONTACT_PENDING,
      creationDate: "2023-01-01",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
    {
      id: "enrollment2",
      student: {
        id: "student2",
        profilePictureUrl: null,
        name: "Jane",
        lastName: "Doe",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_REFUSED_BY_ADMIN,
      creationDate: "2023-01-02",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
    {
      id: "enrollment3",
      student: {
        id: "student3",
        profilePictureUrl: null,
        name: "Alice",
        lastName: "Smith",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.INVITATION_SENT_BY_ADMIN,
      creationDate: "2023-01-03",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
    {
      id: "enrollment4",
      student: {
        id: "student4",
        profilePictureUrl: null,
        name: "Bob",
        lastName: "Smith",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.ACCOUNT_REGISTERED,
      creationDate: "2023-01-04",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
    {
      id: "enrollment5",
      student: {
        id: "student5",
        profilePictureUrl: null,
        name: "Charlie",
        lastName: "Brown",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_SUBMITTED_BY_STUDENT,
      creationDate: "2023-01-05",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
    {
      id: "enrollment6",
      student: {
        id: "student6",
        profilePictureUrl: null,
        name: "David",
        lastName: "Johnson",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_SUBMITTED_TO_FUNDER,
      creationDate: "2023-01-06",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
    {
      id: "enrollment7",
      student: {
        id: "student7",
        profilePictureUrl: null,
        name: "Eve",
        lastName: "Williams",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER,
      creationDate: "2023-01-07",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
    {
      id: "enrollment8",
      student: {
        id: "student8",
        profilePictureUrl: null,
        name: "Frank",
        lastName: "Miller",
      },
      price: 100,
      status: PROVIDER_BATCH_ENROLLMENT_STATUS.ENROLLMENT_CONFIRMED,
      creationDate: "2023-01-08",
      cancellationDate: null,
      enrollmentContext: ENROLLMENT_CONTEXT.BY_PROVIDER,
      hasAnActiveAccountAtEnrollment: false,
      lastUpdatedStatusDate: "2023-01-01",
    },
  ],
  participations: [
    {
      id: "participation1",
      student: {
        id: "student9",
        profilePictureUrl: null,
        name: "Grace",
        lastName: "Lee",
      },
      price: 100,
      status: PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_CONFIRMED,
      creationDate: "2023-01-09",
      cancellationDate: null,
    },
    {
      id: "participation2",
      student: {
        id: "student10",
        profilePictureUrl: null,
        name: "Hank",
        lastName: "Kim",
      },
      price: 200,
      status: PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_CONFIRMED,
      creationDate: "2023-01-10",
      cancellationDate: "2023-01-11",
    },
    {
      id: "participation3",
      student: {
        id: "student11",
        profilePictureUrl: null,
        name: "Ivy",
        lastName: "Clark",
      },
      price: 300,
      status: PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_FINISHED,
      creationDate: "2023-01-11",
      cancellationDate: "2023-01-11",
    },
    {
      id: "participation4",
      student: {
        id: "student12",
        profilePictureUrl: null,
        name: "Jack",
        lastName: "Davis",
      },
      price: 400,
      status: PROVIDER_BATCH_PARTICIPATION_STATUS.BATCH_PARTICIPATION_FINISHED,
      creationDate: "2023-01-12",
      cancellationDate: null,
    },
    {
      id: "participation5",
      student: {
        id: "student13",
        profilePictureUrl: null,
        name: "Karen",
        lastName: "Martinez",
      },
      price: 500,
      status: PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_RECEIVED,
      creationDate: "2023-01-13",
      cancellationDate: null,
    },
    {
      id: "participation6",
      student: {
        id: "student14",
        profilePictureUrl: null,
        name: "Leo",
        lastName: "Garcia",
      },
      price: 600,
      status: PROVIDER_BATCH_PARTICIPATION_STATUS.INVOICE_PAID,
      creationDate: "2023-01-14",
      cancellationDate: null,
    },
  ],
  provider: {
    tag: "providerTag",
    id: providerId,
  },
};

export const ProviderBatchBuilder = (data: ProviderBatchItem = defaultProviderBatchItem) => {
  return {
    withId: (id: ProviderBatchItem["id"]) => ProviderBatchBuilder({ ...data, id }),
    withInterval: (interval: ProviderBatchItem["interval"]) => ProviderBatchBuilder({ ...data, interval }),
    withEnrollments: (enrollments: ProviderBatchItem["enrollments"]) => ProviderBatchBuilder({ ...data, enrollments }),
    withParticipations: (participations: ProviderBatchItem["participations"]) => ProviderBatchBuilder({ ...data, participations }),
    withTitle: (title: ProviderBatchItem["title"]) => ProviderBatchBuilder({ ...data, title }),
    build: (): ProviderBatchItem => data,
  };
};
