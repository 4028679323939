import { TeacherConventionPort } from "@academy-context/shared/application/ports/teacher-convention-port";
import { AdminBudgetPort } from "@academy-context/shared/application/ports/budget-port";
import { Action, combineReducers, configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { AppState } from "./app-state";
import rootReducer from "./root-reducer";
import { FundingRequestPort } from "@academy-context/shared/application/ports/funding-request-port";
import { UserGateway } from "@user-management-context/shared/application/ports/user-gateway";
import { TeacherLessonPort } from "@academy-context/shared/application/ports/teacher-lesson-port";
import { TransferPort } from "@academy-context/shared/application/ports/transfer-port";
import { AdminUserInvitationPort } from "@user-management-context/shared/application/ports/user-invitation-port";
import { AdminLessonPort } from "@academy-context/shared/application/ports/admin-lesson-port";
import { AdminStudentGateway } from "@user-management-context/shared/application/ports/admin-student-gateway";
import { AdminTeacherGateway } from "@user-management-context/shared/application/ports/admin-teacher-gateway";
import { TeacherGateway } from "@user-management-context/shared/application/ports/teacher-gateway";
import { TeacherStudentGateway } from "@user-management-context/shared/application/ports/teacher-student-gateway";
import { StudentGateway } from "@user-management-context/shared/application/ports/student-gateway";
import { StudentLessonPort } from "@academy-context/shared/application/ports/student-lesson-port";
import { AdminExpensePort } from "@academy-context/shared/application/ports/expense-port";
import { StudentExpensePort } from "@academy-context/shared/application/ports/student-expense-port";
import { AdminConventionPort } from "@academy-context/shared/application/ports/admin-convention-port";
import { StudentConventionPort } from "@academy-context/shared/application/ports/student-convention-port";
import { StudentBudgetPort } from "@academy-context/shared/application/ports/student-budget-port";
import { addListeners } from "./listeners";
import { TrainingPort } from "@academy-context/shared/application/ports/training-port";
import { RefundRequestPort } from "@academy-context/shared/application/ports/refund-request-port";
import { CollaborationRequestPort } from "@academy-context/shared/application/ports/collaboration-request-port";
import { AdminProviderRepository } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { ProviderRepository } from "@user-management-context/shared/application/ports/provider-repository";
import { StudentProviderRepository } from "@user-management-context/shared/application/ports/student-provider-repository";
import { AdminInternalTrainingRepository } from "@shared-kernel/application/ports/admin/admin-internal-training-repository";
import { ProviderQualiopiTrainingFollowUpRepository } from "@shared-kernel/application/ports/provider/qualiopi-training-follow-up-repository";

export interface Dependencies {
  adminStudentGateway: AdminStudentGateway;
  fundingRequestGateway: FundingRequestPort;
  userGateway: UserGateway;
  adminTeacherGateway: AdminTeacherGateway;
  teacherGateway: TeacherGateway;
  studentGateway: StudentGateway;
  teacherStudentGateway: TeacherStudentGateway;
  adminBudgetGateway: AdminBudgetPort;
  teacherLessonGateway: TeacherLessonPort;
  adminLessonGateway: AdminLessonPort;
  studentLessonGateway: StudentLessonPort;
  transferGateway: TransferPort;
  adminUserInvitationGateway: AdminUserInvitationPort;
  adminExpenseGateway: AdminExpensePort;
  studentExpenseGateway: StudentExpensePort;
  adminConventionGateway: AdminConventionPort;
  teacherConventionGateway: TeacherConventionPort;
  studentConventionGateway: StudentConventionPort;
  studentBudgetGateway: StudentBudgetPort;
  trainingGateway: TrainingPort;
  refundRequestGateway: RefundRequestPort;
  collaborationRequestGateway: CollaborationRequestPort;
  adminProviderRepository: AdminProviderRepository;
  providerRepository: ProviderRepository;
  studentProviderRepository: StudentProviderRepository;
  adminInternalTrainingRepository: AdminInternalTrainingRepository;
  providerQualiopiTrainingFollowUpRepository: ProviderQualiopiTrainingFollowUpRepository;
}

export const listenerMiddleware = createListenerMiddleware<AppState>();
addListeners(listenerMiddleware);

export const initReduxStore = (dependencies: Partial<Dependencies>, preloadedState?: Partial<AppState>) => {
  return configureStore({
    preloadedState,
    reducer: combineReducers(rootReducer),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies,
        },
      }).prepend(listenerMiddleware.middleware),
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppThunk<R> = ThunkAction<R, AppState, any, Action>;
export type ReduxStore = ReturnType<typeof initReduxStore>;
