import { Nullable } from "@shared-kernel/core/types/nullable";
import { USER_STATUS } from "@user-management-context/read/domain/types/admin/student";
import { AppState } from "src/redux/app-state";

export interface AdminStudentListItem {
  studentId: string;
  name: string;
  lastName: string;
  balance: number;
  nextFundingDate: Nullable<string>;
  email: string;
  userStatus: USER_STATUS;
  isBlacklisted: boolean;
  isFundingRequestInProgress: boolean;
}

export const selectStudents = (state: AppState) => {
  const { data } = state.studentsRetrieval;

  const result: AdminStudentListItem[] = data.map(s => {
    return {
      studentId: s.details.studentId,
      name: s.name,
      email: s.email,
      lastName: s.lastName,
      nextFundingDate: s.details.nextFundingDate,
      userStatus: s.userStatus,
      isBlacklisted: s.details.isBlacklisted,
      isFundingRequestInProgress: s.details.isFundingRequestInProgress,
      balance: s.details.balance,
      convention: s.details.convention,
    };
  });

  return result;
};
