import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveProviderBatches } from "@academy-context/read/application/use-cases/provider/provider-batches-retrieval/retrieve-provider-batches";
import { Button } from "@components/ui/button";
import { Switch } from "@components/ui/switch";
import { Label } from "@components/ui/label";
import { ProviderBatchListSkeleton } from "@academy-context/primary/shared/provider-batch/provider-batch-list.skeleton";
import { selectProviderBatchesRetrievalForList } from "@academy-context/read/application/use-cases/provider/provider-batches-retrieval/selectors/provider-batch-list-selectors";
import { ProviderBatch } from "@academy-context/primary/provider/provider-batches/provider-batch.components";
import { useModal } from "@hooks/useModal";
import { CreateBatchModal } from "@academy-context/primary/shared/provider-batch/create-batch-modal";
import { retrieveProviderTrainings } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/retrieve-provider-trainings";
import { selectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import {
  CreateProviderBatchBody,
  ProviderStudentEnrollmentToBatchBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { createProviderBatch } from "@academy-context/write/application/use-cases/shared/provider-batch-creation/create-provider-batch";
import { useProviderBatchCreated } from "@academy-context/primary/provider/provider-batches/use-batch-created";
import { useStudentEnrolled } from "@user-management-context/primary/provider/student-enrollment/use-student-enrolled";
import { useProviderBatchUpdated } from "@academy-context/primary/provider/provider-batches/use-batch-updated";
import { BATCH_STATUS } from "@academy-context/read/application/use-cases/shared/selectors/format-batches-for-list";
import {
  resetUploadProviderBatchParticipationInvoice,
  uploadProviderBatchParticipationInvoice,
} from "@academy-context/write/application/use-cases/provider/provider-batch-participation-invoice-upload/upload-provider-batch-participation-invoice";
import { AppState } from "@redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { enrollStudentToProviderBatch } from "@academy-context/write/application/use-cases/provider/provider-batch-enrollment/enroll-student-to-batch";
import { EnrollProviderStudentModal } from "@user-management-context/primary/provider/student-enrollment/enroll-provider-student-modal";

export const ProviderBatchListContainer = () => {
  const dispatch = useAppDispatch();
  const uploadedInvoice = useAppSelector((state: AppState) => state.providerBatchParticipationInvoiceUpload);
  const { isLoading, batches } = useAppSelector(selectProviderBatchesRetrievalForList);
  const { trainings } = useAppSelector(selectProviderTrainingsRetrievalForList);
  const { closeModal, isModalOpen, openModal } = useModal();
  const { closeModal: closeEnrollmentModal, isModalOpen: isEnrollmentModalOpen, openModal: openEnrollmentModal } = useModal();
  const [displayArchived, setDisplayArchived] = useState(true);
  const [filteredBatches, setFilteredBatches] = useState<typeof batches>([]);
  const [selectedBatch, setSelectedBatch] = useState<Nullable<ProviderBatchItem>>(null);

  useEffect(() => {
    dispatch(retrieveProviderTrainings());
    dispatch(retrieveProviderBatches());
  }, [dispatch]);

  useEffect(() => {
    if (displayArchived) setFilteredBatches(batches);
    else {
      const activeBatches = batches.filter(batch => batch.status !== BATCH_STATUS.ARCHIVED);
      setFilteredBatches(activeBatches);
    }
  }, [batches, displayArchived]);

  useEffect(() => {
    if (selectedBatch) openEnrollmentModal();
  }, [openEnrollmentModal, selectedBatch]);

  const handleSubmit = (body: CreateProviderBatchBody) => {
    dispatch(createProviderBatch(body));
  };

  const onUploadInvoice = useCallback(
    (invoice: File) => {
      dispatch(uploadProviderBatchParticipationInvoice({ providerBatchParticipationInvoice: invoice }));
    },
    [dispatch]
  );

  const onResetUploadInvoice = useCallback(() => {
    dispatch(resetUploadProviderBatchParticipationInvoice());
  }, [dispatch]);

  const onEnrollStudent = (body: ProviderStudentEnrollmentToBatchBody) => {
    dispatch(enrollStudentToProviderBatch(body));
  };

  const onCloseEnrollmentModal = () => {
    setSelectedBatch(null);
    closeEnrollmentModal();
  };

  useProviderBatchCreated();
  useProviderBatchUpdated();
  useStudentEnrolled();

  return (
    <>
      {selectedBatch && (
        <EnrollProviderStudentModal
          isOpen={isEnrollmentModalOpen}
          onClose={onCloseEnrollmentModal}
          onSubmit={onEnrollStudent}
          batch={selectedBatch}
        />
      )}
      <CreateBatchModal isOpen={isModalOpen} onClose={closeModal} onSubmit={handleSubmit} trainings={trainings} />
      <div className="flex justify-between">
        <Button
          onClick={() => {
            openModal();
          }}
        >
          Ajouter une session de formation
        </Button>
        <div className="flex items-center space-x-2" onClick={() => setDisplayArchived(!displayArchived)}>
          <Switch checked={displayArchived} />
          <Label>Afficher les sessions archivées</Label>
        </div>
      </div>
      <div className="mt-3">
        {isLoading ? (
          <ProviderBatchListSkeleton />
        ) : (
          filteredBatches.map(batch => (
            <div className="mt-4" key={batch.id}>
              <ProviderBatch
                batch={batch}
                uploadedInvoice={uploadedInvoice}
                onUploadInvoice={onUploadInvoice}
                onResetUploadInvoice={onResetUploadInvoice}
                setSelectedBatch={(batch: ProviderBatchItem) => setSelectedBatch(batch)}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};
