import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { enrollStudentToProviderBatch, resetInviteStudentToBatch } from "./enroll-student-to-batch";

const initialState: SimpleState = { processing: "idle" };

export const enrollStudentToProviderBatchReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(enrollStudentToProviderBatch.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(enrollStudentToProviderBatch.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(enrollStudentToProviderBatch.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetInviteStudentToBatch, () => {
    return initialState;
  });
});
