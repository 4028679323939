import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import { cn } from "@components/utils/utils";
import { checkStudentBalance } from "@academy-context/read/application/use-cases/provider/student-balance-check/check-student-balance";

interface Props {
  price: number;
  email: string;
}

export const EnrollmentAlert = ({ email, price }: Props) => {
  const dispatch = useAppDispatch();
  const { data: studentData } = useAppSelector((state: AppState) => state.providerStudentRetrievalByEmail);
  const { isStudentBalanceSufficientForBatchEnrollment } = useAppSelector((state: AppState) => state.studentBalanceCheck);
  const [displayAlert, setDisplayAlert] = useState(true);
  const isExistingStudent = Boolean(studentData);

  useEffect(() => {
    if (isExistingStudent) {
      dispatch(checkStudentBalance({ batchEnrollmentPrice: price, email }));
    }
    // This is needed otherwise it is triggered as soon as an email changes even if it is not valid or a student email
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isExistingStudent, price]);

  useEffect(() => {
    const displayAlert = !isExistingStudent || !isStudentBalanceSufficientForBatchEnrollment;
    setDisplayAlert(displayAlert);
  }, [isExistingStudent, isStudentBalanceSufficientForBatchEnrollment]);

  return (
    <div className={cn(!displayAlert && "hidden")}>
      <p className="text-sm font-bold text-red-500">
        Si le solde disponible de l’élève chez OPÉRA Off est insuffisant pour couvrir le coût de la session ou si l’élève est nouveau, une
        procédure de financement sera déclenchée et fera l'objet d'un suivi Qualiopi qui vous sera attribué.
      </p>
      <p className="text-sm font-bold text-red-500">
        Votre élève connaît son solde : n'hésitez pas à lui demander si le coût de la session est couvert par son solde restant ou si une
        demande de financement sera nécessaire.
      </p>

      <p className="text-sm font-bold text-red-500">
        En cas de question, vous pouvez contacter :{" "}
        <a href="mailto:formations@lesateliersoo.fr" className="font-medium text-blue-600 hover:underline dark:text-blue-500">
          formations@lesateliersoo.fr
        </a>
        .
      </p>
    </div>
  );
};
