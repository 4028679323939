import { StudentGateway } from "@user-management-context/shared/application/ports/student-gateway";
import config from "../../../../../config/backend";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { UpdateStudentBody } from "@user-management-context/write/domain/types/student/student";
import { Student, StudentSummaryVM } from "@user-management-context/read/domain/types/student/student";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TeacherVM } from "../../../../read/domain/types/student/teacher";
import { ProviderVM } from "@user-management-context/read/domain/types/student/provider";

export class BackendStudentGateway extends HttpGateway implements StudentGateway {
  private _oldRoute: string = "student/v1";
  #route: string = "v1/student";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async profile(): Promise<Student> {
    const { data: student } = await this._instance.get(`${this._oldRoute}/profile`);
    return student;
  }

  async update(body: UpdateStudentBody): Promise<string> {
    const { data: studentId } = await this._instance.put(`${this._oldRoute}/profile`, body);
    return studentId;
  }

  async summary(): Promise<StudentSummaryVM> {
    const { data: summary } = await this._instance.get(`${this._oldRoute}/summary`);
    return summary;
  }

  async teacherList(): Promise<TeacherVM[]> {
    const { data: teachers } = await this._instance.get(`${this._oldRoute}/teachers`);
    return teachers;
  }

  async providerList(): Promise<ProviderVM[]> {
    const { data: providers } = await this._instance.get(`${this.#route}/providers`);
    return providers;
  }
}
