import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";

const selectProviderBatchEnrollmentConfirmation = (state: AppState) => state.providerBatchEnrollmentConfirmation;
const selectProviderBatchParticipationInvoiceSubmission = (state: AppState) => state.providerBatchParticipationInvoiceSubmission;
const selectEnrollmentContact = (state: AppState) => state.studentEnrollmentContact;

interface Props {
  providerId: string;
}

export const useAdminProviderBatchUpdated = ({ providerId }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing: confirmationProcessing } = useAppSelector(selectProviderBatchEnrollmentConfirmation);
  const { processing: invoiceProcessing } = useAppSelector(selectProviderBatchParticipationInvoiceSubmission);
  const { processing: enrollmentContactProcessing } = useAppSelector(selectEnrollmentContact);
  useEffect(() => {
    if (confirmationProcessing === "success" || invoiceProcessing === "success" || enrollmentContactProcessing === "success") {
      toast({ description: "Dossier élève mis à jour !" });
      dispatch(adminRetrieveProviderBatches(providerId));
    } else if (confirmationProcessing === "failed" || invoiceProcessing === "failed") {
      toast({ description: "Erreur lors de la mise à jour du dossier élève", variant: "destructive" });
    }
    // We don't want to react on providerId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationProcessing, enrollmentContactProcessing, dispatch, invoiceProcessing, toast]);
};
