import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../../config/backend";
import { RefundRequestPort } from "@academy-context/shared/application/ports/refund-request-port";
import { RefundRequestBody } from "@academy-context/write/domain/types/admin/refund-request";
import { Refund } from "@academy-context/read/domain/types/admin/refunds";
import { UpdateRefundBody } from "@academy-context/write/domain/types/admin/refunds";

export class BackendRefundRequestGateway extends HttpGateway implements RefundRequestPort {
  private _route: string = "refund-requests/v1";
  private _refundsRoute: string = "refunds/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async request(refund: RefundRequestBody): Promise<void> {
    await this._instance.post(this._route, refund);
  }

  async uploadInvoice(file: File): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("invoice", file);

    const res = await this._instance.post(`${this._route}/invoices`, formDataBody);
    return res.data;
  }

  async list(): Promise<Refund[]> {
    const { data: refunds } = await this._instance.get(this._refundsRoute);
    return refunds;
  }

  async update(id: string, refund: UpdateRefundBody): Promise<void> {
    await this._instance.put(`${this._refundsRoute}/${id}`, refund);
  }
}
