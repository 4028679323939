import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { RefuseProviderBatchParticipationPaymentBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export const refuseProviderPayment = createAsyncThunk<void, RefuseProviderBatchParticipationPaymentBody, { extra: Partial<Dependencies> }>(
  "providerPayments/refuseProviderPayment",
  async (data: RefuseProviderBatchParticipationPaymentBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.refusePayment(data);
  }
);

export const resetRefuseProviderPayment = createAction("providerPayments/resetRefuseProviderPayment");
