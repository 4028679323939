import { createReducer } from "@reduxjs/toolkit";
import { ProviderBatchParticipationInvoiceUploadState } from "@redux/app-state";
import {
  resetUploadProviderBatchParticipationInvoice,
  uploadProviderBatchParticipationInvoice,
} from "./upload-provider-batch-participation-invoice";

const initialState: ProviderBatchParticipationInvoiceUploadState = { processing: "idle", providerBatchParticipationInvoiceUrl: null };

export const uploadProviderBatchParticipationInvoiceReducer = createReducer<ProviderBatchParticipationInvoiceUploadState>(
  initialState,
  builder => {
    builder.addCase(uploadProviderBatchParticipationInvoice.fulfilled, (state, { payload }) => {
      return {
        ...state,
        providerBatchParticipationInvoiceUrl: payload,
        processing: "success",
      };
    });
    builder.addCase(uploadProviderBatchParticipationInvoice.pending, state => {
      return {
        ...state,
        processing: "pending",
      };
    });
    builder.addCase(uploadProviderBatchParticipationInvoice.rejected, state => {
      return {
        ...state,
        processing: "failed",
      };
    });
    builder.addCase(resetUploadProviderBatchParticipationInvoice, () => {
      return initialState;
    });
  }
);
