import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveTrainingObjectives } from "../../../read/application/use-cases/admin/training-objectives-retrieval/retrieve-training-objectives";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { ObjectiveCard } from "../../shared/training-objective-form/objective-card.components";
import { selectTrainingObjectivesRetrievalForAdmin } from "../../../read/application/use-cases/admin/training-objectives-retrieval/selectors/training-objectives-selectors";
import { useObjectiveUpdate } from "../../shared/training/use-objective-update";

export const AdminTrainingObjectives = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { objectives } = useAppSelector(selectTrainingObjectivesRetrievalForAdmin);

  useEffect(() => {
    if (id) dispatch(adminRetrieveTrainingObjectives({ trainingId: id }));
  }, [dispatch, id]);

  useObjectiveUpdate({ trainingId: id!, role: "admin" });

  if (!id) return <></>;

  return (
    <div className="mb-5 flex justify-center">
      <div className="w-[90%]">
        <BackButton link={`/trainings/${id}`} label="Projet Pédagogique" />
        <div className="mt-5 space-y-5">
          {objectives.map(o => (
            <div key={o.id} className="space-y-2">
              <ObjectiveCard
                key={`${o.id}-1`}
                title={`${o.title} - Elève`}
                description={o.description}
                done={o.doneByStudent}
                trainingFollowUpId={id}
                objectiveId={o.id}
                role={"student"}
              />
              <ObjectiveCard
                key={`${o.id}-2`}
                title={`${o.title} - Professeur`}
                description={o.description}
                done={o.doneByTeacher}
                trainingFollowUpId={id}
                objectiveId={o.id}
                role={"teacher"}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
