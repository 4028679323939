import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { DownloadableElement } from "../training/downloadable-element";
import { LinkTrainingElement } from "../training/link-element";
import { TrainingDetailsHeader } from "../training/header";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ACTION_URGENCY_LEVEL, EducationalAdvisorQualiopiTrainingFollowUpVM } from "@academy-context/read/domain/types/training";

interface Props {
  trainingFollowUp: Nullable<EducationalAdvisorQualiopiTrainingFollowUpVM>;
}

export const QualiopiTrainingFollowUpDetailComponent = ({ trainingFollowUp }: Props) => {
  if (!trainingFollowUp) return <></>;

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton link="/trainings" label="Suivi QUALIOPI de vos élèves" />
        <TrainingDetailsHeader title={`${trainingFollowUp.title} | ${trainingFollowUp.student}`} date={trainingFollowUp.date} />
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Emargements</h2>
            <span className="ml-3 leading-8">(cliquer sur la zone pour ouvrir la page et le module d’émargement)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Emargements"
            status={trainingFollowUp.attendanceSheetsStatus}
            link={`/trainings/${trainingFollowUp.id}/sessions`}
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Projet pédagogique</h2>
            <span className="ml-3 leading-8">(Apparait lorsque la convention est finie ou 3 mois après la date de fin du stage)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Projet pédagogique"
            status={trainingFollowUp.objectivesStatus}
            link={
              [ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH, ACTION_URGENCY_LEVEL.DONE].includes(trainingFollowUp.objectivesStatus)
                ? `/trainings/${trainingFollowUp.id}/objectives`
                : undefined
            }
          />
        </div>
        {trainingFollowUp.convention.signed ? (
          <div className="mt-5">
            <div className="flex items-baseline">
              <h2 className="text-2xl font-semibold text-theme">Documents à télécharger</h2>
            </div>
            <Separator className="mt-2" />
            <DownloadableElement title="Convention signée" url={trainingFollowUp.convention.url} />
          </div>
        ) : (
          <div className="mt-5">
            <div className="flex items-baseline">
              <h2 className="text-2xl font-semibold text-theme">
                Document(s) à signer et à retourner à cette adresse mail :{" "}
                <a href="mailto:gestion.operaoff@gmail.com" target="_blank" rel="noreferrer" className="text-theme">
                  gestion.operaoff@gmail.com
                </a>
              </h2>
            </div>
            <Separator className="mt-2" />
            <DownloadableElement title="Convention à signer" url={trainingFollowUp.convention.url} action={true} />
          </div>
        )}
      </div>
    </div>
  );
};
