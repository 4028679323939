import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Teacher } from "@user-management-context/read/domain/types/admin/teacher";
import { AdminCreateTeacherBody, AdminUpdateTeacherBody } from "@user-management-context/write/domain/types/admin/teacher";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData, formatTeacherDataToFormData } from "./form-validation/teacher";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { PhoneInput } from "../../../../user-management-context/primary/shared/phone-input/phone-input";
import { MAX_HOURLY_PRICE, TEACHER_MIN_HOURLY_PRICE } from "@academy-context/write/domain/constants/shared";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save, X } from "lucide-react";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { isEmailUsed } from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { Textarea } from "@components/ui/textarea";

interface Props {
  onSubmit: (body: AdminCreateTeacherBody | AdminUpdateTeacherBody) => void;
  teacher?: Teacher;
}

export const TeacherForm = ({ teacher, onSubmit }: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { doesExist } = useAppSelector((state: AppState) => state.userEmailExistence);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(shouldBlockNavigation);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {
    reset,
    formState: { isDirty },
    setValue,
    setError,
    clearErrors,
    watch,
  } = form;

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher]);

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  const email = watch("email");

  // This part is used to check if user account is already registered
  useEffect(() => {
    if (email) dispatch(isEmailUsed(email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    const condition = teacher ? teacher.email !== email : email;
    if (doesExist && condition) {
      setError("email", { message: "Cet email est déjà utilisé", type: "custom" });
      setDisableSubmit(true);
    } else {
      clearErrors("email");
      setDisableSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, doesExist]);

  const resetForm = () => {
    if (teacher) reset(formatTeacherDataToFormData(teacher));
  };

  const handleOnSubmit = async (formBody: FormInputs) => {
    // Should not block navigation on creation otherwise modal opens up since we redirect user to newly created student
    if (!teacher) setShouldBlockNavigation(false);
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const phone = watch("mobile");

  return (
    <div className="space-y-5">
      <BackButton link="/teachers" label="Retour à la liste" />
      <CustomCard title="Informations">
        <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-2 gap-4">
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Prénom</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nom de famille</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} type="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="siret"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>SIRET</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="iban"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>IBAN (zone Euro)</FormLabel>
                    <FormControl>
                      <Input placeholder={`Exemple : FR76 4061 8803 0000 0408 3645 139`} {...field} type="text" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="activityRegistrationNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Numéro de déclaration d'activité</FormLabel>
                    <FormControl>
                      <Input {...field} type="text" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="mobile"
                render={() => (
                  <FormItem>
                    <FormLabel>Téléphone</FormLabel>
                    <FormControl>
                      <PhoneInput
                        phone={phone}
                        onChange={value => {
                          setValue("mobile", value as string, { shouldDirty: true });
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="defaultPrice"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tarif horaire standard (€/heure)</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="1"
                        min={TEACHER_MIN_HOURLY_PRICE}
                        max={MAX_HOURLY_PRICE}
                        {...field}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Adresse complète du lieu des cours</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="additionalAddress"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ville et code postal du lieu des cours</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="adminNotes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Commentaire</FormLabel>
                    <FormControl>
                      <Textarea maxLength={240} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="col-span-4 mt-4 flex justify-end">
              <Button onClick={resetForm} disabled={!isDirty} variant="outline" type="button">
                <X className="mr-2 size-4" />
                Annuler
              </Button>
              <Button type="submit" disabled={!isDirty || disableSubmit} className="ml-2">
                <Save className="mr-2 size-4" />
                Sauvegarder
              </Button>
            </div>
          </form>
        </Form>
      </CustomCard>
    </div>
  );
};
