import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateProviderTrainingBody } from "@user-management-context/shared/application/ports/provider-repository";

export const createProviderTraining = createAsyncThunk<string, CreateProviderTrainingBody, { extra: Partial<Dependencies> }>(
  "providerTrainings/createProviderTraining",
  async (training: CreateProviderTrainingBody, { extra: { providerRepository } }) => {
    return providerRepository!.createTraining(training);
  }
);

export const resetCreateProviderTraining = createAction("providerTrainings/resetCreateProviderTraining");
