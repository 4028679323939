import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderBatchParticipationPayment } from "@academy-context/read/domain/types/admin/provider-batch-participation-payment";

export const retrieveProviderBatchParticipationPaymentList = createAsyncThunk<
  ProviderBatchParticipationPayment[],
  void,
  { extra: Partial<Dependencies> }
>("providers/retrieveProviderBatchParticipationPaymentList", async (_, { extra: { adminProviderRepository } }) => {
  return adminProviderRepository!.payments();
});

export const resetRetrieveProviderBatchParticipationPaymentList = createAction(
  "providers/resetRetrieveProviderBatchParticipationPaymentList"
);
