import { ProviderTrainingListContainer } from "@academy-context/primary/provider/provider-trainings/list/provider-training-list.container";
import { Page } from "@shared-kernel/primary/shared/page";

export const ProviderTrainingsPage = () => {
  return (
    <Page
      title="Formations"
      subtitle="Les informations de cette section sont utilisées pour générer les devis qualitatifs et quantitatifs qui seront envoyés à l’AFDAS."
    >
      <ProviderTrainingListContainer />;
    </Page>
  );
};
