import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderStudentEnrollmentToBatchBody } from "@user-management-context/shared/application/ports/provider-repository";

export const enrollStudentToProviderBatch = createAsyncThunk<void, ProviderStudentEnrollmentToBatchBody, { extra: Partial<Dependencies> }>(
  "providers/enrollStudentToProviderBatch",
  async (enrollment: ProviderStudentEnrollmentToBatchBody, { extra: { providerRepository } }) => {
    return providerRepository!.enrollStudentToBatch(enrollment);
  }
);

export const resetInviteStudentToBatch = createAction("providers/resetEnrollStudentToProviderBatch");
