import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";

const selectAdminAccountCreate = (state: AppState) => state.createAdminAccount;

export const useAdminAccountCreation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fetching } = useAppSelector(selectAdminAccountCreate);
  const { toast } = useToast();

  useEffect(() => {
    if (fetching === "success") {
      navigate(`/login`);
      toast({ description: "Votre compte a été crée!" });
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la création de votre compte", variant: "destructive" });
    }
  }, [navigate, fetching, dispatch, toast]);
};
