import { AttendanceSheetForm } from "./attendance-sheet-form";
import { EducationalAdvisorQualiopiTrainingFollowUpVM, TrainingSession } from "@academy-context/read/domain/types/training";
import { Nullable } from "@shared-kernel/core/types/nullable";

interface Props {
  trainingFollowUpId?: string;
  isAdmin?: boolean;
  trainingSessions: TrainingSession[];
  trainingFollowUp: Nullable<EducationalAdvisorQualiopiTrainingFollowUpVM>;
}

export const TeacherAttendanceSheetForm = ({
  trainingFollowUp,
  trainingSessions,
  isAdmin = false,
  trainingFollowUpId: trainingId,
}: Props) => {
  if (trainingSessions.length === 0 || !trainingFollowUp || !trainingId) return <></>;

  const formattedAttendanceSheets = trainingSessions.map(s => ({
    id: s.id,
    scheduledDate: new Date(s.scheduledDate),
    signatureUrl: s.teacherSignatureUrl,
    number: s.number,
  }));

  return (
    <AttendanceSheetForm
      role="teacher"
      title={`${trainingFollowUp.title} | ${trainingFollowUp.student} | ${trainingFollowUp.externalFundingRequestId}`}
      conventionDate={new Date(trainingFollowUp.date)}
      attendanceSheets={formattedAttendanceSheets}
      trainingFollowUpId={trainingFollowUp.id}
      isAdmin={isAdmin}
    />
  );
};
