import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatFormDataToBodyData, formatTeacherDataToFormData, FormInputs, schema, defaultValues } from "./form-validation/teacher";
import { Teacher } from "@user-management-context/read/domain/types/admin/teacher";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { PhoneInput } from "../../shared/phone-input/phone-input";
import { MAX_HOURLY_PRICE, TEACHER_MIN_HOURLY_PRICE } from "../../../../academy-context/write/domain/constants/shared";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save, X } from "lucide-react";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { UpdateTeacherBody } from "@user-management-context/write/domain/types/teacher/teacher";

interface Props {
  onSubmit: (body: UpdateTeacherBody) => void;
  teacher: Nullable<Teacher>;
}

export const TeacherUserForm = ({ teacher, onSubmit }: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = form;

  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher]);

  const resetForm = () => {
    if (teacher) reset(formatTeacherDataToFormData(teacher));
  };

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const phone = watch("mobile");

  return (
    <CustomCard title="Informations">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-2 gap-4">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nom de famille</FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} disabled type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobile"
              render={() => (
                <FormItem>
                  <FormLabel>Téléphone</FormLabel>
                  <FormControl>
                    <PhoneInput
                      phone={phone}
                      onChange={value => {
                        setValue("mobile", value as string, { shouldDirty: true });
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="defaultPrice"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tarif horaire standard (€/heure)</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      step="1"
                      min={TEACHER_MIN_HOURLY_PRICE}
                      max={MAX_HOURLY_PRICE}
                      {...field}
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="siret"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SIRET (si applicable)</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IBAN (zone Euro)</FormLabel>
                  <FormControl>
                    <Input placeholder={`Exemple : FR76 4061 8803 0000 0408 3645 139`} {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="activityRegistrationNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Numéro de déclaration d'activité</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Adresse complète du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="additionalAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Ville et code postal du lieu des cours</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-2 flex justify-end">
            <Button onClick={resetForm} disabled={!isDirty} variant="outline" type="button">
              <X className="mr-2 size-4" />
              Annuler
            </Button>
            <Button type="submit" disabled={!isDirty} className="ml-2">
              <Save className="mr-2 size-4" />
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
