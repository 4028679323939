import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatFormDataToBodyData, FormInputs, schema, defaultValues } from "./form-validation/student";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { UpdateStudentBody } from "@user-management-context/write/domain/types/student/student";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { formatStudentDataToFormData } from "./form-validation/student";
import { PhoneInput } from "../../shared/phone-input/phone-input";
import { ERROR_MESSAGE, IMAGE_TYPE, isValidImageFile } from "../../../../academy-context/write/domain/constants/shared";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import {
  resetUploadProfilePicture,
  uploadProfilePicture,
} from "../../../write/application/use-cases/student/profile-picture-upload/upload-profile-picture";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save, X } from "lucide-react";
import ImageUploadButton from "@shared-kernel/primary/shared/upload-button/image-upload-button";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

interface Props {
  onSubmit: (body: UpdateStudentBody) => void;
  student: Nullable<Student>;
}

export const StudentUserForm = ({ student, onSubmit }: Props) => {
  const dispatch = useAppDispatch();
  const methods = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { profilePictureUrl, processing } = useAppSelector((state: AppState) => state.profilePictureUpload);

  const {
    reset,
    formState: { isDirty },
    watch,
    setValue,
    clearErrors,
    setError,
  } = methods;

  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useEffect(() => {
    return () => {
      dispatch(resetUploadProfilePicture());
    };
  }, [dispatch]);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    if (profilePictureUrl) {
      setValue("profilePictureUrl", profilePictureUrl, { shouldDirty: true });
    }
  }, [profilePictureUrl, setValue]);

  const resetForm = () => {
    if (student) reset(formatStudentDataToFormData(student));
    dispatch(resetUploadProfilePicture());
  };

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const handleProfilePictureChange = (picture: File) => {
    clearErrors("profilePictureUrl");
    dispatch(uploadProfilePicture({ picture }));
  };

  const handleProfilePictureError = () => {
    setError("profilePictureUrl", { type: "manual", message: ERROR_MESSAGE });
  };

  const phone = watch("mobile");
  const pictureFromForm = watch("profilePictureUrl");
  const profilePicture = profilePictureUrl || pictureFromForm;

  return (
    <CustomCard title="Informations">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...methods}>
        <form onSubmit={methods.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-3 gap-4 space-y-8">
          <div>
            <FormField
              control={methods.control}
              name="profilePictureUrl"
              render={() => (
                <FormItem className="mt-2">
                  <ImageUploadButton
                    processing={processing}
                    label="Photo de profil"
                    onChange={handleProfilePictureChange}
                    onError={handleProfilePictureError}
                    types={IMAGE_TYPE}
                    value={profilePicture || null}
                    validatorFunc={isValidImageFile}
                  >
                    <ul className="ml-4 mt-2 list-disc text-sm">
                      <li>140*100px minimum</li>
                      <li>10mo max</li>
                      <li>JPG ou PNG</li>
                    </ul>
                  </ImageUploadButton>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="h-20">
            <FormField
              control={methods.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-4">
              <FormField
                control={methods.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nom de famille</FormLabel>
                    <FormControl>
                      <Input {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div>
            <FormField
              control={methods.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} disabled type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-4">
              <FormField
                control={methods.control}
                name="mobile"
                render={() => (
                  <FormItem>
                    <FormLabel>Téléphone</FormLabel>
                    <FormControl>
                      <PhoneInput
                        phone={phone}
                        onChange={value => {
                          setValue("mobile", value as string, { shouldDirty: true });
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="col-span-full flex justify-end">
            <Button onClick={resetForm} disabled={!isDirty} variant="outline" type="button">
              <X className="mr-2 size-4" />
              Annuler
            </Button>
            <Button type="submit" disabled={!isDirty} className="ml-2">
              <Save className="mr-2 size-4" />
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
