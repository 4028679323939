import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delay: number | null, options?: { runOnInit: boolean }) {
  const savedCallback = useRef<() => void>();
  const runOnInit = options?.runOnInit || false;

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      if (runOnInit) {
        tick();
      }

      const id = setInterval(tick, delay);

      return () => {
        clearInterval(id);
      };
    }
    return () => {};
  }, [callback, delay, runOnInit]);
}
