import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateUserBody } from "@user-management-context/write/domain/types/user";
import { User } from "@user-management-context/read/domain/types/user";
import { formatFormDataToBodyData, formatUserDataToFormData, FormInputs, schema, defaultValues } from "./form-validation/user-update";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save, X } from "lucide-react";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";

interface Props {
  onSubmit: (body: UpdateUserBody) => void;
  user: User | null;
}

export const UserForm = ({ user, onSubmit }: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  const resetForm = () => {
    if (user) reset(formatUserDataToFormData(user));
  };

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  return (
    <CustomCard title="Informations">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-2 gap-4">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nom de famille</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-2">
            <Label>Email</Label>
            <Input value={user ? user.email : ""} disabled type="email" />
          </div>

          <div className="col-span-2 flex justify-end">
            <Button onClick={resetForm} disabled={!isDirty} variant="outline" type="button">
              <X className="mr-2 size-4" />
              Annuler
            </Button>
            <Button type="submit" disabled={!isDirty} className="ml-2">
              <Save className="mr-2 size-4" />
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
