import clsx from "clsx";
import styles from "./picture.module.scss";

interface Props {
  img?: string | null;
  className?: string;
}

export const Picture = ({ img, className }: Props) => (
  <div className={clsx(styles["picture"], !img && styles["pictureEmpty"], className)}>{img && <img src={img} alt="profil" />}</div>
);
