import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import {
  studentRetrieveProviderBatches,
  resetStudentRetrieveProviderBatches,
} from "@academy-context/read/application/use-cases/student/provider-batch-list-retrieval/retrieve-provider-batch-list";
import { StudentProviderBatchItem } from "@academy-context/read/domain/types/student/provider-batch";

const initialState: RetrievalState<StudentProviderBatchItem[]> = { data: [], fetching: "idle" };

export const studentRetrieveProviderBatchesReducer = createReducer<RetrievalState<StudentProviderBatchItem[]>>(initialState, builder => {
  builder.addCase(studentRetrieveProviderBatches.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveProviderBatches.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveProviderBatches.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetStudentRetrieveProviderBatches, () => {
    return initialState;
  });
});
