import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { formatDateToLocale, formatToCurrency } from "../../../../../../utils/formatting";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "../../../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { Eye } from "lucide-react";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";
import { Refund } from "@academy-context/read/domain/types/admin/refunds";
import { UpdateRefundModal } from "@academy-context/primary/admin/shared/components/transfer/update-refund-modal";
import { InvoiceListModal } from "@academy-context/primary/admin/shared/components/transfer/invoice-list-modal";

const columns: ColumnDef<Refund>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.creationDate),
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "paymentLabel",
    header: () => "Libellé",
    meta: {
      title: "Libellé",
      width: "90px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire élève",
    meta: {
      title: "Commentaire élève",
    },
  },
  {
    accessorKey: "iban",
    header: () => "RIB",
    meta: {
      title: "RIB",
      width: "90px",
    },
  },
  {
    accessorKey: "totalAmount",
    header: () => "Montant à verser",
    cell: ({ row }) => formatToCurrency(row.original.totalAmount),
    meta: {
      title: "Montant à verser",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "amountPaid",
    header: () => "Montant versé",
    cell: ({ row }) => (row.original.amountPaid != null ? formatToCurrency(row.original.amountPaid) : ""),
    meta: {
      title: "Montant versé",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "adminCommentary",
    header: () => "Commentaire admin",
    meta: {
      title: "Commentaire admin",
    },
  },
  {
    accessorKey: "paymentDate",
    header: () => "Date du virement",
    cell: ({ row }) => {
      const value = row.original.paymentDate;
      return value ? formatDateToLocale(value) : null;
    },
    meta: {
      title: "Date du virement",
      width: "90px",
    },
    enableSorting: true,
  },
];

interface Props {
  refunds: Refund[];
}

export const RefundsTable = ({ refunds }: Props) => {
  const [dataColumns, setDataColumns] = useState<ColumnDef<Refund>[]>([...columns]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const currentSelectedLineInfo = useRef<Refund | null>(null);
  const currentSelectedLineIsChecked = useRef<boolean>(false);
  const [isInvoiceListModalOpen, setIsInvoiceListModalOpen] = useState<boolean>(false);
  const selectedRefundId = useRef<string | null>(null);

  useEffect(() => {
    const statusColumn: ColumnDef<Refund> = {
      accessorKey: "status",
      header: () => "Virement effectué",
      meta: {
        title: "Virement effectué",
        width: "80px",
      },
      enableSorting: true,
      cell: ({ row }) => {
        const refund = row.original;
        const isPaid = row.original.status === PaymentStatus.PAID;
        return (
          <Checkbox
            id={refund.id}
            defaultChecked={isPaid}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              setIsModalOpen(true);
              currentSelectedLineInfo.current = refund;
              currentSelectedLineIsChecked.current = isPaid;
            }}
          />
        );
      },
    };

    const invoiceListPreviewColumn: ColumnDef<Refund> = {
      accessorKey: "id",
      header: () => "Détail",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="flex justify-center">
          <Eye
            className="cursor-pointer px-1 py-0"
            onClick={() => {
              selectedRefundId.current = row.original.id;
              setIsInvoiceListModalOpen(true);
            }}
          />
        </div>
      ),
      meta: {
        width: "50px",
      },
    };
    setDataColumns([...columns, statusColumn, invoiceListPreviewColumn]);
  }, [refunds]);

  const refund = refunds.find(r => r.id === selectedRefundId.current);
  let invoices: Refund["invoices"] = [];
  if (refund) {
    invoices = refund.invoices;
  }

  return (
    <CustomCard title="Remboursements de frais">
      {currentSelectedLineInfo.current && (
        <UpdateRefundModal
          refund={currentSelectedLineInfo.current}
          isPaid={currentSelectedLineIsChecked.current}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <InvoiceListModal invoices={invoices} isOpen={isInvoiceListModalOpen} onClose={() => setIsInvoiceListModalOpen(false)} />
      <DataTable
        columns={dataColumns}
        data={refunds}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher un remboursement"
        pageSize={50}
        displayDataViewOptions
      />
    </CustomCard>
  );
};
