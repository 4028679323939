import { Outlet } from "react-router-dom";
import bgImg from "../../../assets/img/background/login.jpg";
import logo from "../../../assets/img/logo/transparent_logo.png";

const LoginOutlet = () => {
  return (
    <div className="container relative grid h-screen w-screen flex-col items-center justify-center bg-slate-900 sm:max-w-none lg:grid-cols-6 lg:px-0">
      <div className="relative col-span-4 hidden h-screen flex-col bg-muted dark:border-r lg:flex">
        <img className="size-full object-cover" src={bgImg} alt="Authentication" />
      </div>
      <div className="flex flex-col items-center space-y-6 text-white lg:col-span-2">
        <div className="flex w-[250px] justify-center">
          <img src={logo} alt="logo" width="250" />
        </div>
        <div className="px-4 lg:w-[350px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LoginOutlet;
