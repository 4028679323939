import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { Control, Controller } from "react-hook-form";
import CreatableReactSelect from "react-select/creatable";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  formName: string;
  options: ComboboxOptions[];
  styleOptions?: { menu: { width: string } };
  allowCreate?: boolean;
}

export const CreatableSelect = ({ control, formName, options, styleOptions, allowCreate = true }: Props) => {
  const menuStyle = { ...styleOptions?.menu };

  return (
    <Controller
      name={formName}
      control={control}
      render={({ field }) => {
        const { value, ...rest } = field;
        const val = value?.value ? value : null;
        return (
          <CreatableReactSelect
            {...rest}
            isClearable
            isSearchable={true}
            options={options}
            value={val}
            placeholder="Sélectionner..."
            formatCreateLabel={input => `Ajouter ${input}`}
            styles={{ menu: base => ({ ...base, ...menuStyle }) }}
            isValidNewOption={() => allowCreate}
          />
        );
      }}
    />
  );
};
