export const userId = "user-id";
export const conventionId = "convention-id";
export const studentId = "student-id";
export const teacherId = "teacher-id";
export const trainingId = "training-id";
export const fundingRequestId = "funding-request-id";
export const budgetId = "budget-id";
export const invitationId = "invitation-id";
export const providerId = "provider-id";
export const providerBatchParticipationId = "provider-batch-participation-id";
export const providerBatchEnrollmentId = "provider-batch-enrollment-id";
export const providerBatchId = "provider-batch-id";
