import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateProviderBody } from "@user-management-context/write/domain/types/admin/provider";
import { useProviderCreate } from "./use-provider-create";
import { ProviderCreateComponent } from "@user-management-context/primary/admin/provider-detail/provider-create.component";
import { createProvider } from "@user-management-context/write/application/use-cases/admin/provider-creation/create-provider";
import { AppState } from "@redux/app-state";
import { isEmailUsed } from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";

export const ProviderCreateContainer = () => {
  const dispatch = useAppDispatch();
  const { doesExist } = useAppSelector((state: AppState) => state.userEmailExistence);

  const saveProvider = async (provider: CreateProviderBody) => {
    await dispatch(createProvider(provider));
  };

  const checkIfEmailUsed = useCallback(
    (email: string) => {
      dispatch(isEmailUsed(email));
    },
    [dispatch]
  );

  useProviderCreate();

  return (
    <>
      <BackButton link="/providers" label="Retour à la liste" />
      <CustomCard title="Informations">
        <div className="w-full space-y-5">
          <ProviderCreateComponent saveProvider={saveProvider} doesEmailExist={doesExist} checkIfEmailUsed={checkIfEmailUsed} />
        </div>
      </CustomCard>
    </>
  );
};
