import { useEffect } from "react";
import { UpdateUserBody } from "@user-management-context/write/domain/types/user";
import { retrieveUserProfile } from "../../../read/application/use-cases/user-profile/retrieve-user-profile";
import { updateUser } from "../../../write/application/use-cases/user-update/update-user";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useUserUpdate } from "./use-user-update";
import { UserForm } from "./user-form.component";

export const UserDetail = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useAppSelector((state: AppState) => state.userProfileRetrieval);

  useEffect(() => {
    dispatch(retrieveUserProfile());
  }, [dispatch]);

  const saveUser = async (body: UpdateUserBody) => {
    await dispatch(updateUser(body));
  };

  useUserUpdate();

  return <UserForm user={user} onSubmit={saveUser} />;
};
