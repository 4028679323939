import { useAppDispatch } from "@redux/hooks";
import { RefundRequestCreate } from "./refund-request-create.component";
import { requestRefund } from "@academy-context/write/application/use-cases/student/refund-request/request-refund";
import { RefundRequestBody } from "@academy-context/write/domain/types/admin/refund-request";

export const RefundRequestDetail = () => {
  const dispatch = useAppDispatch();

  const saveExpense = async (refundRequest: RefundRequestBody) => {
    await dispatch(requestRefund(refundRequest));
  };

  return (
    <div className="space-y-5">
      <RefundRequestCreate onSubmit={saveExpense} />
    </div>
  );
};
