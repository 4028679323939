import { createReducer } from "@reduxjs/toolkit";
import { providerRetrieveQualiopiTrainingFollowUp } from "./retrieve-qualiopi-training-follow-up";
import { EducationalAdvisorQualiopiTrainingFollowUpVM } from "@academy-context/read/domain/types/training";
import { RetrievalState } from "@redux/app-state";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<EducationalAdvisorQualiopiTrainingFollowUpVM>> = { fetching: "idle", data: null };

export const providerRetrieveQualiopiTrainingFollowUpReducer = createReducer<
  RetrievalState<Nullable<EducationalAdvisorQualiopiTrainingFollowUpVM>>
>(initialState, builder => {
  builder.addCase(providerRetrieveQualiopiTrainingFollowUp.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(providerRetrieveQualiopiTrainingFollowUp.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(providerRetrieveQualiopiTrainingFollowUp.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
