import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";
import { StandardPayment, StandardPaymentSummaryVM } from "@academy-context/read/domain/types/admin/payment";

export const transfersSummary: StandardPaymentSummaryVM = {
  current: {
    data: [
      {
        id: "4",
        from: "2022-03-29T00:00:00.000Z",
        to: "2022-03-30T00:00:00.000Z",
        teacher: "Gérard Darmon",
        amount: 90,
        amountPaid: null,
        status: PaymentStatus.PENDING,
        paymentDate: null,
        reference: null,
      },
    ],
    interval: {
      start: "",
      end: "",
    },
  },
  previous: {
    data: [
      {
        id: "3",
        from: "2021-01-29T00:00:00.000Z",
        to: "2021-01-30T00:00:00.000Z",
        teacher: "Clélia Timsit",
        amount: 60.8,
        amountPaid: null,
        status: PaymentStatus.PENDING,
        reference: null,
        paymentDate: null,
      },
      {
        id: "1",
        from: "2022-01-29T00:00:00.000Z",
        to: "2022-01-30T00:00:00.000Z",
        teacher: "Clélia Timsit",
        amount: 50.8,
        amountPaid: null,
        status: PaymentStatus.PENDING,
        reference: null,
        paymentDate: null,
      },
      {
        id: "2",
        from: "2022-01-29T00:00:00.000Z",
        to: "2022-01-30T00:00:00.000Z",
        teacher: "Gérard Darmon",
        amount: 50.8,
        amountPaid: 50.8,
        status: PaymentStatus.PAID,
        reference: "OperaOff_mai_2022_reymond",
        paymentDate: "2022-01-30T00:00:00.000Z",
      },
    ],
  },
};

export const transfers: StandardPayment[] = [
  {
    id: "1",
    from: "2022-01-29T00:00:00.000Z",
    to: "2022-01-30T00:00:00.000Z",
    teacher: "Clélia Timsit",
    amount: 50.8,
    amountPaid: null,
    status: PaymentStatus.PENDING,
    reference: null,
    paymentDate: null,
  },
  {
    id: "2",
    from: "2022-01-29T00:00:00.000Z",
    to: "2022-01-30T00:00:00.000Z",
    teacher: "Gérard Darmon",
    amount: 50.8,
    amountPaid: 50.8,
    status: PaymentStatus.PAID,
    reference: "OperaOff_mai_2022_reymond",
    paymentDate: "2022-01-30T00:00:00.000Z",
  },
  {
    id: "3",
    from: "2021-01-29T00:00:00.000Z",
    to: "2021-01-30T00:00:00.000Z",
    teacher: "Clélia Timsit",
    amount: 60.8,
    amountPaid: null,
    status: PaymentStatus.PENDING,
    reference: null,
    paymentDate: null,
  },
  {
    id: "4",
    from: "2022-03-29T00:00:00.000Z",
    to: "2022-03-30T00:00:00.000Z",
    teacher: "Gérard Darmon",
    amount: 90,
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    reference: null,
  },
  {
    id: "5",
    from: "2022-04-30T23:59:59.999Z",
    to: "2022-04-30T23:59:59.999Z",
    teacher: "Gérard Darmon",
    amount: 90,
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    reference: null,
  },
  {
    id: "6",
    from: "2022-05-01T00:00:00.000Z",
    to: "2022-05-30T00:00:00.000Z",
    amount: 90,
    teacher: "Gérard Darmon",
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    reference: null,
  },
  {
    id: "7",
    from: "2022-06-30T23:59:59.999Z",
    to: "2022-06-30T23:59:59.999Z",
    amount: 90,
    teacher: "Gérard Darmon",
    amountPaid: null,
    status: PaymentStatus.PENDING,
    paymentDate: null,
    reference: null,
  },
  {
    id: "8",
    from: "2022-07-01T00:00:00.000Z",
    to: "2022-07-30T00:00:00.000Z",
    amount: 90,
    amountPaid: null,
    teacher: "Gérard Darmon",
    status: PaymentStatus.PENDING,
    paymentDate: null,
    reference: null,
  },
];
