import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminUpdateTeacherBody } from "@user-management-context/write/domain/types/admin/teacher";

export const updateTeacher = createAsyncThunk<string, { id: string; body: AdminUpdateTeacherBody }, { extra: Partial<Dependencies> }>(
  "teachers/updateTeacher",
  async ({ id, body }: { id: string; body: AdminUpdateTeacherBody }, { extra: { adminTeacherGateway } }) => {
    return adminTeacherGateway!.update(id, body);
  }
);

export const resetUpdateTeacher = createAction("teachers/resetUpdateTeacher");
