import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../../config/backend";
import { TeacherConventionListVM, TeacherOldConventionListVM } from "@academy-context/read/domain/types/teacher/convention";
import { TeacherConventionPort } from "@academy-context/shared/application/ports/teacher-convention-port";

export class BackendTeacherConventionGateway extends HttpGateway implements TeacherConventionPort {
  private _route: string = "teacher/v1/conventions";
  private _routeV2: string = "teacher/v2/conventions";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<TeacherConventionListVM[]> {
    const { data: conventions } = await this._instance.get(this._routeV2);
    return conventions;
  }

  async oldConventions(): Promise<TeacherOldConventionListVM[]> {
    const { data: conventions } = await this._instance.get(this._route);
    return conventions;
  }
}
