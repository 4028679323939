import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import { getNormalizedString } from "../../../../utils/utils";
import { useAppSelector } from "@redux/hooks";
import {
  selectTeachersRetrievalForModal,
  TeacherExtended,
} from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/selectors/teachers-modal-list-selectors";
import { Dialog, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { ScrollableDialogContent } from "@components/ui/scrollable-dialog";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";

const generateCheckboxInput = (id: string, isChecked: boolean, onClick: (checked: boolean | "indeterminate") => void, label: string) => (
  <div className="flex items-center space-x-2" key={id}>
    <Checkbox id={id} defaultChecked={isChecked} onCheckedChange={onClick} />
    <label htmlFor={id} className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
      {label}
    </label>
  </div>
);

interface Props {
  onSubmit: (teachers: string[]) => void;
  onClose: () => void;
  isOpen: boolean;
  favoriteTeachers: string[];
}

const SelectTeachersModal = ({ isOpen, onClose, onSubmit, favoriteTeachers }: Props) => {
  const [selectedTeachersById, setSelectedTeachersById] = useState<Record<string, boolean>>({});
  const [validatedSelectedTeachersById, setValidatedSelectedTeachersById] = useState<Record<string, boolean>>({});
  const [filterStringValue, setFilterStringValue] = useState<string>("");
  const filterString = useRef<string>("");
  const { data: teachers } = useAppSelector(selectTeachersRetrievalForModal);
  const [teacherOptions, setTeacherOptions] = useState<TeacherExtended[]>([]);

  useEffect(() => {
    const selectedTeachersById: Record<string, boolean> = {};
    teachers.forEach(t => {
      const isFavoriteTeacher = favoriteTeachers.includes(t.teacherId);
      let value = false;
      if (isFavoriteTeacher) value = true;
      selectedTeachersById[t.teacherId] = value;
    });
    setSelectedTeachersById(selectedTeachersById);
    setValidatedSelectedTeachersById(selectedTeachersById);
  }, [teachers, favoriteTeachers]);

  useEffect(() => {
    const filteredOptions = teachers.filter(teacher => teacher.filterString.match(filterString.current));
    setTeacherOptions(filteredOptions);
  }, [filterStringValue, teachers]);

  const teacherCheckboxes: JSX.Element[] = useMemo(() => {
    return teacherOptions.map(teacher => {
      const isChecked = selectedTeachersById[teacher.teacherId] ?? false;
      const onClick = (change: boolean | "indeterminate") => {
        setSelectedTeachersById({ ...selectedTeachersById, [teacher.teacherId]: Boolean(change) });
      };
      const label = `${teacher.name} ${teacher.lastName}`;
      return generateCheckboxInput(teacher.teacherId, isChecked, onClick, label);
    });
  }, [teacherOptions, selectedTeachersById]);

  const resetFilterValue = () => {
    setFilterStringValue("");
    filterString.current = "";
  };

  const onCancel = () => {
    onClose();
    setSelectedTeachersById(validatedSelectedTeachersById);
    resetFilterValue();
  };

  const onSearch = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setFilterStringValue(value);
    filterString.current = getNormalizedString(value);
  };

  const handleSubmit = () => {
    const selectedTeachers: string[] = [];
    Object.entries(selectedTeachersById).forEach(([id, isChecked]) => {
      if (isChecked) selectedTeachers.push(id);
    });
    setValidatedSelectedTeachersById(selectedTeachersById);
    onSubmit(selectedTeachers);
    resetFilterValue();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onCancel}>
      <ScrollableDialogContent onPointerDownOutside={onCancel}>
        <DialogHeader>
          <DialogTitle>Choisir les professeurs de l'élève</DialogTitle>
        </DialogHeader>
        <Input type="text" placeholder="Rechercher un professeur" className="mb-4" onChange={onSearch} value={filterStringValue} />
        <div className="grid grid-cols-4 gap-4">{teacherCheckboxes}</div>
        <DialogFooter>
          <Button onClick={onCancel} variant="outline" type="button">
            Annuler
          </Button>
          <Button type="submit" onClick={handleSubmit}>
            Valider
          </Button>
        </DialogFooter>
      </ScrollableDialogContent>
    </Dialog>
  );
};

export default SelectTeachersModal;
