import { Link, matchPath, useLocation } from "react-router-dom";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@components/ui/sidebar";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@components/ui/collapsible";
import {
  ChevronRight,
  BadgeEuro,
  BookOpen,
  Briefcase,
  FileSignature,
  FolderCheck,
  FolderDown,
  GraduationCap,
  HandCoins,
  Hexagon,
  Landmark,
  LayoutList,
  Network,
  Plane,
  Send,
  UserPlus,
} from "lucide-react";
import { TRAININGS_ROUTE_PATH } from "src/routes";
import { ReactElement } from "react";

interface AdminNavListItem {
  title: string;
  items: {
    title: string;
    url: string;
    icon: ReactElement;
  }[];
}

const adminNavList: AdminNavListItem[] = [
  {
    title: "Demandes de financement",
    items: [
      {
        title: "À traiter",
        url: "funding-requests",
        icon: <FolderDown />,
      },
      {
        title: "Traitées",
        url: "to-submit-funding-requests",
        icon: <FolderCheck />,
      },
    ],
  },
  {
    title: "Qualité",
    items: [
      {
        title: "Cours",
        url: "lessons",
        icon: <BookOpen />,
      },
      {
        title: "Conventions",
        url: "conventions",
        icon: <FileSignature />,
      },
      {
        title: "Anciennes conv.",
        url: "old-conventions/list",
        icon: <FileSignature />,
      },
      {
        title: "Sessions presta.",
        url: "provider-batches",
        icon: <Network />,
      },
      {
        title: "Suivi qualité",
        url: TRAININGS_ROUTE_PATH,
        icon: <Hexagon />,
      },
      {
        title: "Formations",
        url: "provider-trainings",
        icon: <LayoutList />,
      },
    ],
  },
  {
    title: "Comptabilité",
    items: [
      {
        title: "Stages",
        url: "expenses",
        icon: <Plane />,
      },
      {
        title: "Budgets",
        url: "budgets",
        icon: <BadgeEuro />,
      },
      {
        title: "Paiements à effectuer",
        url: "payments",
        icon: <HandCoins />,
      },
      {
        title: "Historique des paiements",
        url: "transfers/list",
        icon: <Landmark />,
      },
      {
        title: "Paiements des anciennes conv.",
        url: "convention-transfers",
        icon: <Landmark />,
      },
    ],
  },
  {
    title: "Utilisateurs",
    items: [
      {
        title: "Invitations",
        url: "invitations",
        icon: <Send />,
      },
      {
        title: "Demandes d'ajout d'élève",
        url: "collaboration-requests",
        icon: <UserPlus />,
      },
      {
        title: "Elèves",
        url: "students",
        icon: <GraduationCap />,
      },
      {
        title: "Professeurs",
        url: "teachers",
        icon: <Briefcase />,
      },
      {
        title: "Prestataires",
        url: "providers",
        icon: <Network />,
      },
    ],
  },
];

export const AdminSideBar = () => {
  const location = useLocation();
  const { state } = useSidebar();

  const collapsibleProps =
    state === "collapsed"
      ? {
          disable: true,
          open: true,
        }
      : {};

  return (
    <>
      {adminNavList.map(route => (
        <Collapsible key={route.title} title={route.title} defaultOpen className="group/collapsible" {...collapsibleProps}>
          <SidebarGroup>
            <SidebarGroupLabel
              asChild
              className="group/label bg-slate-100 text-sm text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground dark:bg-inherit"
            >
              <CollapsibleTrigger>
                {route.title} <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
              </CollapsibleTrigger>
            </SidebarGroupLabel>
            <CollapsibleContent>
              <SidebarGroupContent>
                <SidebarMenu>
                  {route.items.map(item => {
                    // Check if the current path matches the item URL, including subpaths
                    const isActive = Boolean(matchPath(`/${item.url}/*`, location.pathname));

                    return (
                      <SidebarMenuItem key={item.title}>
                        <SidebarMenuButton asChild isActive={isActive} tooltip={item.title} hidden={false}>
                          <Link to={item.url}>
                            {item.icon} <span>{item.title}</span>
                          </Link>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    );
                  })}
                </SidebarMenu>
              </SidebarGroupContent>
            </CollapsibleContent>
          </SidebarGroup>
        </Collapsible>
      ))}
    </>
  );
};
