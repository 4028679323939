import { useEffect } from "react";
import { retrieveTrainingSessions } from "../../../read/application/use-cases/shared/training-sessions-retrieval/retrieve-training-sessions";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { teacherRetrieveTraining } from "../../../read/application/use-cases/teacher/training-retrieval/retrieve-training";
import { TeacherAttendanceSheetForm } from "@academy-context/primary/shared/attendance-sheet-signature-form/teacher-attendance-sheet-form";

interface Props {
  isAdmin?: boolean;
}

export const TeacherQualiopiTrainingAttendanceSheetListContainer = ({ isAdmin }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingSessions } = useAppSelector((state: AppState) => state.trainingSessionsRetrieval);
  const { data: trainingFollowUp } = useAppSelector((state: AppState) => state.teacherTrainingRetrieval);

  useEffect(() => {
    if (id) dispatch(teacherRetrieveTraining(id));
    if (id) dispatch(retrieveTrainingSessions(id));
  }, [dispatch, id]);

  return (
    <TeacherAttendanceSheetForm
      trainingSessions={trainingSessions}
      trainingFollowUp={trainingFollowUp}
      trainingFollowUpId={id}
      isAdmin={isAdmin}
    />
  );
};
