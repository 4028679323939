import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../config/backend";
import { TrainingPort } from "../../application/ports/training-port";
import {
  AdminTrainingListVM,
  AdminTrainingVM,
  StudentTrainingVM,
  TrainingFollowUpListVM,
  EducationalAdvisorQualiopiTrainingFollowUpVM,
  TrainingObjective,
  TrainingSession,
} from "@academy-context/read/domain/types/training";
import { urlToBloB } from "../../../../utils/utils";
import { SignAttendanceSheetPayload } from "../../../write/application/use-cases/shared/attendance-sheet-signature/sign-attendance-sheet";
import { UpdateTrainingObjectivePayload } from "../../../write/application/use-cases/shared/training-objective-update/update-training-objective";

export class BackendTrainingGateway extends HttpGateway implements TrainingPort {
  private _route: string = "v1/trainings";
  private _oldRoute: string = "trainings/v1";
  private _studentRoute: string = "student/trainings/v1";
  private _teacherRoute: string = "teacher/trainings/v1";
  private _adminRoute: string = "admin/trainings/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async getTrainingSessions(trainingId: string): Promise<TrainingSession[]> {
    const { data: sessions } = await this._instance.get(`${this._oldRoute}/${trainingId}/sessions`);
    return sessions;
  }

  async getTrainingObjectives(trainingId: string, role: "student" | "teacher"): Promise<TrainingObjective[]> {
    const { data: objectives } = await this._instance.get(`${this._oldRoute}/${trainingId}/objectives?role=${role}`);
    return objectives;
  }

  async studentGetAll(): Promise<TrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._studentRoute}`);
    return trainings;
  }

  async teacherGetAll(): Promise<TrainingFollowUpListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._teacherRoute}`);
    return trainings;
  }

  async adminGetAll(): Promise<AdminTrainingListVM[]> {
    const { data: trainings } = await this._instance.get(`${this._adminRoute}`);
    return trainings;
  }

  async studentGetOne(id: string): Promise<StudentTrainingVM> {
    const { data: training } = await this._instance.get(`${this._studentRoute}/${id}`);
    return training;
  }

  async teacherGetOne(id: string): Promise<EducationalAdvisorQualiopiTrainingFollowUpVM> {
    const { data: training } = await this._instance.get(`${this._teacherRoute}/${id}`);
    return training;
  }

  async adminGetOne(id: string): Promise<AdminTrainingVM> {
    const { data: training } = await this._instance.get(`${this._adminRoute}/${id}`);
    return training;
  }

  async signAttendanceSheet({ trainingId, id, role, signatureUrl }: SignAttendanceSheetPayload): Promise<void> {
    const blob = await urlToBloB(signatureUrl);
    const formDataBody = new FormData();
    formDataBody.append("role", role);
    formDataBody.append("signature", blob);

    const res = await this._instance.put(`${this._oldRoute}/${trainingId}/sessions/${id}:sign`, formDataBody);
    return res.data;
  }

  async updateTrainingObjective({ trainingId, id, done, role }: UpdateTrainingObjectivePayload): Promise<void> {
    await this._instance.put(`${this._oldRoute}/${trainingId}/objectives/${id}`, { done, role });
  }

  async toggleTrainingDunningProcessStatus({ trainingId, action }: { trainingId: string; action: "pause" | "resume" }): Promise<void> {
    await this._instance.put(`${this._adminRoute}/${trainingId}:toggle-dunning-status`, { action });
  }

  async uploadSignedConvention(trainingId: string, file: File): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("convention", file);

    const res = await this._instance.post(`${this._route}/${trainingId}/conventions:sign`, formDataBody);
    return res.data;
  }

  async deleteSignedConvention(trainingId: string): Promise<void> {
    await this._instance.post(`${this._route}/${trainingId}/conventions:delete`);
  }
}
