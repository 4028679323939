import { Link, matchPath, useLocation } from "react-router-dom";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "@components/ui/sidebar";
import {
  BadgeEuro,
  BookOpen,
  FileSignature,
  FolderDown,
  HandCoins,
  Hexagon,
  Landmark,
  CircleGauge,
  LayoutList,
  TriangleAlert,
  Zap,
} from "lucide-react";
import { ReactElement, useEffect, useState } from "react";
import { REFUND_REQUEST_ROUTE_PATH, STUDENT_FUNDING_REQUEST_MENU_LABEL, TRAININGS_ROUTE_PATH } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { cn } from "@components/utils/utils";

interface NavListItem {
  title: string;
  url: string;
  icon: ReactElement;
}

const studentNavList: NavListItem[] = [
  {
    title: "Tableau de bord",
    url: "dashboard",
    icon: <CircleGauge />,
  },
  {
    title: STUDENT_FUNDING_REQUEST_MENU_LABEL,
    url: "funding-request",
    icon: <FolderDown />,
  },
  {
    title: "Suivi QUALIOPI",
    url: TRAININGS_ROUTE_PATH,
    icon: <Hexagon />,
  },
  {
    title: "Remboursement de frais",
    url: REFUND_REQUEST_ROUTE_PATH,
    icon: <HandCoins />,
  },
  {
    title: "Cours",
    url: `lessons`,
    icon: <BookOpen />,
  },
  {
    title: "Masterclass / Frais",
    url: `expenses`,
    icon: <Landmark />,
  },
  {
    title: "Budgets",
    url: `budgets`,
    icon: <BadgeEuro />,
  },
  {
    title: "Conventions",
    url: `conventions/list`,
    icon: <FileSignature />,
  },
];

const teacherNavList: NavListItem[] = [
  {
    title: "Tableau de bord",
    url: "dashboard",
    icon: <CircleGauge />,
  },
  {
    title: "Suivi QUALIOPI",
    url: TRAININGS_ROUTE_PATH,
    icon: <Hexagon />,
  },
  {
    title: "Cours",
    url: `lessons/list`,
    icon: <BookOpen />,
  },
  {
    title: "Conventions",
    url: `conventions/list`,
    icon: <FileSignature />,
  },
];

const providerNavList: NavListItem[] = [
  {
    title: "Tableau de bord",
    url: "dashboard",
    icon: <CircleGauge />,
  },
  {
    title: "Suivi QUALIOPI",
    url: TRAININGS_ROUTE_PATH,
    icon: <Hexagon />,
  },
  {
    title: "Formations",
    url: "provider-trainings",
    icon: <LayoutList />,
  },
];

interface Props {
  role: Nullable<ROLES>;
  student: Nullable<Student>;
  isActionAvailable: boolean;
}

export const StandardUserSideBar = ({ role, student, isActionAvailable }: Props) => {
  const location = useLocation();
  const [navList, setNavList] = useState<NavListItem[]>([]);
  const { data } = useAppSelector((state: AppState) => state.fundingRequestEligibilityRetrieval);

  useEffect(() => {
    let list: NavListItem[] = [];

    if (role === ROLES.STUDENT) {
      if (student && !student.canRequestARefund) {
        list = studentNavList.filter(r => r.url !== REFUND_REQUEST_ROUTE_PATH);
      } else {
        list = studentNavList;
      }
    } else if (role === ROLES.TEACHER) {
      list = teacherNavList;
    } else if (role === ROLES.PROVIDER) {
      list = providerNavList;
    }

    setNavList(list);
  }, [role, student]);

  return (
    <SidebarMenu>
      {navList.map(item => {
        // Check if the current path matches the item URL, including subpaths
        const isActive = Boolean(matchPath(`/${item.url}/*`, location.pathname));
        const isStudentEligibleForAFundingRequest = item.title === STUDENT_FUNDING_REQUEST_MENU_LABEL && data?.isEligibleForAFundingRequest;
        const isTrainingActionAvailable = item.url === TRAININGS_ROUTE_PATH && isActionAvailable;
        const displayDangerSign = isStudentEligibleForAFundingRequest || isTrainingActionAvailable;
        let icon = item.icon;
        if (isStudentEligibleForAFundingRequest) icon = <Zap fill="yellow" />;
        if (isTrainingActionAvailable) icon = <TriangleAlert fill="yellow" />;

        return (
          <SidebarMenuItem key={item.title}>
            <SidebarMenuButton
              asChild
              isActive={isActive}
              tooltip={item.title}
              hidden={false}
              className={cn("mx-auto", displayDangerSign && "bg-yellow-100")}
            >
              <Link to={item.url}>
                {icon} <span>{item.title}</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        );
      })}
    </SidebarMenu>
  );
};
