import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectExpensesRetrievalForList } from "../../../read/application/use-cases/admin/expenses-retrieval/selectors/expenses-list-selectors";
import { useNavigate } from "react-router-dom";
import { retrieveExpenses } from "../../../read/application/use-cases/admin/expenses-retrieval/retrieve-expenses";
import { AdminExpenseListVM } from "@academy-context/read/domain/types/admin/expense";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";

const columns: ColumnDef<AdminExpenseListVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
  },
  {
    accessorKey: "date",
    header: () => "Date",
    cell: info => formatDateToLocale(info.row.original.date),
    enableSorting: true,
    meta: {
      title: "Date",
      width: "90px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "type",
    header: () => "Type",
    enableSorting: true,
    meta: {
      title: "Type",
      width: "120px",
    },
  },
  {
    accessorKey: "price",
    header: () => "Prix",
    cell: info => formatToCurrency(info.row.original.price),
    enableSorting: true,
    meta: {
      title: "Prix",
      width: "80px",
    },
  },
  {
    accessorKey: "description",
    header: () => "Description",
    meta: {
      title: "Description",
    },
  },
];

export const ExpensesList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: expenses } = useAppSelector(selectExpensesRetrievalForList);

  useEffect(() => {
    dispatch(retrieveExpenses());
  }, [dispatch]);

  const onNewExpense = () => {
    navigate("/expenses");
  };

  return (
    <CustomCard title="Masterclass / frais" headerChildren={<Button onClick={onNewExpense}>Nouvelle masterclass / frais</Button>}>
      <DataTable
        columns={columns}
        data={expenses}
        searchPlaceHolder="Rechercher une masterclass / Frais"
        sortField="creationDate"
        order="desc"
      />
    </CustomCard>
  );
};
