import { Nullable } from "@shared-kernel/core/types/nullable";

export interface Student {
  id: string;
  name: string;
  lastName: string;
  email: string;
  mobile: Nullable<string>;
  favoriteTeachers: string[];
  blackListed: boolean;
  profilePictureUrl: string | null;
  canRequestARefundOnce: boolean;
}

export enum USER_STATUS {
  REGISTERED = "registered",
  NOT_REGISTERED = "not_registered",
  INVITATION_SENT = "invitation_sent",
  EXPIRED_INVITATION = "expired_invitation",
}

export interface StudentListElement {
  userId: string;
  name: string;
  lastName: string;
  userStatus: USER_STATUS;
  email: string;
  details: {
    studentId: string;
    balance: number;
    isBlacklisted: boolean;
    nextFundingDate: Nullable<string>;
    convention: Nullable<{
      teacherId: string;
      hourlyPrice: number;
      hoursLeft: number;
      isOldConvention: boolean;
    }>;
    isFundingRequestInProgress: boolean;
  };
}
