import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../../config/backend";
import { AdminBudgetListVM } from "@academy-context/read/domain/types/admin/budget";
import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { AdminBudgetPort } from "@academy-context/shared/application/ports/budget-port";

export class AdminBackendBudgetGateway extends HttpGateway implements AdminBudgetPort {
  private _route: string = "admin/budgets/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<AdminBudgetListVM[]> {
    const { data: budgets } = await this._instance.get(this._route);
    return budgets;
  }

  async lastBudgets(): Promise<AdminBudgetListVM[]> {
    const { data: budgets } = await this._instance.get(`${this._route}/last`);
    return budgets;
  }

  async create(budget: CreateBudgetBody): Promise<string> {
    const { data: budgetId } = await this._instance.post(this._route, budget);
    return budgetId;
  }
}
