import { object, string } from "yup";
import { InviteUserBody } from "@user-management-context/write/domain/types/admin/user-invitation";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { Nullable } from "@shared-kernel/core/types/nullable";

export type FormInputs = {
  email: Nullable<{ label: string; value: string }>;
  role: ROLES;
};

export const ROLES_MAP = {
  [ROLES.ADMIN]: "Administrateur",
  [ROLES.STUDENT]: "Elève",
  [ROLES.TEACHER]: "Professeur",
  [ROLES.PROVIDER]: "Prestataire",
};

export const defaultValues = {
  email: { label: "", value: "" },
  role: ROLES.STUDENT,
};

export const schema = object().shape({
  email: object().shape({
    label: string().required(REQUIRED_FIELD),
    value: string().email("Email incorrect").required(REQUIRED_FIELD),
  }),
  role: string().required(REQUIRED_FIELD).oneOf(Object.values(ROLES)),
});

export const formatFormDataToBodyData = (user: FormInputs): InviteUserBody => {
  return {
    email: user.email ? user.email.value.toLowerCase().trim() : "",
    role: user.role,
  };
};
