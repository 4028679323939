import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { EducationalAdvisorQualiopiTrainingFollowUpVM } from "../../../../domain/types/training";

export const providerRetrieveQualiopiTrainingFollowUp = createAsyncThunk<
  EducationalAdvisorQualiopiTrainingFollowUpVM,
  string,
  { extra: Partial<Dependencies> }
>("trainings/providerRetrieveQualiopiTrainingFollowUp", async (id: string, { extra: { providerQualiopiTrainingFollowUpRepository } }) => {
  return providerQualiopiTrainingFollowUpRepository!.byId(id);
});
