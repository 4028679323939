import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveProviderBatches } from "@academy-context/read/application/use-cases/provider/provider-batches-retrieval/retrieve-provider-batches";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

const initialState: RetrievalState<ProviderBatchItem[]> = { data: [], fetching: "idle" };

export const retrieveProviderBatchesReducer = createReducer<RetrievalState<ProviderBatchItem[]>>(initialState, builder => {
  builder.addCase(retrieveProviderBatches.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveProviderBatches.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveProviderBatches.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
