import { createReducer } from "@reduxjs/toolkit";
import { ProviderBatchParticipationInvoiceUploadState } from "@redux/app-state";
import {
  adminResetUploadProviderBatchParticipationInvoice,
  adminUploadProviderBatchParticipationInvoice,
} from "./upload-provider-batch-participation-invoice";

const initialState: ProviderBatchParticipationInvoiceUploadState = { processing: "idle", providerBatchParticipationInvoiceUrl: null };

export const adminUploadProviderBatchParticipationInvoiceReducer = createReducer<ProviderBatchParticipationInvoiceUploadState>(
  initialState,
  builder => {
    builder.addCase(adminUploadProviderBatchParticipationInvoice.fulfilled, (state, { payload }) => {
      return {
        ...state,
        providerBatchParticipationInvoiceUrl: payload,
        processing: "success",
      };
    });
    builder.addCase(adminUploadProviderBatchParticipationInvoice.pending, state => {
      return {
        ...state,
        processing: "pending",
      };
    });
    builder.addCase(adminUploadProviderBatchParticipationInvoice.rejected, state => {
      return {
        ...state,
        processing: "failed",
      };
    });
    builder.addCase(adminResetUploadProviderBatchParticipationInvoice, () => {
      return initialState;
    });
  }
);
