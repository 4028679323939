import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";
import { formatDateToLocale } from "@utils/formatting";
import {
  PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD,
  PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS,
} from "@academy-context/read/domain/types/admin/provider-batch-participation-payment";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { DateInterval } from "@shared-kernel/core/types/date-interval";

export type ProviderBatchParticipationPaymentRow = {
  id: string;
  status: PROVIDER_BATCH_PARTICIPATION_PAYMENT_STATUS;
  invoice: { url: string; number: string };
  creationDate: string;
  adminCommentary: Nullable<string>;
  student: string;
  label: string;
  provider: {
    tag: string;
    name: string;
    iban: string;
  };
  training: {
    tag: string;
    title: string;
  };
  batch: {
    interval: DateInterval;
  };
  payment: {
    amount: number;
    amountPaid: Nullable<number>;
    date: Nullable<string>;
    method: Nullable<PROVIDER_BATCH_PARTICIPATION_PAYMENT_METHOD>;
    reference: Nullable<string>;
  };
};

export const selectProviderBatchParticipationPaymentList = createSelector(
  [(state: AppState) => state.providerBatchParticipationPaymentListRetrieval],
  providerBatchParticipationPaymentListRetrieval => {
    const { data, fetching } = providerBatchParticipationPaymentListRetrieval;

    const formattedData = [...data]
      .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())
      .map(payment => {
        return {
          ...payment,
          student: `${payment.student.name} ${payment.student.lastName}`,
          label: `${payment.training.tag} | ${formatDateToLocale(payment.batch.interval.start)} -> ${formatDateToLocale(
            payment.batch.interval.end
          )}`,
        };
      });

    return {
      payments: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
