import { Card, CardContent, CardHeader, CardTitle } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Progress } from "@shared-kernel/primary/shared/shadcn/ui/progress";

interface Props {
  convention: { student: string; hours: number; hoursDone: number };
}

export const ConventionCard = ({ convention }: Props) => {
  const { student, hours, hoursDone } = convention;

  return (
    <Card className="mt-2 w-full bg-theme text-white">
      <CardHeader className="border-b border-gray-400 p-2">
        <CardTitle className="text-sm text-yellow-400">Nouvelle convention: {student}</CardTitle>
      </CardHeader>
      <CardContent className="flex items-center px-2 py-3">
        <div className="flex w-full flex-col justify-center">
          <p className="mb-3 text-xs">Les heures des nouvelles conventions sont payées en fin de mois</p>
          <Progress value={(hoursDone / hours) * 100} />
        </div>
        <div className="ml-4 flex w-[60px] flex-col items-center justify-center pl-1">
          <span className="text-2xl">
            {hoursDone}/{hours}
          </span>
          <span className="text-center text-xs">heures prises</span>
        </div>
      </CardContent>
    </Card>
  );
};
