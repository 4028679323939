import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { retrieveTeacherProfile } from "../../../read/application/use-cases/teacher/teacher-profile-retrieval/retrieve-teacher";
import { resetUpdateTeacherUser } from "../../../write/application/use-cases/teacher/teacher-update/update-teacher";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

const selectTeacherUpdate = (state: AppState) => state.teacherUserUpdate;

export const useTeacherUserUpdate = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useAppSelector(selectTeacherUpdate);

  useEffect(() => {
    if (fetching === "success") {
      toast({ description: "Mise à jour effectuée avec succès!" });
      dispatch(resetUpdateTeacherUser());
      dispatch(retrieveTeacherProfile());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la mise à jour", variant: "destructive" });
    }
  }, [fetching, dispatch, toast]);
};
