import config from "../../../../../config/backend";
import { AdminLessonPort } from "@academy-context/shared/application/ports/admin-lesson-port";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { AdminLessonListVM } from "@academy-context/read/domain/types/admin/lesson";
import { CreateLessonBody } from "@academy-context/write/domain/types/admin/lesson";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";

export class AdminBackendLessonGateway extends HttpGateway implements AdminLessonPort {
  private _route: string = "admin/v1/lessons";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<AdminLessonListVM[]> {
    const { data: lessons } = await this._instance.get(this._route);
    return lessons;
  }

  async lastLessons(): Promise<AdminLessonListVM[]> {
    const { data: lessons } = await this._instance.get(`${this._route}/last`);
    return lessons;
  }

  async create(lesson: CreateLessonBody): Promise<string> {
    const { data: lessonId } = await this._instance.post(this._route, lesson);
    return lessonId;
  }
}
