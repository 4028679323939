import { ProviderBatchParticipationPaymentRow } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/selectors/provider-batch-participation-payment-list-selectors";
import { formatDateToLocale, formatToCurrency } from "@utils/formatting";
import { ProviderPaymentInformationHeader } from "@academy-context/primary/admin/shared/components/transfer/payment-information-header";
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogFooter } from "@components/ui/alert-dialog";

interface Props {
  payment: ProviderBatchParticipationPaymentRow;
  isOpen: boolean;
  onClose: () => void;
}

export const ProviderPaymentDetailModal = ({ payment: providerPayment, isOpen, onClose }: Props) => {
  const { payment, adminCommentary } = providerPayment;

  const atLeastOneInformation = Boolean(payment.amountPaid || payment.date || payment.reference || adminCommentary);

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <ProviderPaymentInformationHeader payment={providerPayment} />
        {atLeastOneInformation && (
          <div className="flex flex-col text-sm text-muted-foreground">
            <span className="font-semibold">Informations paiement</span>
            {payment.amountPaid && (
              <div className="flex justify-between">
                <span>Montant réglé</span>
                <span>{formatToCurrency(payment.amountPaid)}</span>
              </div>
            )}
            {payment.date && (
              <div className="flex justify-between">
                <span>Date de règlement réglé</span>
                <span>{formatDateToLocale(payment.date)}</span>
              </div>
            )}
            {payment.reference && (
              <div className="flex justify-between">
                <span>Identification du virement</span>
                <span>{payment.reference}</span>
              </div>
            )}
            {adminCommentary && (
              <div className="grid grid-cols-5">
                <div className="col-span-2">Commentaire</div>
                <div className="col-span-3">{adminCommentary}</div>
              </div>
            )}
          </div>
        )}
        <AlertDialogFooter className="mt-4">
          <AlertDialogCancel onClick={onClose} type="button">
            Fermer
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
