import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateProviderBatchBody } from "@user-management-context/shared/application/ports/provider-repository";

export const createProviderBatch = createAsyncThunk<void, CreateProviderBatchBody, { extra: Partial<Dependencies> }>(
  "providerBatches/createProviderBatch",
  async (batch: CreateProviderBatchBody, { extra: { providerRepository } }) => {
    return providerRepository!.createBatch(batch);
  }
);

export const resetCreateProviderBatch = createAction("providerBatches/resetCreateProviderBatch");
