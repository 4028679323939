import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveStudentSummary } from "../../../read/application/use-cases/student/summary-retrieval/retrieve-student-summary";
import { selectStudentSummaryRetrieval } from "../../../read/application/use-cases/student/summary-retrieval/selectors/student-summary-selectors";
import { StudentSummary } from "./student-summary.components";

export const StudentSummaryContainer = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectStudentSummaryRetrieval);

  useEffect(() => {
    dispatch(retrieveStudentSummary());
  }, [dispatch]);

  return <>{data && <StudentSummary summary={data} lastActiveConvention={data.lastActiveConvention} />}</>;
};
