import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { providerRetrieveQualiopiTrainingFollowUp } from "@academy-context/read/application/use-cases/provider/qualiopi-training-follow-up-retrieval/retrieve-qualiopi-training-follow-up";
import { QualiopiTrainingFollowUpDetailComponent } from "@academy-context/primary/shared/qualiopi-training-follow-up/qualiopi-training-follow-up-detail.components";

export const ProviderQualiopiTrainingFollowUpDetailContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingFollowUp } = useAppSelector((state: AppState) => state.providerQualiopiTrainingFollowUpRetrieval);

  useEffect(() => {
    if (id) dispatch(providerRetrieveQualiopiTrainingFollowUp(id));
  }, [dispatch, id]);

  return <QualiopiTrainingFollowUpDetailComponent trainingFollowUp={trainingFollowUp} />;
};
