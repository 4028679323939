import clsx from "clsx";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./rich-text.module.scss";

interface Props {
  value: string;
  placeholder?: string;
  onChange: (value: { html: string; text: string }) => void;
  onReset: (value: string) => void;
  hasError?: boolean;
}
const RichText = ({ value, placeholder, onChange, onReset, hasError }: Props) => (
  <ReactQuill
    className={clsx(styles["richText"], hasError && styles["richTextHasError"])}
    theme="snow"
    placeholder={placeholder}
    value={value}
    onChange={(_v, _delta, source, editor) => {
      // ReactQuill sometimes needs to modify the value given as parameter to fit its format.
      // This is why when source is API we reset the value with what ReactQuill gives us so that we start with a proper form state.
      if (source === "api") onReset!(editor.getHTML());
      if (source === "user") onChange({ html: editor.getHTML(), text: editor.getText() });
    }}
  />
);

export default RichText;
