import { useFieldArray, useFormContext } from "react-hook-form";
import { Objective } from "./objective";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { PlusCircle } from "lucide-react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";

type FormInputs = {
  personalizedEducationalProject: { title: { label: string; value: string }; description: string; descriptionText: string }[];
};

export const PersonalizedEducationalProject = () => {
  const { control } = useFormContext<FormInputs>();
  const { append, remove } = useFieldArray({
    control,
    name: "personalizedEducationalProject",
  });

  return (
    <FormField
      control={control}
      name="personalizedEducationalProject"
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>Projet pédagogique personnalisé pour l'AFDAS</FormLabel>
            <FormControl className="w-[100px]">
              <>
                {field.value.map((_, index) => (
                  <Objective key={index} index={index} onRemove={() => remove(index)} />
                ))}
              </>
            </FormControl>
            <FormMessage />
            <div>
              <Button
                onClick={() => append({ title: { label: "", value: "" }, description: "", descriptionText: "" })}
                className="mt-2"
                type="button"
              >
                <PlusCircle className="mr-3" />
                Ajouter un nouvel objectif
              </Button>
            </div>
          </FormItem>
        );
      }}
    />
  );
};
