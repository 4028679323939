import { number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { SubmitBatchParticipationInvoiceBody } from "@user-management-context/shared/application/ports/provider-repository";
import { MIN_BATCH_PRICE } from "@academy-context/primary/provider/provider-batches/form-validation/batch-enrollment-confirmation/batch-enrollment-confirmation";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

export type BatchParticipationInvoiceFormInputs = {
  invoiceNumber: string;
  invoiceUrl: string;
  price: number;
};

export const defaultBatchParticipationInvoiceValues = {
  invoiceNumber: "",
  invoiceUrl: "",
  price: 0,
};

export const batchParticipationInvoiceSchema = object().shape({
  invoiceNumber: string().max(255).required(REQUIRED_FIELD),
  invoiceUrl: string().url("URL invalide").max(255).required(REQUIRED_FIELD),
  price: number().min(MIN_BATCH_PRICE, `minimum ${MIN_BATCH_PRICE}€`).typeError("Une valeur est nécessaire").required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (
  invoice: BatchParticipationInvoiceFormInputs & { participationId: string }
): SubmitBatchParticipationInvoiceBody => {
  const { invoiceNumber, invoiceUrl, price, participationId } = invoice;

  return {
    invoiceUrl,
    invoiceNumber,
    participationId,
    price,
  };
};

export const formatParticipationDataToFormData = (
  enrollment: ProviderBatchItem["participations"][number]
): BatchParticipationInvoiceFormInputs => {
  const { price } = enrollment;

  return {
    price,
    invoiceUrl: defaultBatchParticipationInvoiceValues.invoiceUrl,
    invoiceNumber: defaultBatchParticipationInvoiceValues.invoiceNumber,
  };
};
