import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentProviderEnrollment } from "@academy-context/read/domain/types/student/provider-enrollment";
import { Nullable } from "@shared-kernel/core/types/nullable";

export const studentRetrieveEligibleProviderEnrollment = createAsyncThunk<
  Nullable<StudentProviderEnrollment>,
  void,
  { extra: Partial<Dependencies> }
>("providers/studentRetrieveEligibleProviderEnrollment", async (_, { extra: { studentProviderRepository } }) => {
  return studentProviderRepository!.getEligibleEnrollment();
});

export const resetStudentRetrieveEligibleProviderEnrollment = createAction("providers/resetStudentRetrieveEligibleProviderEnrollment");
