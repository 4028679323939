import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveCollaborationRequestList } from "@academy-context/read/application/use-cases/admin/collaboration-request-list-retrieval/retrieve-collaboration-request-list";
import { Avatar, AvatarFallback, AvatarImage } from "@shared-kernel/primary/shared/shadcn/ui/avatar";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { approveCollaborationRequest } from "@academy-context/write/application/use-cases/admin/collaboration-request-approval/approve-collaboration-request";
import { useCollaborationRequestApproval } from "@academy-context/primary/admin/collaboration-request-list/use-collaboration-request-approval";
import { selectCollaborationRequestsRetrievalForList } from "@academy-context/read/application/use-cases/admin/collaboration-request-list-retrieval/selectors/collaboration-request-list-selectors";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { formatNumberToLocale } from "@utils/formatting";
import { useModal } from "@hooks/useModal";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { CollaborationRequest } from "@academy-context/read/domain/types/admin/collaboration-request";

export const CollaborationRequests = () => {
  const dispatch = useAppDispatch();
  const { data, fetching } = useAppSelector(selectCollaborationRequestsRetrievalForList);
  const { closeModal, isModalOpen, openModal } = useModal();
  const [selectedCollaborationRequest, setSelectedCollaborationRequest] = useState<Nullable<CollaborationRequest>>(null);

  useEffect(() => {
    dispatch(retrieveCollaborationRequestList());
  }, [dispatch]);

  const onSubmit = (id: string, status: "approved" | "rejected") => {
    dispatch(approveCollaborationRequest({ id, status }));
  };

  useCollaborationRequestApproval();

  return (
    <CustomCard title="Demande d'ajout d'élève">
      <div className="grid w-full grid-cols-2">
        <AlertModal
          isOpen={isModalOpen}
          onClose={() => closeModal()}
          onSubmit={() => {
            closeModal();
            if (selectedCollaborationRequest) onSubmit(selectedCollaborationRequest.id, "rejected");
          }}
          title="Refuser la demande d'ajout de cet élève"
          body={`Êtes-vous sûr de vouloir refuser la demande d'ajout de cet élève ?`}
          submitText="Refuser"
        />
        {data.map(c => (
          <div className="flex justify-center" key={c.id}>
            <Card key={c.id} className="mb-3 grid w-[400px] grid-cols-3 items-center bg-white px-2">
              <CardContent className="col-span-2 flex flex-col justify-center space-y-2 p-0">
                <div className="flex items-center space-x-2 pt-2">
                  <Avatar>
                    <AvatarImage src={undefined} />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                  <span className="text-sm">{c.teacher.fullName}</span>
                </div>
                <div className="flex items-center space-x-2 pb-2">
                  <Avatar>
                    <AvatarImage src={c.student.picture ?? undefined} />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                  <div className="flex items-center pr-2">
                    <span className="text-sm">{c.student.fullName}</span>
                    <span className="mx-2 text-sm">|</span>
                    <span className="text-sm font-bold">{formatNumberToLocale(c.student.balance, 2)}€</span>
                  </div>
                </div>
              </CardContent>
              <CardContent className="col-span-1 flex flex-col items-center gap-1 p-0">
                <Button
                  className="w-full bg-green-500 text-white hover:bg-green-400 hover:text-white"
                  onClick={() => {
                    onSubmit(c.id, "approved");
                  }}
                >
                  Approuver
                </Button>
                <Button
                  variant="destructive"
                  className="w-full"
                  onClick={() => {
                    setSelectedCollaborationRequest(c);
                    openModal();
                  }}
                >
                  Refuser
                </Button>
              </CardContent>
            </Card>
          </div>
        ))}
        {fetching === "success" && !data.length && <div>Pas de demande d'ajout d'élève</div>}
      </div>
    </CustomCard>
  );
};
