import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { adminRetrieveTraining } from "../../../read/application/use-cases/admin/training-retrieval/retrieve-training";
import { DownloadableElement } from "../../shared/training/downloadable-element";
import { LinkTrainingElement } from "../../shared/training/link-element";
import { TrainingDetailsHeader } from "../../shared/training/header";
import { AppState } from "@redux/app-state";
import {
  resetUploadSignedConvention,
  uploadSignedConvention,
} from "../../../write/application/use-cases/admin/signed-convention-upload/upload-signed-convention";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { CONVENTION_FILETYPE, isValidConventionFile } from "@academy-context/write/domain/constants/shared";
import InputError from "@shared-kernel/primary/shared/input-error";
import { deleteSignedConvention } from "../../../write/application/use-cases/admin/signed-convention-deletion/delete-signed-convention";
import { Trash } from "lucide-react";
import DocumentUploadButton from "@shared-kernel/primary/shared/upload-button/document-upload-button";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { ACTION_URGENCY_LEVEL } from "@academy-context/read/domain/types/training";

export const AdminTrainingDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: training } = useAppSelector((state: AppState) => state.adminTrainingRetrieval);
  const { conventionUrl, processing } = useAppSelector((state: AppState) => state.signedConventionUpload);
  const [error, setError] = useState<Nullable<string>>(null);

  useEffect(() => {
    return () => {
      dispatch(resetUploadSignedConvention());
    };
  }, [dispatch]);

  const handleConventionUrlChange = (convention: File) => {
    if (training) dispatch(uploadSignedConvention({ id: training.id, convention }));
  };

  const handleConventionUrlError = () => {
    setError("Veuillez uploader un fichier de 10 Mo max");
  };

  const handleConventionUrlDelete = () => {
    if (training) dispatch(deleteSignedConvention(training.id));
  };

  useEffect(() => {
    if (id) dispatch(adminRetrieveTraining(id));
  }, [dispatch, id]);

  if (!training) return <></>;

  const signedConvention = conventionUrl ?? training.teacher.convention.signedUrl;
  const label = training.teacher.convention.signedUrl ? "Remplacer la convention signée (PDF)" : "Uploader la convention signée (PDF)";

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton link="/trainings" label="Suivi de formation" />
        <TrainingDetailsHeader title={`${training.title} | ${training.teacher.name} | ${training.student.name}`} date={training.date} />
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Emargements</h2>
            <span className="ml-3 leading-8">(cliquer sur la zone pour ouvrir la page et le module d’émargement)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Emargements - Elève"
            status={training.student.attendanceSheetsStatus}
            link={`/trainings/${id}/sessions/student`}
          />
          <LinkTrainingElement
            title="Emargements - Professeur"
            status={training.teacher.attendanceSheetsStatus}
            link={`/trainings/${id}/sessions/teacher`}
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Evaluations</h2>
            <span className="ml-3 leading-8">
              (à remplir au cours de votre formation, les questionnaires seront accessibles au fur et à mesure votre formation...)
            </span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Questionnaire PRE-FORMATION"
            status={training.student.preTrainingSurveyStatus}
            link={
              [ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH].includes(training.student.preTrainingSurveyStatus)
                ? `/trainings/${id}/pre-training-survey`
                : undefined
            }
          />
          <LinkTrainingElement
            title="Questionnaire A FROID"
            status={training.student.postTrainingSurveyStatus}
            subTitle="(2-3 mois après la fin de votre formation)"
            link={
              [ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH].includes(training.student.postTrainingSurveyStatus)
                ? `/trainings/${id}/post-training-survey`
                : undefined
            }
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Projet Pédagogique</h2>
            <span className="ml-3 leading-8">(Apparait lorsque la convention est finie ou 3 mois après la date de fin du stage)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Projet Pédagogique"
            status={training.student.objectivesStatus}
            link={
              [ACTION_URGENCY_LEVEL.LOW, ACTION_URGENCY_LEVEL.HIGH, ACTION_URGENCY_LEVEL.DONE].includes(training.student.objectivesStatus)
                ? `/trainings/${id}/objectives`
                : undefined
            }
          />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Documents à télécharger</h2>
          </div>
          <Separator className="mt-2" />
          <DownloadableElement title="Convocation" url={training.student.trainingInvitationUrl} />
          <DownloadableElement title="Attestation d'assiduité" url={training.student.attendanceCertificateUrl} />
          <DownloadableElement title="Certificat de réalisation de l'action de formation" url={training.student.completionCertificateUrl} />
          <div className="flex items-center justify-center">
            <div className="flex w-[750px] items-center">
              <div className="flex-1">
                {signedConvention ? (
                  <DownloadableElement title="Convention" url={signedConvention} />
                ) : (
                  <DownloadableElement title="Convention à signer" url={training.teacher.convention.unsignedUrl} action={true} />
                )}
              </div>
              <div className="flex flex-1 items-center">
                <DocumentUploadButton
                  label={label}
                  defaultValue={signedConvention}
                  onChange={handleConventionUrlChange}
                  onError={handleConventionUrlError}
                  types={CONVENTION_FILETYPE}
                  validatorFunc={isValidConventionFile}
                  processing={processing}
                />
                {signedConvention && (
                  <Trash onClick={handleConventionUrlDelete} className="ml-3 cursor-pointer text-red-500 hover:text-red-400" />
                )}
                <InputError error={error ?? undefined} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
