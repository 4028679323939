import { useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { StudentForm } from "./student-form.component";
import { createStudent } from "../../../../user-management-context/write/application/use-cases/admin/admin-student-creation/create-student";
import { AdminCreateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { useStudentCreate } from "./use-student-create";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";

export const StudentCreate = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(adminRetrieveTeachers());
  }, [dispatch]);

  const saveStudent = async (student: AdminCreateStudentBody) => {
    await dispatch(createStudent(student));
  };

  useStudentCreate();

  return <StudentForm onSubmit={saveStudent} />;
};
