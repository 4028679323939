import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../../config/backend";
import { AdminConventionListVM } from "@academy-context/read/domain/types/admin/convention";
import { CreateConventionBody } from "@academy-context/write/domain/types/admin/convention";
import { AdminConventionPort } from "@academy-context/shared/application/ports/admin-convention-port";

export class AdminBackendConventionGateway extends HttpGateway implements AdminConventionPort {
  private _route: string = "admin/v1/conventions";
  private _routeV2: string = "admin/v2/conventions";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async oldConventions(): Promise<AdminConventionListVM[]> {
    const { data: conventions } = await this._instance.get(this._route);
    return conventions;
  }

  async all(): Promise<AdminConventionListVM[]> {
    const { data: conventions } = await this._instance.get(this._routeV2);
    return conventions;
  }

  async create(convention: CreateConventionBody): Promise<string> {
    const { data: conventionId } = await this._instance.post(this._route, convention);
    return conventionId;
  }

  async cancel(id: string): Promise<void> {
    return this._instance.post(`${this._route}/${id}:cancel`);
  }

  async end(id: string): Promise<void> {
    return this._instance.post(`${this._route}/${id}:end`);
  }
}
