import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UpdateTeacherBody } from "@user-management-context/write/domain/types/teacher/teacher";

export const updateTeacherUser = createAsyncThunk<string, UpdateTeacherBody, { extra: Partial<Dependencies> }>(
  "teachers/updateTeacherUser",
  async (body: UpdateTeacherBody, { extra: { teacherGateway } }) => {
    return teacherGateway!.update(body);
  }
);

export const resetUpdateTeacherUser = createAction("teachers/resetUpdateTeacherUser");
