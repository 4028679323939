import { Page } from "@shared-kernel/primary/shared/page";
import { ProviderUserDetailContainer } from "@user-management-context/primary/provider/provider-user-detail/provider-user-detail.container";

export const ProviderProfilePage = () => {
  return (
    <Page title="Profil">
      <ProviderUserDetailContainer />
    </Page>
  );
};
