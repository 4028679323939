import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const uploadProviderBatchParticipationInvoice = createAsyncThunk<
  string,
  { providerBatchParticipationInvoice: File },
  { extra: Partial<Dependencies> }
>(
  "providerParticipations/uploadProviderBatchParticipationInvoice",
  async ({ providerBatchParticipationInvoice }: { providerBatchParticipationInvoice: File }, { extra: { providerRepository } }) => {
    return providerRepository!.uploadBatchParticipationInvoice(providerBatchParticipationInvoice);
  }
);

export const resetUploadProviderBatchParticipationInvoice = createAction(
  "providerParticipations/resetUploadProviderBatchParticipationInvoice"
);
