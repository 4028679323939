import { unparse } from "papaparse";

/**
 * The download function takes a CSV string, the filename and mimeType as parameters
 */
const download = (content: string, fileName: string, mimeType: string) => {
  const a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";
  const blob = new Blob(["\uFEFF" + content], {
    type: mimeType,
  });

  a.href = URL.createObjectURL(blob);
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * This function is used to extract a CSV.
 * It generates a CSV string from an array of entries and then creates the CSV file and downloads it.
 * @param data - Data of CSV file
 * @param fields - Columns name of CSV file
 * @param name - Name of CSV file
 */
export const extractCSV = (data: unknown[][], fields: string[], name: string) => {
  if (data && data.length > 0) {
    download(unparse({ fields, data }, { quotes: true }), name, "text/csv;charset:utf-8");
  }
};
