import config from "../../../../../config/backend";
import { TeacherGateway } from "@user-management-context/shared/application/ports/teacher-gateway";
import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { Teacher } from "@user-management-context/read/domain/types/admin/teacher";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import { UpdateTeacherBody } from "@user-management-context/write/domain/types/teacher/teacher";

export class BackendTeacherGateway extends HttpGateway implements TeacherGateway {
  private _route: string = "teachers/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async profile(): Promise<Teacher> {
    const { data: teacher } = await this._instance.get(`${this._route}/profile`);
    return teacher;
  }

  async update(body: UpdateTeacherBody): Promise<string> {
    const { data: teacherId } = await this._instance.put(`${this._route}/profile`, body);
    return teacherId;
  }
}
