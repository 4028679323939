import { useFormContext } from "react-hook-form";
import { Trash } from "lucide-react";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import {
  FormInputs,
  MAX_INVOICE_PRICE,
  MIN_INVOICE_PRICE,
} from "@academy-context/primary/student/refund-request-detail/form-validation/refund-request";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { InvoiceUpload } from "@academy-context/primary/student/refund-request-detail/invoice-upload";
import InputError from "@shared-kernel/primary/shared/input-error";

interface Props {
  index: number;
  invoice: { id: string; url: Nullable<string> };
  onAddInvoice: (url: string, uploadId: string) => void;
  onDeleteInvoice: (id: string) => void;
  onRemove: () => void;
}

export const Invoice = ({ index, invoice, onRemove, onAddInvoice, onDeleteInvoice }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const error = errors.invoices?.[index]?.url?.message;
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <Label>{`Facture ${index + 1}`}</Label>
        <Trash size={24} className="ml-2 cursor-pointer" fill="black" onClick={() => onRemove()} />
      </div>
      <div className="grid grid-cols-5 items-center space-x-2">
        <div className="col-span-4">
          <InvoiceUpload invoice={invoice} onInvoiceUpload={onAddInvoice} onDeleteInvoice={onDeleteInvoice} />
          <InputError error={error ?? undefined} />
        </div>
        <div className="col-span-1">
          <FormField
            control={control}
            key={invoice.id}
            name={`invoices.${index}.amount`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Montant</FormLabel>
                <FormControl>
                  <Input type="number" step="1" min={MIN_INVOICE_PRICE} max={MAX_INVOICE_PRICE} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};
