import { Skeleton } from "@shared-kernel/primary/shared/shadcn/ui/skeleton";

export const InvitationsListSkeleton = () => {
  return (
    <>
      <Skeleton className="mt-5 h-[40px]" />
      <Skeleton className="mt-2 h-[220.5px]" />
    </>
  );
};
