import { OldConventionCard } from "./old-convention-card";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { ScrollArea } from "@shared-kernel/primary/shared/shadcn/ui/scroll-area";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { Tooltip } from "@shared-kernel/primary/shared/tooltip/tooltip";
import { ConventionCard } from "./convention-card";

interface Props {
  oldConventions: { student: string; hours: number; hoursDone: number; paymentThreshold: number; paymentDate: Nullable<string> }[];
  conventions: { student: string; hours: number; hoursDone: number }[];
}

export const ActiveConventions = ({ oldConventions, conventions }: Props) => {
  return (
    <CustomCard
      title="CONVENTIONS ACTIVES"
      cardClassname="h-full"
      headerChildren={
        <Tooltip tooltipContent="Cette zone ne concerne que les professeurs soumis à une convention (obligation QUALIOPI)." />
      }
    >
      <ScrollArea className="h-[430px] w-full" displayScrollBar={false}>
        {conventions && conventions.map((convention, index) => <ConventionCard key={index} convention={convention} />)}
        {oldConventions && oldConventions.map((convention, index) => <OldConventionCard key={index} convention={convention} />)}
      </ScrollArea>
    </CustomCard>
  );
};
